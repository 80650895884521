import axios from "axios";
import "bulma/css/bulma.min.css";
import React, { useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../services/sessionService";
import { post } from "../services/smartApiService";
import { useSiteContext } from "../contexts/SiteProvider";

// Type definitions
interface ConsentSettings {
  essential: boolean;
  functional: boolean;
  analytics: boolean;
  advertising: boolean;
  consentId?: string;
  timestamp?: string;
}

interface ConsentResponse {
  consentId: string;
  timestamp: string;
  status: "success" | "error";
}

const CookieConsentModal: React.FC = () => {
  // State

  const [isDetailView, setIsDetailView] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [consentSettings, setConsentSettings] = useState<ConsentSettings>({
    essential: true, // Essential cookies are always required
    functional: false,
    analytics: false,
    advertising: false,
  });
  const { setIsVisible,isVisible } = useSiteContext();

  // Check if we need to show the consent popup
  useEffect(() => {
    const storedConsent = getLocalStorage("cookieConsent");

    if (!storedConsent) {
      setIsVisible(true);
    }
  }, []);

  // Handle individual checkbox changes
  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setConsentSettings((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Save consent to backend and localStorage
  const saveConsent = async (fullConsent: boolean = false) => {
    try {
      setIsSubmitting(true);

      // If accepting all cookies
      if (fullConsent) {
        setConsentSettings({
          essential: true,
          functional: true,
          analytics: true,
          advertising: true,
        });
      }

      // Prepare data for backend
      const consentData = {
        ...consentSettings,
        ipAddress: await fetchIP(), // Helper function to get IP (implementation not shown)
        userAgent: navigator.userAgent,
        source: window.location.href,
      };

      let url = "consent/insert";
      post(url, consentData).subscribe((response) => {
        const { consentId, timestamp } = response.data.data;
        const storageData: ConsentSettings = {
          ...consentSettings,
          consentId,
          timestamp,
        };

        // Save to localStorage
        setLocalStorage("cookieConsent", storageData);
      });
      // return () => subscription.unsubscribe();

      // Close the modal
      setIsVisible(false);
    } catch (error) {
      console.error("Failed to save consent:", error);
      alert("There was an error saving your preferences. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to fetch user's IP (simplified)
  const fetchIP = async (): Promise<string> => {
    try {
      const res = await axios.get("https://api.ipify.org?format=json");
      return res.data.ip;
    } catch (error) {
      console.error("Failed to get IP:", error);
      return "0.0.0.0";
    }
  };

  // Accept all cookies handler
  const acceptAllCookies = () => {
    saveConsent(true);
  };

  // Reject non-essential cookies handler
  const rejectNonEssentialCookies = async () => {
    setConsentSettings({
      essential: true,
      functional: false,
      analytics: false,
      advertising: false,
    });

    await saveConsent();
  };

  // Save current preferences handler
  const savePreferences = () => {
    saveConsent();
  };

  // Toggle between simple and detailed view
  const toggleDetailView = () => {
    setIsDetailView(!isDetailView);
  };

  // If not visible, don't render anything
  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookieConsent">
      <section className="">
        <div className="">
          <h2 className="is-size-4">This website uses cookies</h2>
          <p>
            We use cookies to enhance your browsing experience, serve
            personalized ads or content, and analyze our traffic. By clicking
            "Accept All", you consent to our use of cookies.
          </p>

          {isDetailView ? (
            <div className="cookie-settings mt-4">
              <div className="field">
                <div className="control">
                  <label className="checkbox is-disabled">
                    <input
                      type="checkbox"
                      name="essential"
                      checked={consentSettings.essential}
                      disabled
                    />
                    <span className="ml-2 has-text-weight-bold">
                      Essential Cookies
                    </span>
                  </label>
                  <p className="help ml-4">
                    Required for the website to function properly. Cannot be
                    disabled.
                  </p>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="functional"
                      checked={consentSettings.functional}
                      onChange={handleConsentChange}
                    />
                    <span className="ml-2 has-text-weight-bold">
                      Functional Cookies
                    </span>
                  </label>
                  <p className="help ml-4">
                    Remember your preferences and settings for a better
                    experience.
                  </p>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="analytics"
                      checked={consentSettings.analytics}
                      onChange={handleConsentChange}
                    />
                    <span className="ml-2 has-text-weight-bold">
                      Analytics Cookies
                    </span>
                  </label>
                  <p className="help ml-4">
                    Help us understand how visitors interact with our website.
                  </p>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="advertising"
                      checked={consentSettings.advertising}
                      onChange={handleConsentChange}
                    />
                    <span className="ml-2 has-text-weight-bold">
                      Advertising Cookies
                    </span>
                  </label>
                  <p className="help ml-4">
                    Used to provide you with relevant ads and marketing
                    campaigns.
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>

      <section className="mt-1" style={{ justifyContent: "space-between" }}>
        <button
          className={`button is-small is-link ${
            isSubmitting ? "is-loading" : ""
          }`}
          onClick={acceptAllCookies}
          disabled={isSubmitting}
        >
          Accept All
        </button>
        <button
          className={`button is-small is-light ml-2 ${
            isSubmitting ? "is-loading" : ""
          }`}
          onClick={rejectNonEssentialCookies}
          disabled={isSubmitting}
        >
          Reject All
        </button>

        {isDetailView ? (
          <button
            className={`button is-small is-success ${
              isSubmitting ? "is-loading" : ""
            }`}
            onClick={savePreferences}
            disabled={isSubmitting}
          >
            Save Preferences
          </button>
        ) : null}
        <button
          className="button is-small is-info is-light ml-2"
          onClick={toggleDetailView}
        >
          {isDetailView ? "Simple View" : "Customize"}
        </button>
      </section>
    </div>
  );
};

export default CookieConsentModal;
