import { Route } from "react-router-dom";

import {
  ContactUs,
  ContentSecurityPolicy,
  ForgetPassword,
  HelpAndSupport,
  HimsSections,
  HrmsSection,
  Login,
  ServicesSection
} from "../pages";
import AboutUs from "../pages/HomeFirst/AboutUs/AboutUs";
import HomePage from "../pages/HomeFirst/HomePage";
import PrivacyPolicy from "../pages/HomeFirst/Others/PrivacyPolicy";
import TermsAndConditions from "../pages/HomeFirst/Others/TermsAndConditions";
import CspPricing from "../pages/HomeFirst/Pricing/CspPricing";
import ModalLoginPage from "../pages/Login/ModalLoginPage";
import NotFound from "../themes/Main/NotFound";
import UserLayout from "../themes/Main/UserLayout";

const HomeRoutes = () => {
  return (
    <Route path="/" element={<UserLayout />}>
      <Route index element={<HomePage />} />
      <Route path="login" element={<Login />} />{" "}
      <Route path="forget" element={<ForgetPassword />} />
      <Route path="register" element={<ModalLoginPage />} />      
      <Route path="Help-Support" element={<HelpAndSupport />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="hrms" element={<HrmsSection />} />
      <Route path="hims" element={<HimsSections />} />
      <Route path="content-security-policy-evaluation-and-solution" element={<ContentSecurityPolicy />} />
      <Route path="content-security-policy-evaluation-tool-pricing" element={<CspPricing/>} />
      <Route path="offered-services" element={<ServicesSection />} />
      <Route path="*" element={<NotFound />} />       

    </Route>
  );
};

export default HomeRoutes;
