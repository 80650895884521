import React from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';

const DashboardCards = () => {

     const navigate = useNavigate();
  return (
    <div>
        <div className='columns'>
            <div className='column is-4'   onClick={() => navigate("/auth/waiting-list")}>
                <div className=' smart-dash-card smart-card-bg-one'>
                <div className='is-flex'>
                                <span className='icon'>
                                    <i className='fa fa-users'></i>
                                </span>
                                <div className='card-title' >Waitings</div>
                            </div>
                    <div className='card-content'>
                        <div className='media'>
                          
                            <div className='media-content'>
                                <p className='title'>232</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='column is-4'>
                <div className=' smart-dash-card smart-card-bg-two'>
                <div className='is-flex'>
                                <span className='icon'>
                                    <i className='fa fa-address-book'></i>
                                </span>
                                <div className='card-title' >test</div>
                            </div>
                    <div className='card-content'>
                        <div className='media'>
                          
                            <div className='media-content'>
                                <p className='title'>23</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='column is-4'>
                <div className=' smart-dash-card smart-card-bg-four'>
                <div className='is-flex'>
                                <span className='icon'>
                                    <i className='fa fa-id-card'></i>
                                </span>
                                <div className='card-title'>test</div>
                            </div>
                    <div className='card-content'>
                        <div className='media'>
                          
                            <div className='media-content'>
                                <p className='title'>23</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
  )
}
export default DashboardCards
