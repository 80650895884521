import { motion } from "framer-motion";
import React, { useState } from "react";
import { useSiteContext } from "../../../contexts/SiteProvider";
import "./projects.scss"; // Import SCSS

interface Directive {
  directive_name: string;
  values: string[];
  issues: string[];
  score: number;
}

interface CSPTableProps {
  tabData: { directives: Directive[] };
}

const CSPTable: React.FC<CSPTableProps> = ({ tabData }) => {
  const [openDirectives, setOpenDirectives] = useState<{
    [key: string]: boolean;
  }>({});
  const { openModal } = useSiteContext();

  const toggleDirective = (directive: Directive) => {
    setOpenDirectives((prev) => ({
      ...prev,
      [directive.directive_name]: !prev[directive.directive_name],
    }));
    openForm(directive); // Pass full directive object
  };

  const openForm = (data: any) => {
    console.log("open", data);
    let options = {
      title: data.directive_name,
      content: renderDirectiveDetails(data),
      width: 90,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };

  // **Extracted function for expandable details**
  const renderDirectiveDetails = (directive: any) => (
    // <><p>test</p></>
    <motion.div className="csp-details">
      {/* Values */}
      {directive && directive?.values?.length > 0 ? (
        <ul className="csp-values">
          {directive.values.map((valueObj: any, valIdx: number) => {
            // Define the icon based on the status
            let icon = "✅"; // Default success icon
            if (valueObj.status === "warning") icon = "⚠️";
            else if (valueObj.status === "danger") icon = "❌";

            return (
              <li key={valIdx} className="csp-value">
                {icon} {valueObj.value}
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="no-values">⚠️ No Values</p>
      )}

      {/* Issues */}
      {directive && directive?.issues?.length > 0 && (
        <ul className="csp-issues">
          {directive.issues.map((issue: any, issueIdx: number) => (
            <li key={issueIdx} className="csp-issue">
              ❌ {issue}
            </li>
          ))}
        </ul>
      )}
    </motion.div>
  );

  return (
    <div className="csp-container">
      <ul className=" columns is-multiline is-vcentered">
        {tabData &&
          tabData.directives.map((directive: any, index) => {
            // console.log(directive);
            let icon = "✅";
            if (
              directive.status === "warning" ||
              directive.status === "info" ||
              (directive.status === "success" && directive.values.length < 1)
            )
              icon = "⚠️";
            else if (directive.status === "danger") icon = "❌";
            return (
              <li key={index} className="column is-3">
                <div
                  // Pass full object
                  className="directive-card"
                >
                  <span className="directive-title">
                    {directive.directive_name}
                  </span>
                  <div className=" ">
                    <i
                      className="fa fa-eye has-text-link mr-2 is-clickable"
                      onClick={() => toggleDirective(directive)}
                      aria-hidden="true"
                    ></i>
                    {/* <span className="arrow">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span> */}
                    {icon}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CSPTable;
