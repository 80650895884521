import { useForm, Controller } from "react-hook-form";
import { SdsForm } from "soft_digi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";

const validationSchema = Yup.object({
  site_name: Yup.string().required("Site name is required."),
  site_short_name: Yup.string().required("Short name is required."),
  site_theme: Yup.string().required("Theme is required."),
});
interface HeaderProps {
  onSubmit: (formData: any) => void;
  data: any;
}
const SiteSettings: React.FC<HeaderProps> = ({ onSubmit, data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: data
    
  });

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 6,
      name: "site_name",
      element: { label: "Site Name", placeHolder: "Enter Site Name" },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "site_short_name",
      element: {
        label: "Site Short Name",
        placeHolder: "Enter Site Short Name",
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "site_theme",
      element: {
        label: "Theme",
        placeHolder: "",
      },
    },
    // {
    //   type: "FILE",
    //   width: 6,
    //   name: "site_logo",
    //   element: {
    //     label: "Upload Site Logo",
    //     placeHolder: "(Image Only)",
    //     accept: "*",
    //   },
    // },

    {
      type: "BUTTON",
      width: 12,
      name: "submit",
      class_name: "has-text-right ",
      element: { label: "Submit", onClick: handleSubmit(onSubmit),     classList: ["mt-4  smart-action-button"], },
    },
  ];
  return (
    <>
      <div className="mt-3 sd-hrms-input">
        <SdsForm elements={elements} errors={errors} control={control} />
      </div>
    </>
  );
};

export default SiteSettings;
