import React from "react";
import HeaderOne from "./HeaderOne";
import ImageSlider from "./ImageSlider";
import FlipCardSection from "./FlipCard";
import MainContent from "./MainContent";
import MainContenttwo from "./MainContenttwo";
import Footer from "./Footer";
import "./Home.scss";
import LandingPage from "./LandingPage";
const HomePage = () => {
  return (
    <>
    <LandingPage />
     
     
    </>
  );
};

export default HomePage;
