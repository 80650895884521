import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../contexts/SiteProvider";
import { post } from "../../services/smartApiService";
const clientId =
  "288181436704-vegignqvfra0d2sqqq174l55r2ug26ol.apps.googleusercontent.com";

const GoogleAuthLogin = () => {
  const { setUser, user } = useSiteContext();
  const navigate = useNavigate();
  const decodeJWT = (token: string) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      return JSON.parse(atob(base64));
    } catch (error) {
      console.error("Invalid JWT", error);
      return null;
    }
  };

  const handleSuccess = (credentialResponse: any) => {
    const token = credentialResponse.credential;
    const decoded: any = decodeJWT(token);
   // console.log("User Info:", decoded);
    post("auth/google", { token: token }, {
      requiresAuthorization: false,
    }).subscribe((response) => {
      setUser(response.data.data);
      //const roles = response.data.data.roles; // Assuming roles is an array
      navigate("/client/dashboard");
    });

  }

  // Send token to backend


  const handleFailure = () => {
    console.error("Google Login Failed");
  };

  return <GoogleLogin onSuccess={handleSuccess} onError={handleFailure} />;
};

export default GoogleAuthLogin;
