import React from 'react';

interface ViewDetailsProps {
  tableData: any;
}

const EmailDetails: React.FC<ViewDetailsProps> = ({ tableData }) => {
  const tableRows = [
    { label: 'Mail Subject', key: 'mail_subject' },
    { label: 'Mail Message', key: 'mail_msg' },
  ];


  return (
    <div className="table-container ">
      <table className="table is-bordered is-fullwidth">
        <tbody>
          {tableRows.map((row, index) => (
            <tr key={index}>
              <td>
                <strong>{row.label}</strong>
              </td>
              <td>
                {row.key === 'mail_msg' ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tableData[row.key],
                    }}
                  />
                ) : (
                  tableData[row.key]
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmailDetails;
