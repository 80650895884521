import React, { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartFormInterFace,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { useSiteContext } from "../../contexts/SiteProvider";
import { get, post } from "../../services/smartApiService";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../services/notifyService";
import GalarySectionForm from "./GalarySectionForm";
import { GALLERY_URLS } from "../../api/GalaryUrls";

const GalarySection = () => {
  const { openModal, closeModal } = useSiteContext();
  const [tabData, setTabData] = useState([]);
  // const [_data, setData] = useState<any>();
  const loadTableData = () => {
    let URL = GALLERY_URLS.GET_ALL;
    const subscription = get(URL).subscribe((response) => {
      setTabData(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const deleteData = (id: any) => {
    const subscription = post(GALLERY_URLS.DELETE, { id: id }).subscribe(
      (response) => {
        showAlertAutoClose("Deleted Successfully...", "success");
        closeModal();
        loadTableData();
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openDeleteModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Gallery Deletion!
        </span>
      ),
      alertFunction: (option) => {
        if (option === "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to delete this Gallery? This action cannot be reverted
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };

  const viewEditForm = (id: any) => {
    const subscription = post(GALLERY_URLS.GET_ONE, { id: id }).subscribe(
      (response: any) => {
        openNewsForm(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
//   const openViewdetails = (data: any) => {
//     let options = {
//       title: "NEWS Details",
//       content: <NewsDetails tableData={data} />,
//       width: 60,
//       className: "sd-efl-modal",
//       closeBody: false,
//     };
//     openModal(options);
//   };
  const openNewsForm = (data: any) => {
    let options = {
      title: "Gallery Section Addition Form",
      content: <GalarySectionForm loadTableData={loadTableData} data={data} />,
      width: 60,
      className: "sd-efl-modal",
      closeBody: false,
    };
    openModal(options);
  };
  const buttons = [
    // {
    //   label: "",
    //   type: "icon",
    //   leftIcon: "fa fa-eye",
    //   classList: ["smart-kgr-table-view-icon"],
    //   onClick: (dataIn: any) => {
    //     // openViewdetails(dataIn);
    //   },
    // },
    {
      label: "",
      type: "icon",
      leftIcon: " fa-pencil-square-o",
      classList: ["smart-kgr-table-edit-icon"],
      onClick: (data: any) => {
        viewEditForm(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["smart-kgr-table-delete-icon"],
      onClick: (data: any) => {
        openDeleteModal(data["ID"]);
      },
    },
  ];
  

  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width:"5" },
   

    {
      title: "Title",
      index: "title",
      width: "30",
    },
   
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];
  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-6",
      custom: <p className="is-size-5">Gallery</p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-3",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-3",
      align: "CENTER",
      buttons: [
        { type: "FILTER" },
        {
          type: "REFRESH",
          action: loadTableData,
        },
        {
          label: "ADD",
          icon: "fa-plus",
          type: "CUSTOM",
          action: () => openNewsForm({}),
        },
      ],
    },
  ];
  const filterFields: SmartFormInterFace.SmartFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "category",
      element: {
        label: "Category",
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "taluka_name",
      element: {
        label: "Taluk",
      },
    },
  ];
  return (
    <>
      <div className="">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          filterFields={filterFields}
          tableProps={{
            className: " is-hoverable is-bordered is-striped smart-kgr-table",
            isResponsive: true,
            searchPlaceHolder: "Search Here...",
          }}
          // paginationProps={{
          //   pageSize: 5,
          // }}
        />
      </div>
    </>
  );
};

export default GalarySection