import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO, LOGO_ONE, SITE_LOGO } from "../../services/ImageService";
import { useSiteContext } from "../../contexts/SiteProvider";
import { checkInterSection } from "../../services/core/FilterService";
import { motion } from "framer-motion";
interface childrenProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}
const EFSideNav: React.FC<childrenProps> = ({ isOpen, setIsOpen }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  // const listItems = [
  //   {
  //     id: 1,
  //     label: "Dashboard",
  //     icon: "fa-tachometer",
  //     link: "dashboard",
  //     roles: ["ADMIN"],
  //   },  {
  //     id: 1,
  //     label: "Gallery Section",
  //     icon: "fa-picture-o",
  //     link: "gellary-section",
  //     roles: ["ADMIN"],
  //   }, {
  //     id: 1,
  //     label: "Site Circular",
  //     icon: "fa-book",
  //     link: "Site",
  //     roles: ["ADMIN"],
  //   },
  //   {
  //     id: 1,
  //     label: "Role",
  //     icon: "fa-user-circle-o",
  //     link: "role",
  //     roles: ["ADMIN"],
  //   },
  //     {
  //     id: 1,
  //     label: "Members",
  //     icon: "fa-users",
  //     link: "users",
  //     roles: ["ADMIN"],
  //   },
  //   {
  //     id: 1,
  //     label: "Settings",
  //     icon: "fa-cogs",
  //     link: "Settings",
  //     roles: ["ADMIN"],
  //   },
  // ];
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSiteContext();
  const [hide ,setHide]=useState(false);
  // console.log("logged in user ", user);
  const navigateLink = (index: any) => {
    navigate("/auth/" + index);
    if (window.innerWidth <= 768) {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      //setActive(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const headerLinks = [
    { name: "Home", link: "/", icon: "home" },
    {
      name: "Products",
      icon: "box",
      link: "",
      type: "dropdown",
      dropdown: [
        { name: "Content Security Policy", link: "/csp" },
        { name: "Docuforge (Digital Work flow)", link: "/product2" },
        { name: "Smart HRMS", link: "/hrms" },
        { name: "Smart HIMS", link: "/hims" },
      ],
    },

    { name: "Services", link: "/service-", icon: "phone" },
    { name: "About Us", link: "/about-us", icon: "info" },
    // { name: "Pages", link: "/privacy-policy", icon: "book" },
    // { name: "Blog", link: "/terms-condition", icon: "newspaper" },
    { name: "Contact Us", link: "/contact-us", icon: "mail" },
    { name: "Login", link: "/login", icon: "mail" },
  ];

  return (
    <motion.div
      key="mobileScreen"
      className=""
      initial={{ opacity: 0, x: -250 }} // Start off-screen
      animate={{
        opacity: isOpen ? 1 : 0,
        x: isOpen ? 0 : -250,
      }}
      exit={{ opacity: 0, x: -250 }} // Animate out
      transition={{ duration: 0.3, ease: "easeOut", type: "tween" }}
    >
      <div className={`smart-sidenav ${isOpen ? "expanded" : ""}`}>
        {/* Brand Logo */}
        <div className="header-brand-">
          <img
            className="is-64x64  with-out-pointer"
            src={SITE_LOGO}
            alt="Brand Logo"
          />
        </div>
        {headerLinks.map((item: any, index: any) => (
          <div className="sds-dropdown-container">
            <motion.a
              key={index}
              className={`navbar-item  ${
                item?.dropdown ? " sds-hover-dropdown " : ""
              } ${location.pathname === item.link ? "active-navbar-item" : ""}`}
              href={item?.link || "#"}
              onClick={(e) => {
                // navigate(item?.link);
                if (!item?.link) e.preventDefault();
                if (item.name==="Products"){
                  setHide(!hide)
                }
                // setIsOpen(false);
              }}
              whileHover={{ scale: 1.1, color: "#3273dc" }}
              whileTap={{ scale: 0.95 }}
            >
              {item.name}
            </motion.a>
            {item && item.dropdown && (
              <>
            {hide &&  <div className="sds-side-dropdown">
                {item &&
                  item.dropdown &&
                  item.dropdown.map((subItem: any, subIndex: any) => (
                    <a
                      key={subIndex}
                      className="navbar-item is-size-6"
                      onClick={() => {
                        navigate(subItem.link);
                        setIsOpen(false);
                        setHide(!hide)
                      }}
                    >
                      {subItem.name}
                    </a>
                  ))}
              </div>} 
              </> )}
          </div>
        ))}
      </div>
    </motion.div>
  );
};
export default EFSideNav;
