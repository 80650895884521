import React, { useState } from "react";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { useForm } from "react-hook-form";
import Rigistation from "./Rigistation";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../../services/smartApiService";
import { REGISTER_URLS } from "../../api/LoginUrls";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
const validationSchema = Yup.object({
  ename: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s]+$/, "Name must only contain letters"),
  email_id: Yup.string()
    .required("Email is required")
    .email("Must be a valid email address"),
  unit_office: Yup.string().required("Unit Office is required"),
  // icno: Yup.string()
  //   .required("ICNO is required")
  
});

const RegisterPage = () => {
  const [stage, setStage] = useState("FIRST");
  const [userData, setUserData] = useState<any>();
  const [otpId, setOtpId] = useState<any>();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm( {resolver: yupResolver(validationSchema),}   );

  const onSubmit = (data: any) => {
    setUserData(data);
    if (stage === "FIRST") {
      let url = REGISTER_URLS.SEND_OTP;
      let email_id = data.email_id;
      const subscription = post(
        url,
        { email_id },
        {
          requiresAuthorization: false,
        }
      ).subscribe((response) => {
        reset();
        // setUser(response.data);
        setOtpId(response.data.otp_id);
        setStage("SECOND");
      });

      return () => {
        subscription.unsubscribe();
      };
    } else if (stage === "SECOND") {
      let url = REGISTER_URLS.VERIFY_OTP;
      // console.log("user Datas ", userData);
      let _data = {
        otp_id: otpId,
        ename: userData.ename,
        email_id: userData.email_id,
        unit_office: userData.unit_office,
        icno: userData.icno,
        otp_value: data.otp_value,
      };
      const subscription = post(url, _data, {
        requiresAuthorization: false,
      }).subscribe((response) => {
        reset();
        // setUser(response.data);
        navigate("/");
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  };

  const loginTitle = () => (
    <div className="mt-1 mb-2 customer-primary-font">
      <p className="has-text-centered is-size-4 mb-2 customer-login-textcolor">
        Create Account
      </p>
    </div>
  );

  const loginSignUp = () => (
    <div>
      <p className="has-text-centered mt-4 mb-5 member-text">
        Already a Member?{" "}
        <Link to="/login" className="">
          Login
        </Link>
      </p>
    </div>
  );

  const stage1_elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 6,
      name: "ename",
      element: { label: "Name", placeHolder: "Enter Email", isRequired: true },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "email_id",
      element: {
        label: "Email Address",
        isRequired: true,
        placeHolder: "Enter Email Address",
      },
    },

    {
      type: "TEXT_BOX",
      width: 6,
      name: "unit_office",
      element: {
        label: "Unit Office",
        placeHolder: "Enter  Unit Office ",
        isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "icno",
      element: { label: "IC NO", placeHolder: "Enter ICNO ", isRequired: true },
    },

    {
      type: "BUTTON",
      width: 12,
      class_name: " is-flex is-justify-content-flex-end",
      name: "sendOtp",
      element: { label: "Submit", onClick: handleSubmit(onSubmit) },
    },
  ];

  const stage2_elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "otp_value",
      element: { max: 6 },
    },
    {
      type: "BUTTON",
      width: 12,
      name: "verify",
      element: { label: "Verify", onClick: handleSubmit(onSubmit) },
    },
  ];

  const formDisplay = (elements: SdsFormElementProps[]) => (
    <div className="register-page-business-login-form">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
const smart_login_Data = () =>{
  return(<>
  <table className="table is-borderless  is-fullwidth">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{userData?.ename} </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{userData?.email_id} </td>
          </tr>
          <tr>
            <td>Unit Office</td>
            <td>{userData?.unit_office} </td>
          </tr>
          <tr>
            <td>Ic No.</td>
            <td>{userData?.icno} </td>
          </tr>
        </tbody> </table>
  </>)
}
  const first_second_form = () => (
    <div>
      {stage === "FIRST" && (
        <>
          {loginTitle()}
          {formDisplay(stage1_elements)}
          {loginSignUp()}
        </>
      )}
      {stage === "SECOND" && (
        <>
        {smart_login_Data()}
          {formDisplay(stage2_elements)}
          {loginSignUp()}
        </>
      )}
    </div>
  );

  const third_form = () => (
    <div>
      <p className="has-text-centered is-size-4 customer-login-textcolor">
        Create Account 1
      </p>
      {/* <Rigistation /> */}
    </div>
  );

  return (
    <div>
      {(stage === "FIRST" || stage === "SECOND") && first_second_form()}
      {stage === "THIRD" && third_form()}
    </div>
  );
};

export default RegisterPage;
