import { Route } from "react-router-dom";
import ClientDashBoard from "../pages/client/ClientDashBoard";
import CspAnalytics from "../pages/client/CspAnalytics";
import MainLayout from "../themes/Main/Layout";

const ClientRoutes = () => {
  return (
    <>
    <Route
      path="/client/*"
      element={ <MainLayout /> }
    >
      <Route path="dashboard" element={<ClientDashBoard />} />
      <Route path="csp-analytics/:id" element={<CspAnalytics />} />
    </Route>

    </>
  );
};

export default ClientRoutes;
