import { motion } from "framer-motion";
import React from "react";
import "./help.scss";

import { SIDE_WAVE, USER_8 } from "../../services/ImageService";
import "./help.scss";
import { useNavigate } from "react-router-dom";

const ChooseUs: React.FC = () => {
  const navigate =useNavigate()
  const FeatureCards = () => {
    return (
      <div className="feature-cards-container">
        <motion.div
          className="feature-card"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          {/* <div className="feature-card-icon">
         
        </div> */}
          <div className="feature-card-content">
            <div className="is-flex my-2">
              <i className="fas fa-shield-alt"></i>
              <h3 className="title has-text-white ml-2">Tech Solution</h3>
            </div>

            <p className="description">
            Our comprehensive portfolio includes CSP implementations, digital signature workflows, mobile apps, web development, HRMS, and HIMS.
             We don't just build software—we create tailored solutions that solve your unique business challenges.
            </p>
          </div>
        </motion.div>

        <motion.div
          className="feature-card"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="feature-card-content">
            <div className="is-flex my-2">
              <i className="fas fa-headset"></i>
              <h3 className="title has-text-white ml-2">Quick Support</h3>
            </div>

            <p className="description">
            We pride ourselves on responsive service and ongoing technical assistance. Our dedicated support team ensures your systems run smoothly, with quick resolution 
            times and proactive maintenance to prevent issues before they impact your operations.
            </p>
          </div>
        </motion.div>
      </div>
    );
  };

  return (
    <section className="choseUs-container">
      <div className="container p-0 m-0">
        <div className="sub-container">
          <figure className="image ">
            <img src={SIDE_WAVE} alt="" />
          </figure>
        </div>
        <div className="columns is-multiline p-0 m-0">
          {/* <div className="column is-6 mt-6">
            <div className=" brand-us-text ">Trusted brands work with us</div>
          </div>
          <div className="column is-6 mt-6 ">
            <div className=" brand-container">
              <BrandSlider />
            </div>
          </div> */}
          <div className="column is-half">
            <motion.div
              initial={{ opacity: 0, x: -100 }} // Start from left
              whileInView={{ opacity: 1, x: 0, y: [0, -10, 0] }} // Slide in, then bounce
              transition={{
                duration: 1, // Slide duration
              }}
              className="image-content"
            >
              <img src={USER_8} alt="Woman smiling" className="image" />
            </motion.div>
          </div>
          <div className="column is-half">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="text-content p-4"
            >
              <h2 className="button has-gray-lite has-text-black my-3">
                WHY CHOOSE US
              </h2>
              <h1 className="title has-text-white is-1 my-2">
                Empowering success in technology since 2010
              </h1>
              <p className="subtitle has-text-white is-6 my-4">
              With over a decade of experience delivering cutting-edge software solutions, we've established ourselves as trusted partners for businesses seeking digital transformation. Our proven track record speaks for itself through successful implementations and satisfied clients across industries.
              </p>
              <FeatureCards />
              <div className="buttons mt-5">
                <button onClick={()=>navigate("/about-us")} className=" cta-button">Learn More</button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
