import { useEffect } from "react";
import { get } from "../smartApiService";


const useSelectBox = (url: string, callBack: (data: any) => void) => {
  useEffect(() => {
    const subscription = get(url).subscribe({
      next: (response) => callBack(response.data),
      error: (error) => console.error("Error fetching data:", error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [url, callBack]); 
};

export default useSelectBox;
