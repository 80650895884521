import { motion } from "framer-motion"; // Import Framer Motion
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContactUsComponent from "../../components/ContactUsComponent";
import { useSiteContext } from "../../contexts/SiteProvider";
import { SITE_LOGO } from "../../services/ImageService";
import EFSideNav from "./SideNav";

const UserHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout, openModal, hasTriggered, setHasTriggered } =
    useSiteContext();
  // const { hasTriggered, setHasTriggered } = useScrollContext();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCanScrolled, setCanIsScrolled] = useState(false);
  const [mobileScreen, setMobileScreen] = useState<boolean>(
    window.innerWidth <= 768
  );
  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const toggleNavbar = () => setIsOpen(!isOpen);
  const headerLinks = [
    { name: "Home", link: "/", icon: "home" },
    {  name: "Products", icon: "box", link: "", type: "dropdown",
       dropdown: [
        { name: "Content Security Policy", link: "/content-security-policy-evaluation-and-solution" },
        { name: "Docuforge (Digital Work flow)", link: "/product2" },
        { name: "Smart HRMS", link: "/hrms" },
        { name: "Smart HIMS", link: "/hims" },
      ],
    },
    { name: "Services", link: "/offered-services", icon: "phone" },
    { name: "About Us", link: "/about-us", icon: "info" },
    // { name: "Pages", link: "/privacy-policy", icon: "book" },
    // { name: "Blog", link: "/terms-condition", icon: "newspaper" },
    { name: "Contact Us", link: "/contact-us", icon: "mail" },
  ];

  useEffect(() => {
    if (location.pathname !== "/") return;
    const handleFormScroll = () => {
      setCanIsScrolled(window.scrollY > 1000);
      if (isCanScrolled && !hasTriggered) {
        openForm();
        setHasTriggered(true); // Persist across navigation
      }
    };

    window.addEventListener("scroll", handleFormScroll);
    return () => window.removeEventListener("scroll", handleFormScroll);
  }, [isCanScrolled, hasTriggered, setHasTriggered]);


  const openForm = () => {
    let options = {
      title: " ",
      content: <ContactUsComponent
        companyName="HDT Solutions"
        companyEmail="sds@hdtsolution.com"
        companyPhone="+91 9392336796"
      />,
      width: 60,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };

  return (
    <>
      <motion.nav
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`navbar smart-header-sds ${isScrolled ? "fixed-header" : ""
          }`}
      >
        <div className="navbar-brand p-0">
          <a className="navbar-item smart-header-title" href="#">
            <figure className=" image">
              <motion.img
                className="navbar-item-image"
                src={SITE_LOGO}
                alt="Admin Logo"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
              />
            </figure>
          </a>


          <a
            role="button"
            className={`navbar-burger has-text-black ${isOpen ? "is-active" : ""}`}
            aria-label="menu"
            //  aria-expanded="false"
            data-target="navbarBasic"
            aria-expanded={isOpen}
            onClick={toggleNavbar}
          >
            {/* <span aria-hidden="true"></span> */}
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>

        </div>

        <div
          id="navbarBasic"
          className="navbar-menu smart-sds-headed navbar-menu-mobile"
        >
          <div className="navbar-end smart-header-content">
            {headerLinks.map((item, index) => (
              <div className="sds-dropdown-container">
                <motion.a
                  key={index}
                  // className={`navbar-item ${
                  //   location.pathname === item.link ? "active-navbar-item" : ""
                  // }`}
                  className={`navbar-item  ${item?.dropdown ? " sds-hover-dropdown " : ""
                    } ${location.pathname === item.link ? "active-navbar-item" : ""
                    }`}
                  href={item?.link}
                  onClick={() => {
                    // navigate(item?.link);
                    setIsOpen(false);
                  }}
                  whileHover={{ scale: 1.1, color: "#3273dc" }}
                  whileTap={{ scale: 0.95 }}
                >
                  {item.name}
                </motion.a>
                {item && item.dropdown && (
                  <div className="sds-navbar-dropdown">
                    {item &&
                      item.dropdown &&
                      item.dropdown.map((subItem, subIndex) => (
                        <a
                          key={subIndex}
                          className="navbar-item is-size-6"
                          onClick={() => navigate(subItem.link)}
                        >
                          {subItem.name}
                        </a>
                      ))}
                  </div>
                )}

                {/* </motion.div>} */}
              </div>
            ))}

            <div className="navbar-end">
              <div className="navbar-item">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="cta-button"
                  onClick={() => navigate("/login")}
                >
                  Login/Sign up
                  <span className="icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </motion.nav>

      {mobileScreen && <EFSideNav isOpen={isOpen} setIsOpen={toggleNavbar} />}
    </>
  );
};

export default UserHeader;
