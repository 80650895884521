import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSiteContext } from "../../contexts/SiteProvider";

import "./Login.scss";

import { SdsForm } from "soft_digi";

import { AI_BG, SITE_LOGO } from "../../services/ImageService";

import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from 'framer-motion';
import { useForm } from "react-hook-form";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { getRemeberMe } from "../../services/sessionService";
import { post } from "../../services/smartApiService";

import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  User
} from "firebase/auth";
import GoogleAuthLogin from "./GoogleAuthLogin";
import Registration from "./Rigistation";
import ModalLoginPage from "./ModalLoginPage";

const schema = Yup.object({
  password: Yup.string(),
  emailid: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email"),
});

const GOOGLE_CLIENT_ID =
  "288181436704-vegignqvfra0d2sqqq174l55r2ug26ol.apps.googleusercontent.com";

const Login: React.FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema), // Attach the Yup schema to react-hook-form
  });

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [googleUser, setGoogleUser] = useState<User | null>(null);
  const [isRegistering, setIsRegistering] = useState(false);

  // onAuthStateChanged(auth, (currentUser) => {
  // })

  const toggleCardFlip = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  // const handleLogin = async (data: any) => {
  //   try {
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       data.email,
  //       data.password
  //     );
  //     navigate("/admin/dashboard");
  //     return userCredential.user;
  //   } catch (error) {
  //     console.error("Login Error:", error);
  //     return null;
  //   }
  // };

  const { setUser, user } = useSiteContext();

  const handleRemeberMe = () => {
    // let checkbox_remember_me =
    //   formData.checkbox_remember_me !== undefined
    //     ? formData.checkbox_remember_me
    //     : "0";
    // // console.log("check box rmeber me " , checkbox_remember_me, formData);
    // if (checkbox_remember_me === "1") {
    //   setRemeberMe(formData);
    // } else {
    //   removeRemeberMe();
    // }
  };

  const handleRemeberMeLoad = () => {
    // let remember_data = getRemeberMe();
    let remember_data_original = getRemeberMe();
    const remember_data = { ...remember_data_original };
    delete remember_data.epassword;
    if (remember_data) {
      let checkbox_remember_me =
        remember_data.checkbox_remember_me !== undefined
          ? remember_data.checkbox_remember_me
          : "0";
      if (checkbox_remember_me === "1") {
        //setFormData(remember_data);
      }
    }
  };
  useEffect(() => {
    handleRemeberMeLoad();
  }, []);

  const handleLogin = (_data: any) => {
    let url = "auth/login";
    const subscription = post(url, _data, {
      requiresAuthorization: false,
    }).subscribe((response) => {
      reset();
      setUser(response.data.data);
      handleRemeberMe();
      const roles = response.data.data.roles; // Assuming roles is an array
   //   console.log("roles " , roles);
      if (roles && roles.includes("ADMIN")) {
        navigate("/admin/dashboard");
      } else {
        navigate("/client/dashboard");
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  };

  const passWordPin = () => {
    return (
      <div className=" mb-4 has-text-weight-medium has-text-right member-text">
        <span className="" onClick={() => navigate("/forget")}>
          Forget Password?
        </span>
      </div>
    );
  };
  const passWordHome = () => {
    return (
      <div className=" mb-4 has-text-weight-medium member-text has-text-left">
        <span className="" onClick={() => navigate("/")}>
          Back to Home
        </span>
      </div>
    );
  };
  const formElements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "emailid",
      element: {
        label: "Email",
        isRequired: true,
        // placeHolder: "Email ",
        max: 255,
        inputType: "BORDER_LABEL",
        leftIcon: "fa fa-envelope-square",
      },
    },
    {
      type: "PASSWORD",
      width: 12,
      name: "password",
      element: {
        label: "Password",
        // isRequired: true,
        placeHolder: "Password",
        inputType: "BORDER_LABEL",
        leftIcon: "fa fa-lock",
        passwordValidator: false
      },
    },
    // {
    //   type: "LABEL",
    //   name: "remember_me",
    //   width: 6,
    //   labelComponent: passWordHome(),
    // },
    // {
    //   type: "LABEL",
    //   name: "remember_me",
    //   width: 6,
    //   labelComponent: passWordPin(),
    // },
    {
      type: "BUTTON",
      width: 12,
      name: "submitbutton",
      class_name: "login-button",
      element: {
        label: "Login",
        onClick: handleSubmit(handleLogin),
        classList: ["smart-lnpr-login-button"],
      },
    },
  ];

  return (
    <>
      <div className="login-page">
        {/* <div className="background-overlay">
          <img
            src={AI_BG}
            alt="Background"
            className="background-image"
          />
        </div> */}

        <motion.div
          className="login-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="login-box">
            <div className="logo-container">
              <img src={SITE_LOGO} alt="Logo" className="logo" />
            </div>
            {!isRegistering && <div>
              <h1 className="title is-4 has-text-weight-semibold">Login </h1>
              <SdsForm elements={formElements} errors={errors} control={control} />
              {/* <div className="forgot-password">Esqueceu sua senha?</div> */}
              {/* <button className="login-button">Entrar</button> */}
     <div className="columns is-mobile">
      <div className="column is-6">
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleAuthLogin />
              </GoogleOAuthProvider>
      </div>
      <div className="column is-6">
        <button className="smart-login-back-button" onClick={()=>navigate("/")}>
          Back to Home
        </button>
      </div>
     </div>
             
              <div className="signup-link" onClick={() => setIsRegistering(true)}>Don't have an account? sign up for free</div>
            </div>}

            {isRegistering && <div className="">
              <h1 className="title is-4 has-text-weight-semibold ">Sing Up</h1>
              <ModalLoginPage />
              <div className="signup-link" onClick={() => setIsRegistering(false)}>Back to Login</div>
            </div>}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Login;
