import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaCheckCircle, FaExclamationTriangle, FaInfoCircle, FaTimes, FaTimesCircle } from "react-icons/fa";

type DirectiveValue = {
  label: string;
  status: "success" | "warning" | "danger";
};

type CSPDirective = {
  directive: string;
  values: DirectiveValue[];
  recommendation: string;
  status: "success" | "warning" | "danger";
};

type CSPDirectiveListProps = {
  directives: any[];
};

const CspDirectiveDisplay: React.FC<CSPDirectiveListProps> = ({ directives }) => {

  console.log("CSPDirectiveDisplay",directives)
  const [selectedDirective, setSelectedDirective] = useState<CSPDirective | null>(null);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "success":
        return <FaCheckCircle color="green" size={18} />;
      case "warning":
        return <FaExclamationTriangle color="orange" size={18} />;
      case "danger":
        return <FaTimesCircle color="red" size={18} />;
      default:
        return null;
    }
  };

  return (
    <div className="container">
      {directives && directives.length === 0 ? (
        <div className="empty-state">
          <h2>No CSP directives found.</h2>
          <p>Start monitoring your CSP by adding a new site.</p>
          <button className="button is-primary">Add CSP Site</button>
        </div>
      ) : (
        <div className="columns is-multiline">
          {Array.isArray(directives) && directives.map((directive:any, index:number) => (
            <motion.div
              key={index}
              className="column is-half"
              whileHover={{ scale: 1.02 }}
            >
              <div
                className="box directive-card"
                style={{
                  borderLeft: `5px solid ${
                    directive.status === "success"
                      ? "green"
                      : directive.status === "warning"
                      ? "orange"
                      : "red"
                  }`,
                  position: "relative",
                }}
              >
                <div className="directive-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <strong>{directive.directive_name}</strong>
                  {/* {directive.values.some((v:any) => v.status !== "success") && (
                    <FaInfoCircle
                      color="red"
                      size={18}
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedDirective(directive)}
                    />
                  )} */}
                </div>
                {/* <div className="values-list">
                  {directive.values.map((val:any, idx:number) => (
                    <span key={idx} style={{ display: "flex", alignItems: "center", gap: "8px", marginTop: "5px" }}>
                      {getStatusIcon(val.status)} {val.label}
                    </span>
                  ))}
                </div> */}
                  <motion.div
                      className="csp-details"
                    >
                      {/* Values */}
                      {directive && directive?.values.length > 0 ? (
                  <ul className="csp-values">
                    {directive.values.map((valueObj:any, valIdx:number) => {
                      // Define the icon based on the status
                      let icon = "✅"; // Default success icon
                      if (valueObj.status === "warning") icon = "⚠️";
                      else if (valueObj.status === "danger") icon = "❌";
                
                      return (
                        <li key={valIdx} className="csp-value">
                          {icon} {valueObj.value}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="no-values">⚠️ No Values</p>
                )}
                
                      {/* Issues */}
                      {directive && directive?.issues.length > 0 && (
                        <ul className="csp-issues">
                          {directive.issues.map((issue:any, issueIdx:any) => (
                            <li key={issueIdx} className="csp-issue">
                              ❌ {issue}
                            </li>
                          ))}
                        </ul>
                      )}
                    </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      )}

      {selectedDirective && (
        <motion.div
          className="absolute-popup"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className="popup-content">
            <button className="close-btn" onClick={() => setSelectedDirective(null)}>
              <FaTimes />
            </button>
            <h3>{selectedDirective.directive}</h3>
            <ul>
              {selectedDirective.values.map((val, idx) => (
                <li key={idx} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {getStatusIcon(val.status)} {val.label}
                </li>
              ))}
            </ul>
            <p><strong>Recommendation:</strong> {selectedDirective.recommendation}</p>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default CspDirectiveDisplay;
