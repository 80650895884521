import { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { USER_URLS } from "../../api/AdminUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { get, post } from "../../services/smartApiService";





const CSPList = () => {
  const [tabData, setTabData] = useState([]);
  const { openModal, closeModal,setTitle } = useSiteContext();

  const loadTableData = () => {
    let URL = "/csp/get_all";
   
    const subscription = get(URL, ).subscribe((response) => {
      setTabData(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);
  
  useEffect(() => {
    setTitle ("CSP Details");
  }, []);

//   const openOfficesForm = (data: any) => {
//     let options = {
//       title: <>{data.ID ? "Members Update Form" : "Members Addition Form"}</>,
//       content: <UsersForm loadTableData={loadTableData} dataIn={data} />,
//       className: "sd-efl-modal",
//       closeBody: false,
//       width: 60,
//     };
//     openModal(options);
//   };
  const deleteData = (id: any) => {
    const subscription = post("csp/delete_one", { id: id }).subscribe(
      (response) => {
        showAlertAutoClose("Deleted Successfully...", "success");
        closeModal();
        loadTableData();
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openDeleteModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Member Deletion!
        </span>
      ),
      alertFunction: (option) => {
        if (option === "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to delete this Member? This action cannot be reverted
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };

//   const viewEditForm = (id: any) => {
//     const subscription = post(USER_URLS.GET_ONE, { id: id }).subscribe(
//       (response: any) => {
//         openOfficesForm(response.data);
//       }
//     );
//     return () => {
//       subscription.unsubscribe();
//     };
//   };
  const retireData = (id: any) => {
    const subscription = post(USER_URLS.STATUS_UPDATE, { id: id }).subscribe(
      (response) => {
        showAlertAutoClose("Deleted Successfully...", "success");
        closeModal();
        loadTableData();
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openretireModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Member Retire!
        </span>
      ),
      alertFunction: (option) => {
        if (option === "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to Retire this Member? This action cannot be reverted
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };
  

  const buttons = [
    // {
    //   label: "",
    //   type: "icon",
    //   leftIcon: " fa-user",
    //   classList: ["smart-kgr-table-edit-icon"],
    //   onClick: (data: any) => {
    //     openretireModal(data["ID"]);
    //   },
    // },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["smart-kgr-table-delete-icon"],
      onClick: (data: any) => {
        openDeleteModal(data["ID"]);
      },
    },
    
  ];
  const statusTags = [
    { value: 5, label: "Active", class: "is-primary" },
    { value: 10, label: "Inactive", class: "is-danger" },
  ];
  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width: "5" },
  
    {
      title: "URL",
      index: "sd_url",
      width: "10",
    },
    {
      title: "Email Address",
      index: "email_id",
      width: "10",
    },
    {
      title: "Over All Score",
      index: "overall_score",
      width: "10",
    },
    {
      title: "Date",
      index: "created_time",
      width: "15",
      type:"date",
      dateFormat:"DD/MM/YYYY"
    },
  
   

  
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];
  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-6",
      custom: <p className="is-size-4 has-text-weight-bold"></p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-4",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-2",
      align: "CENTER",
      buttons: [
        {
          type: "REFRESH",
          action: loadTableData,
        },
        //   {
        //   label: "ADD",
        //   icon: "fa-plus",
        //   type: "CUSTOM",
        //   action:openOfficesForm,
        // },
      ],
    },
  ];

  return (
    <>
      <div className="sd-efl-input">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          tableProps={{
            className: " is-hoverable is-bordered is-striped smart-kgr-table",
            isResponsive: true,
            searchPlaceHolder: "Search",
          }}
          paginationProps={{
            pageSize: 10,
          }}
        />
      </div>
    </>
  );
};




export default CSPList