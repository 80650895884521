import { motion } from "framer-motion";
import "./help.scss";

const stats = [
  { value: "50+", label: "CLIENTS AROUND THE WORLD" },
  { value: "10+", label: "AWARD WINNING" },
  { value: "97%", label: "BUSINESS GROWTH" },
  { value: "40+", label: "TEAM MEMBERS" },
];

const StatsSection = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-multiline is-centered">
          {stats.map((stat, index) => (
            <div key={index} className="column is-3-desktop is-6-tablet">
              <motion.div
                className="box stat-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                whileHover={{ scale: 1.05 }}
              >
                <motion.h1
                  className="stat-value"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.6, delay: index * 0.3 }}
                >
                  {stat.value}
                </motion.h1>
                <p className="stat-label">{stat.label}</p>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
