import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface ScorePieChartProps {
  overall_score: number; // Overall score percentage (0-100)
  status:string
}

const ScorePieChart: React.FC<ScorePieChartProps> = ({ overall_score,status }) => {
  const validScore = Math.max(0, Math.min(overall_score, 100)); // Ensure score is between 0 and 100

  // check if status and make color change  status is danger and warning suucss 
  let color = "#00ff48";
  if (status === "danger") {
    color = "#ff0d00";
  } else if (status === "warning") {
    color = "#ffea4a";
  }  


  return (
    <div className="is-flex  is-justify-content-center">
      {/* <h2 className="text-xl font-semibold mb-2">Overall Score</h2> */}
      <div className=" pie-chart-card" >
        <CircularProgressbarWithChildren
          value={validScore}
          styles={buildStyles({
            pathColor: color,
            trailColor: "",
            textSize: "32px",
          })
        }
          strokeWidth={8}
        >
          {/* Centered Text */}
          <div className="is-flex is-flex-direction-column is-justify-content-center ">
            <span className="is-size-4 has-text-centered">{validScore}%</span>
            <div className="is-size-6">Overall Score</div>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

export default ScorePieChart;
