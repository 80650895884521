import React, { useEffect } from "react";
import DashboardCards from "./DashboardCards";
import "../../assets/scss/kgr.scss";
import DashBoardChart from "./DashBoardChart";
import { useSiteContext } from "../../contexts/SiteProvider";
const DashBoard: React.FC = () => {

  const { setTitle } = useSiteContext();
    useEffect(() => {
      setTitle ("Dashboard");
    }, []);
  return (
    <div>
      {" "}
      {/* Dashboard */}
      <div className="">
        {" "}
        <DashboardCards />{" "}
      </div>
      <div className="columns">
        <div className="column is-8">
          {" "}
          <DashBoardChart />{" "}
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
