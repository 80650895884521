import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  isModalOpen: boolean;
  modalOptions: any | null;
}

const initialState: ModalState = {
  isModalOpen: false,
  modalOptions: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<any>) => {
      state.modalOptions = action.payload;
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.modalOptions = null;
      state.isModalOpen = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
