import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { useSiteContext } from "../../../contexts/SiteProvider";

import { ADMIN_MENU_SETTINGS_URLS } from "../../../api/AdminUrls";
import { menu_Parent_get_select, role_get_select_desc } from "../../../services/site/SelectBoxServices";
import { post } from "../../../services/smartApiService";

interface HeaderProps {
  loadTableData: () => void;
  data: any;
}
const validationSchema = Yup.object({
  order_number: Yup.string()
    .required("Order number is required")
    .matches(/^\d+$/, "Order number must be a number"),
//   parent_id: Yup.object(),
  label: Yup.string()
    .required("Label is required")
    .max(50, "Label must not exceed 50 characters"),
  icon: Yup.string().required("Icon is required"),
  link: Yup.string().required("Link is required"),
  roles: Yup.array()
    .min(1, "At least one role must be selected")
    .required("Role is required"),
});

const AdminMenuForm: React.FC<HeaderProps> = ({ loadTableData, data }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: data,
  });

  const { closeModal } = useSiteContext();
  const [allRole, setAllRole] = useState([]);
  const [parent, setParent] = useState([]);
  useEffect(() => {
    role_get_select_desc((data: any) => setAllRole(data.data));
    menu_Parent_get_select((data: any) => setParent(data.data));
  }, []);

  const onSubmit = (formData: any) => {
    let url = ADMIN_MENU_SETTINGS_URLS.INSERT;
    if (formData.ID !== undefined) {
      formData["ID"] = formData.ID;
      url = ADMIN_MENU_SETTINGS_URLS.UPDATE;
    }
    const payload = { ...formData };
    const subscription = post(url, payload).subscribe(() => {
      closeModal();
      loadTableData();
    });
    return () => subscription.unsubscribe();
  };
  const roles= [{value:"USER",label: "User"},{value:"ADMIN",label: "Admin"}]

  const elements: SdsFormElementProps[] = [
   
    {
      type: "SELECT_BOX",
      width: 6,
      name: "parent_id",
      element: {
        label: "Select Parent",
        isRequired: true,
        options: parent
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "label",
      element: {
        label: "Enter label",
        placeHolder: "Enter label",
        isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "icon",
      element: {
        label: "Enter Icon",
        placeHolder: "Enter Icon",
        isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "link",
      element: {
        label: "Enter Link",
        placeHolder: "Enter Link",
        isRequired: true,
      },
    },
    {
      type: "SELECT_BOX",
      width: 9,
      name: "roles",
      element: {
        label: "Select Roles Allowed to Access",
        options:allRole,
        isMulti: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: 3,
      name: "order_number",
      element: {
        label: "Enter Order No.",
        placeHolder: "Ex.1234",
        isRequired: true,
      },
    },

    {
      type: "BUTTON",
      width: 12,
      name: "submit",
      class_name: "has-text-right",
      element: {
        classList: ["mt-4 smart-action-button"],
        label: data.ID ? "Update" : "Add",
        onClick: handleSubmit(onSubmit),
      },
    },
  ];

  return (
    <div className="sd-hrms-input">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};

export default AdminMenuForm;
