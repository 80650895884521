import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import SmartHeader from '../../../components/SmartHeader';
import ScrollToTop from '../ScrollToTop';

const PrivacyPolicy: React.FC = () => {
    const companyName = "HDT Solutions Pvt Limited";
    const companyEmail = "support@hdtsolution.com";
    const companyWebsite = "www.hdtsolution.com";
    const effectiveDate = "March 13, 2010";
    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);
    }, []);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5 }
        }
    };

    const sectionVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: 0.6 }
        }
    };

    return (
        <>
       <SmartHeader title=' Privacy Policy' subTitle={`Effective Date: ${effectiveDate}`} />
        <motion.div
            className="privacy-container container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <section className="section">
                <div className="container">
                    {/* <motion.h1
                        className="title is-2 has-text-centered privacy-title"
                        variants={itemVariants}
                    >
                        Privacy Policy
                    </motion.h1> */}

                    {/* <motion.div className="notification is-light privacy-effective-date" variants={itemVariants}>
                        <p className="has-text-centered">Effective Date: {effectiveDate}</p>
                    </motion.div> */}

                    <motion.div className="content privacy-content" variants={itemVariants}>
                        <p>
                            This Privacy Policy describes how {companyName} ("we", "us", or "our") collects, uses, and shares your personal information when you use our website, products, or services (collectively, the "Services").
                        </p>
                        <p>
                            We take your privacy seriously. Please read this Privacy Policy carefully to understand our practices regarding your personal data and how we will treat it.
                        </p>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>1. Information We Collect</motion.h2>
                            <p>
                                We collect several types of information from and about users of our Services, including:
                            </p>
                            <div className="columns">
                                <div className="column">
                                    <div className="notification is-light privacy-info-card">
                                        <h3 className="title is-5">Personal Information</h3>
                                        <p>This may include:</p>
                                        <ul>
                                            <li>Name</li>
                                            <li>Email address</li>
                                            <li>Telephone number</li>
                                            <li>Postal address</li>
                                            <li>Username and password</li>
                                            <li>Billing information</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="notification is-light privacy-info-card">
                                        <h3 className="title is-5">Usage Information</h3>
                                        <p>This may include:</p>
                                        <ul>
                                            <li>IP address</li>
                                            <li>Browser type</li>
                                            <li>Operating system</li>
                                            <li>Pages visited</li>
                                            <li>Time spent on pages</li>
                                            <li>Referring website</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>2. How We Collect Information</motion.h2>
                            <p>We collect information in the following ways:</p>
                            <ul>
                                <li><strong>Direct Collection:</strong> Information you provide to us directly when you register for an account, fill out a form, make a purchase, or communicate with us.</li>
                                <li><strong>Automated Collection:</strong> Information collected automatically through cookies and similar technologies when you use our Services.</li>
                                <li><strong>Third-Party Sources:</strong> We may receive information about you from third-party partners, such as business partners, marketing agencies, and analytics providers.</li>
                            </ul>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>3. How We Use Your Information</motion.h2>
                            <p>We use the information we collect for various purposes, including to:</p>
                            <div className="tile is-ancestor">
                                <div className="tile is-parent">
                                    <article className="tile is-child notification is-primary privacy-tile">
                                        <p className="title is-5">Provide Services</p>
                                        <p>Operate, maintain, and deliver our products and services</p>
                                    </article>
                                </div>
                                <div className="tile is-parent">
                                    <article className="tile is-child notification is-info privacy-tile">
                                        <p className="title is-5">Improve Services</p>
                                        <p>Understand how our Services are used to enhance user experience</p>
                                    </article>
                                </div>
                                <div className="tile is-parent">
                                    <article className="tile is-child notification is-success privacy-tile">
                                        <p className="title is-5">Communicate</p>
                                        <p>Send updates, security alerts, and support messages</p>
                                    </article>
                                </div>
                            </div>
                            <div className="tile is-ancestor">
                                <div className="tile is-parent">
                                    <article className="tile is-child notification is-warning privacy-tile">
                                        <p className="title is-5">Marketing</p>
                                        <p>Send relevant promotional communications (with consent)</p>
                                    </article>
                                </div>
                                <div className="tile is-parent">
                                    <article className="tile is-child notification is-danger privacy-tile">
                                        <p className="title is-5">Security</p>
                                        <p>Detect, prevent, and address technical or security issues</p>
                                    </article>
                                </div>
                                <div className="tile is-parent">
                                    <article className="tile is-child notification is-link privacy-tile">
                                        <p className="title is-5">Legal Compliance</p>
                                        <p>Comply with applicable laws, regulations, and legal processes</p>
                                    </article>
                                </div>
                            </div>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>4. Sharing Your Information</motion.h2>
                            <p>We may share your personal information with:</p>
                            <ul>
                                <li><strong>Service Providers:</strong> Third-party vendors who provide services on our behalf, such as hosting, data analysis, payment processing, and customer service.</li>
                                <li><strong>Business Partners:</strong> Companies with whom we partner to offer joint promotional offers or provide integrated services.</li>
                                <li><strong>Legal Entities:</strong> In response to a legal request if we believe disclosure is required by applicable law or to protect our rights, privacy, safety, or property.</li>
                                <li><strong>Business Transfers:</strong> In connection with a corporate transaction, such as a merger, acquisition, or sale of assets.</li>
                            </ul>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>5. Data Security</motion.h2>
                            <p>
                                We implement appropriate technical and organizational measures to protect the personal information we collect and process. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
                            </p>
                            <div className="box privacy-security-box">
                                <h3 className="title is-5">Our Security Measures Include:</h3>
                                <div className="columns">
                                    <div className="column">
                                        <ul>
                                            <li>Encryption of sensitive data</li>
                                            <li>Secure networks and servers</li>
                                            <li>Regular security assessments</li>
                                        </ul>
                                    </div>
                                    <div className="column">
                                        <ul>
                                            <li>Access controls and authentication</li>
                                            <li>Employee training on data protection</li>
                                            <li>Incident response procedures</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>6. Your Data Protection Rights</motion.h2>
                            <p>
                                Depending on your location, you may have certain rights regarding your personal information, including:
                            </p>
                            <div className="card privacy-rights-card">
                                <div className="card-content">
                                    <div className="content">
                                        <ul>
                                            <li><strong>Access:</strong> The right to request copies of your personal information.</li>
                                            <li><strong>Rectification:</strong> The right to request that we correct inaccurate information about you.</li>
                                            <li><strong>Erasure:</strong> The right to request that we delete your personal information in certain circumstances.</li>
                                            <li><strong>Restriction:</strong> The right to request that we restrict the processing of your information in certain circumstances.</li>
                                            <li><strong>Data Portability:</strong> The right to receive your personal information in a structured, commonly used format.</li>
                                            <li><strong>Objection:</strong> The right to object to our processing of your personal information.</li>
                                        </ul>
                                        <p>
                                            To exercise any of these rights, please contact us at {companyEmail}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>7. Cookies and Similar Technologies</motion.h2>
                            <p>
                                We use cookies and similar tracking technologies to collect and use information about you and your interaction with our Services. We use these technologies to:
                            </p>
                            <ul>
                                <li>Recognize you when you return to our Services</li>
                                <li>Store your preferences and settings</li>
                                <li>Analyze how our Services are used</li>
                                <li>Personalize content and advertising</li>
                                <li>Facilitate social sharing functionality</li>
                            </ul>
                            <p>
                                You can control cookies through your browser settings and other tools. However, if you block certain cookies, you may not be able to use all features of our Services.
                            </p>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>8. Children's Privacy</motion.h2>
                            <p>
                                Our Services are not intended for children under the age of 16, and we do not knowingly collect personal information from children under 16. If we learn that we have collected personal information from a child under 16, we will take steps to delete such information as soon as possible.
                            </p>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>9. International Data Transfers</motion.h2>
                            <p>
                                Your personal information may be transferred to, and processed in, countries other than the country in which you reside. These countries may have data protection laws that are different from the laws of your country.
                            </p>
                            <p>
                                When we transfer your information to other countries, we will use appropriate safeguards to ensure that your personal information receives an adequate level of protection in the countries to which it is transferred.
                            </p>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>10. Changes to This Privacy Policy</motion.h2>
                            <p>
                                We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the new Privacy Policy on this page and, where appropriate, notifying you by email.
                            </p>
                            <p>
                                We encourage you to review this Privacy Policy periodically for any changes.
                            </p>
                        </motion.section>

                        <motion.section className="privacy-section" variants={sectionVariants}>
                            <motion.h2 className="title is-4" variants={itemVariants}>11. Contact Us</motion.h2>
                            <p>
                                If you have any questions about this Privacy Policy or our data practices, please contact us at:
                            </p>
                            <div className="box privacy-contact-box">
                                <p>
                                    <strong>{companyName}</strong><br />
                                    Email: {companyEmail}<br />
                                    Website: {companyWebsite}
                                </p>
                            </div>
                        </motion.section>
                    </motion.div>
                </div>
            </section>

            {/* <motion.div
                className="back-to-top"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                variants={itemVariants}
            >
                <button className="button is-primary is-rounded">
                    <span className="icon">
                        <i className="fas fa-arrow-up"></i>
                    </span>
                    <span>Back to Top</span>
                </button>
            </motion.div> */}
            <ScrollToTop />
        </motion.div>
        
        </>
    );
};

export default PrivacyPolicy;