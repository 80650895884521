import { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { ADMIN_MENU_SETTINGS_URLS } from "../../../api/AdminUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get, post } from "../../../services/smartApiService";
import AdminMenuForm from "./AdminMenuForm";


const AdminMenuTable = () => {
  const [tabData, setTabData] = useState([]);
  const { openModal, closeModal,setTitle } = useSiteContext();
  useEffect(() => {
    setTitle ("Menu Settings");
  }, []);

  const loadTableData = () => {
    let URL = ADMIN_MENU_SETTINGS_URLS.GET_ALL;
    const subscription = get(URL).subscribe((response) => {
      setTabData(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const openOfficesForm = (data: any) => {
    let options = {
      title: <>{data.ID ? "Menu Settings Update" : "Menu Settings Form"}</>,
      content: <AdminMenuForm loadTableData={loadTableData} data={data} />,
      className: "sd-efl-modal",
      closeBody: false,
      width: 80,
    };
    openModal(options);
  };

  const deleteData = (id: any) => {
    const subscription = post(ADMIN_MENU_SETTINGS_URLS.DELETE, {
      id: id,
    }).subscribe((response) => {
      showAlertAutoClose("Deleted Successfully...", "success");
      closeModal();
      loadTableData();
      // setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const openDeleteModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Menu Deletion!
        </span>
      ),
      alertFunction: (option) => {
        if (option == "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to delete this Menu? This action cannot be reverted
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };

  const viewEditForm = (id: any) => {
    const subscription = post(ADMIN_MENU_SETTINGS_URLS.GET_ONE, {
      ID: id,
    }).subscribe((response: any) => {
      openOfficesForm(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: " fa-pencil-square",
      classList: ["smart-hims-table-edit-icon"],
      onClick: (data: any) => {
        viewEditForm(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["smart-hims-table-delete-icon"],
      onClick: (data: any) => {
        openDeleteModal(data["ID"]);
      },
    },
  ];

  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width: "5" },   { title: "Order No.", index: "order_number" },   
    {
      title: "Label",
      index: "label",
    },
    {
      title: "Icon",
      index: "icon",
    },
    {
      title: "Link",
      index: "link",
    },
    {
      title: "roles",
      index: "roles",
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];
  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-6",
      custom: <p className="is-size-4 has-text-weight-bold"></p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-4",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-2",
      align: "CENTER",
      buttons: [
        {
          type: "REFRESH",
          action: loadTableData,
        },
        {
          label: "Add",
          icon: "fa-plus",
          type: "CUSTOM",
        //   className: "smart-third-button",
          action: openOfficesForm,
        },
      ],
    },
  ];

  return (
    <>
      <div className="sd-efl-input">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          tableProps={{
            className: "is-hoverable is-bordered smart-kgr-table",
            isResponsive: true,
            searchPlaceHolder: "Search",
          }}
          paginationProps={{
            pageSize: 50,
          }}
        />
      </div>
    </>
  );
};

export default AdminMenuTable;
