import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ToggleSwitch from "./ToggleSwitch";
import { useSiteContext } from "../../contexts/SiteProvider";
import { ADMIN_USER_LOGO, LOGO, SITE_LOGO } from "../../services/ImageService";

interface HeaderProps {
  setIsOpen: (open: boolean) => void;
  setIsDark: (open: boolean) => void;
  isOpen: boolean;
  isDark: boolean;
}
const EFHeader: React.FC<HeaderProps> = ({
  setIsOpen,
  isOpen,
  setIsDark,
  isDark,
}) => {
  const navigate = useNavigate();
  const basenav = "/";
  const { user, logout,title,setTitle } = useSiteContext();
  const [mobileScreen, setMobileScreen] = useState<boolean>(
    window.innerWidth <= 768
  );
  const toggleTheme = () => setIsDark(!isDark);
  //console.log("user ", user);
  const navigateLink = (index: any) => {
    // navigate(basenav + "/" + index);
    navigate(basenav + index);
  };
  const handleLogout = () => {
    logout();
    navigateLink("");
  };
  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsOpen(false);
      // console.log("Mobile Screen")
    }
  }, [mobileScreen]);

  const MobileSideOpen = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setTitle(title)
  }, []);
  return (
    <nav
      className="navbar is-transparent efl-main-nav"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div className="header-brand-logo is-hidden-desktop smart-mobile-header-logo">
          <img src={SITE_LOGO} alt="Brand Logo" />
        </div>
        <div className="is-flex  is-justify-content-end ml-6 is-hidden-desktop">
        <span className="ml-5 mt-3 is-size-5">{user?.ename||"Admin"}</span>
            <figure className="image is-48x48 is-rounded with-out-pointer ml-5">
              <img src={ADMIN_USER_LOGO} alt="User" />
            </figure>
            
          </div>
        <a
          role="button"
          className="navbar-burger "
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => MobileSideOpen()}
        >
          {/* <span aria-hidden="true">cd</span> */}

          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      
        
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          {/* <a onClick={() => setIsOpen(!isOpen)} className="navbar-item">
            <i className="fa fa-bars "></i>
          </a> */}
          <div className="is-size-3 has-text-weight-bold mt-1 ml-4">
        <span className="site-heder-title" > {title}</span> 
          </div>
        </div>

        <div className="navbar-end">
          {/* <div className="dark-switch-item mt-4">
            <ToggleSwitch isDark={isDark} toggleTheme={toggleTheme} />
          </div> */}
          <div className=" navbar-item  mr-1 smart-elf-notification">
            {/* <Notificaton /> */}

            <div className="dropdown is-active" key="s2"></div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable  ">
          
            <a className="navbar-link">
             
             <img
               src={ADMIN_USER_LOGO}
               alt=""
               className="is-64x64 is-rounded with-out-pointer"
             />
              <span className=" ml-2">{user?.ename}</span>
           </a>
          
          

            <div className="navbar-dropdown is-right">
             
              <hr className="navbar-divider" />
              <a onClick={() => handleLogout()} className="navbar-item">
                <span className="icon mr-1">
                  <i className="fa fa-sign-out"></i>
                </span>{" "}
                Logout
              </a>
            </div>
          
          </div>
        </div>
      </div>
       
         
          
    </nav>
  );
};

export default EFHeader;
