import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { showAlertAutoClose } from "../services/notifyService";
import { getUserSession, setUserSession } from "../services/sessionService";



// Define the context shape
interface SiteContextProps {
  user:any,
  setUser: (data: any | null) => void;
  openModal: (options: any) => void;
  closeModal: () => void;
  isModalOpen: boolean;
  modalOptions:any;
  logout: () => void;
  handleUserChange: (name: string, value: any) => void;
  hasTriggered: boolean;
  setHasTriggered: (value: boolean) => void;
  title: string;
  setTitle: (title: string) => void;
  setIsVisible: (value: boolean) => void;
  isVisible: boolean;
}

// Create the context with the default value of `undefined`
const SiteContext = createContext<SiteContextProps | undefined>(undefined);

// Custom hook to use the SiteContext
export const useSiteContext = () => {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error("useSiteContext must be used within a SiteProvider");
  }
  return context;
};

// Define the props for SiteProvider
interface SiteProviderProps {
  children: ReactNode;
}

export const SiteProvider: React.FC<SiteProviderProps> = ({ children }) => {
  const [user, setUserData] = useState<any | null>(getUserSession());
  const [modalOptions, setModalOptions] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasTriggered, setHasTriggered] = useState(false);
  const [title, setTitle] = useState(" HD Tech Solutions ");
   const [isVisible, setIsVisible] = useState<boolean>(false);
  

  // Handle user state changes
  const handleUserChange = (name: string, value: any) => {
    setUserData((prev:any) => ({ ...prev, [name]: value }));
  };

  // Set user and persist session data
  const setUser = (data: any | null) => {
    setUserData(data);
    setUserSession(data);
  };

  // Open modal with given options
  const openModal = useCallback((options: any) => {
    setModalOptions(options);
    setIsModalOpen(true);
  }, []);

  // Close modal
  const closeModal = useCallback(() => {
    setModalOptions(null);
    setIsModalOpen(false);
  }, []);

  // Update user session on load
  const updateUserOnLoad = () => {
    const session_data = getUserSession();
    if (session_data) {
      setUserData(session_data);
      if (session_data.ename !== undefined) {
        // Additional logic for `ename` if needed
      }
    }
  };

  // Logout user and show alert
  const logout = () => {
    showAlertAutoClose("Log Out Successful", "success");
    setUser(null);
  };

  // Effect to update user on component mount
  useEffect(() => {
    updateUserOnLoad();
  }, []);

  return (
    <SiteContext.Provider
      value={{
        user,
        setUser,
        openModal,
        closeModal,
        isModalOpen,
        modalOptions,
        logout,
        handleUserChange,
        hasTriggered,
        setHasTriggered,
        title,
        setTitle,
        setIsVisible,
        isVisible
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};