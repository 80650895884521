import { motion } from "framer-motion";
import config from "../../config/config";
import { FACEBOOK, IN, TWITTER, YOUTUBE } from "../../services/ImageService";

export default function Footer() {

   const headerLinks = [
      { name: "Home", link: "/", icon: "home" },
      {
        name: "Our Products",
        icon: "box",
        link: "/content-security-policy-evaluation-and-solution",
        type: "dropdown",
       
      },
  
      { name: "Our Services", link: "/offered-services", icon: "phone" },
     
      { name: "About Us", link: "/about-us", icon: "info" },
      // { name: "Pages", link: "/privacy-policy", icon: "book" },
      // { name: "Blog", link: "/terms-condition", icon: "newspaper" },
      { name: "Contact Us", link: "/contact-us", icon: "mail" },
      { name: "Terms & Conditions", link: "/terms-and-conditions", icon: "briefcase" },
      { name: "Privacy Policy", link: "/privacy-policy", icon: "lock" },
    ];
  return (
    <footer className="footer has-background-dark has-text-white p-6">
      <div className="container">
        <div className="columns">
          {/* Logo & Description */}
          <div className="column is-4">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <h2 className="title is-4 has-text-white">HD TECH SOLUTIONS</h2>
              <p>NEXT-GEN IT ULTIMATE PROTECTOR.</p>
              <div className="social-icons mt-3 is-flex">
                {[FACEBOOK, TWITTER, IN, YOUTUBE].map((icon, index) => (
                  <motion.a
                    key={index}
                    href="#"
                    target="blank"
                    // className={`icon-box fa fa-${icon}`}
                    whileHover={{ scale: 1.2 }}
                    transition={{ duration: 0.3 }}
                  > <figure className="image is-32x32 mx-1">
                    <img className="is-rounded" src={icon} alt="" />
                  </figure>
                  </motion.a>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Quick Links */}
          <div className="column is-4">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
              <h2 className="title is-5 has-text-white">Quick Links</h2>
              <ul className="quick-links">
                {headerLinks.map((item:any, index:number) => (
                  <li key={index}><a className="footer-quick-link" href={item.link} >{item.name}</a></li>
                ))}
              </ul>
            </motion.div>
          </div>

          {/* Recent Posts */}
          {/* <div className="column is-4">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.7 }}>
              <h2 className="title is-5 has-text-white">Recent Posts</h2>
              <ul className="recent-posts">
                {["Achieve Goals With Precision & Speed", "Clients Achieve Goals With Precision"].map((post, index) => (
                  <li key={index}>
                    <span className="icon-text"><span className="icon fa fa-calendar"></span> April 27, 2024</span>
                    <p>{post}</p>
                  </li>
                ))}
              </ul>
            </motion.div>
          </div> */}

          {/* Contact Us */}
          <div className="column is-4">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
              <h2 className="title is-5 has-text-white">Contact Us</h2>
              <ul className="contact-info">
                <li><i className="fa fa-map-marker"></i>
               Ameenpur,Hyderabad,            Telangana-502 032.
                  </li>
                <li><i className="fa fa-phone"></i>{config?.CONTACT_DETAILS?.phone_1},{config?.CONTACT_DETAILS?.phone_2}</li>
                <li><i className="fa fa-envelope"></i>{config?.CONTACT_DETAILS?.email} </li>
              </ul>
              {/* <motion.a href="#" className="button is-link mt-3" whileHover={{ scale: 1.1 }}>Get A Quote →</motion.a> */}
            </motion.div>
          </div>
        </div>
      </div>
    </footer>
  );
}
