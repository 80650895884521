import { motion } from "framer-motion";
import "./projects.scss";
import { CAN, HRMS_1, HRMS_2, HRMS_3, HRMS_4, HRMS_5, HRMS_6, HRMS_7 } from "../../../services/ImageService";
import ProductSectionItems from "./ProductSectionItems";
import SmartHeader from "../../../components/SmartHeader";

const hrmsData = [
    {
      title: "Employee Management",
      description: "Streamline employee records and ensure accurate data management.",
      image: HRMS_1,
      reverse: false,
      list: ["Track employee details", "Manage job roles", "Automate record updates"]
    },
    {
      title: "Payroll Processing",
      description: "Automate salary calculations and tax deductions efficiently.",
      image: HRMS_2,
      reverse: true,
      list: ["Salary computation", "Tax compliance", "Direct deposit processing"]
    },
    {
      title: "Attendance & Leave Tracking",
      description: "Monitor employee work hours and leave requests with ease.",
      image: HRMS_3,
      reverse: false,
      list: ["Automated timesheets", "Leave approvals", "Real-time tracking"]
    },
    {
      title: "Performance Management",
      description: "Evaluate and improve employee performance with structured feedback.",
      image: HRMS_4,
      reverse: true,
      list: ["Goal setting", "360-degree feedback", "Continuous improvement"]
    },
    {
      title: "Recruitment & Onboarding",
      description: "Simplify hiring and onboarding new employees seamlessly.",
      image: HRMS_5,
      reverse: false,
      list: ["Job postings", "Candidate tracking", "Onboarding workflows"]
    },
    {
      title: "Training & Development",
      description: "Enhance employee skills with structured learning programs.",
      image: HRMS_6,
      reverse: true,
      list: ["Skill assessments", "Learning modules", "Progress tracking"]
    },
    {
      title: "Benefits & Compensation",
      description: "Manage employee benefits and incentives efficiently.",
      image: HRMS_7,
      reverse: false,
      list: ["Health insurance", "Retirement plans", "Performance bonuses"]
    },
    {
      title: "HR Analytics & Reports",
      description: "Gain insights into workforce trends and HR performance.",
      image:HRMS_3,
      reverse: true,
      list: ["Employee retention analysis", "Payroll forecasting", "Diversity metrics"]
    },
    {
      title: "Compliance & Policy Management",
      description: "Ensure adherence to labor laws and company policies.",
      image: HRMS_4,
      reverse: false,
      list: ["Regulatory compliance", "Company policy tracking", "Automated reporting"]
    },
    {
      title: "Employee Self-Service Portal",
      description: "Enable employees to manage their HR-related activities independently.",
      image: HRMS_5,
      reverse: true,
      list: ["View payslips", "Update personal info", "Request time off"]
    }
  ];
  

const HrmsSection = () => {
  return (
    <><SmartHeader title='HRMS' subTitle=''/>
    
  <ProductSectionItems data={hrmsData}/>
  </>
   
  );
};

export default HrmsSection;
