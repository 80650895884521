import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../contexts/SiteProvider";
import { Outlet, useLocation } from "react-router-dom";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import "./userLayout.scss";

const UserLayout = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isDark, setIsDark] = useState<boolean>(true);
  const { user } = useSiteContext();
  const location = useLocation();
  useEffect(() => {
    // scroll has to go to top'
    window.scrollTo(0, 0);
   
  }, [location.pathname]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  return (
    <div
      className={`container is-fluid smart-dark-efl-container p-0 ${
        !isDark ? "smart-efl-container" : ""
      }`}
    >
      <div className="">
        <UserHeader />
      </div>
      <div className={`smart-user-main-div ${isOpen ? "expanded" : ""}`}>
        <Outlet />
      </div>
      <div className="p-0">
        <UserFooter />
      </div>
    </div>
  );
};

export default UserLayout;
