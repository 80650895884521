import React, { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { post } from "../../services/smartApiService";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../services/notifyService";
import { useSiteContext } from "../../contexts/SiteProvider";
import OfferSettingsFrom from "./HomeImageForm";
import { HOME_IMAGES_URLS } from "../../api/SiteSettingsUrl";

const HomeImage = () => {
  const { openModal, closeModal } = useSiteContext();
  const [tabData, setTabData] = useState([]);

  const loadTableData = () => {
    let URL = HOME_IMAGES_URLS.GET_ALL;
    const subscription = post(URL, {}).subscribe((response) => {
      setTabData(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const openjobForm = (data: any) => {
    let options = {
      title: <>{data.ID ? "Home Image Form" : "Home Image Addition Form"}</>,
      content: <OfferSettingsFrom loadTableData={loadTableData} data={data} />,
      width: 60,
      className: "sd-efl-modal",
      closeBody: false,
    };
    openModal(options);
  };

  const deleteData = (id: any) => {
    const subscription = post(HOME_IMAGES_URLS.DELETE, { id: id }).subscribe(
      (response) => {
        showAlertAutoClose("Deleted Successfully...", "success");
        closeModal();
        loadTableData();
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openDeleteModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Home Image Deletion!
        </span>
      ),
      alertFunction: (option) => {
        if (option == "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to delete this Home Image? This action cannot be reverted
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };

  const viewEditForm = (id: any) => {
    const subscription = post(HOME_IMAGES_URLS.GET_ONE, {
      id: id,
    }).subscribe((response: any) => {
      openjobForm(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: " fa-pencil-square-o",
      classList: ["smart-kgr-table-edit-icon"],
      onClick: (data: any) => {
        viewEditForm(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["smart-kgr-table-delete-icon"],
      onClick: (data: any) => {
        openDeleteModal(data["ID"]);
      },
    },
  ];

  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width:"5" },
    {
      title: "Image Title",
      index: "image_title",
    },

    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "15",
    },
  ];
  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-7",
      custom: <p className="is-size-5">Home Image</p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-3",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-2",
      align: "CENTER",
      buttons: [
        {
          type: "REFRESH",
          action: loadTableData,
        },
        {
          label: "ADD",
          icon: "fa-plus",
          type: "CUSTOM",
          action: () => openjobForm({}),
        },
      ],
    },
  ];
  return (
    <>
      <div className="">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          tableProps={{
            className: " is-hoverable is-bordered is-striped smart-kgr-table",
            isResponsive: true,
          }}
          // paginationProps={{
          //   pageSize: 5,
          // }}
        />
      </div>
    </>
  );
};

export default HomeImage;
