import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { post } from "../../../services/smartApiService";
import { useNavigate } from "react-router-dom";
import { showAlertAutoClose } from "../../../services/notifyService";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email_id: Yup.string().email("Invalid email").required("Email is required"),
  mobile_no: Yup.string()
    .matches(/^\d{10}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  billing_address: Yup.string().required("Billing address is required"),
  gst_no: Yup.string().required("GST number is required"),
  //   payment_start_time: Yup.date().required("Payment start time is required"),
  // payment_ref_no: Yup.string().required("Payment reference number is required"),
  //  payment_amount: Yup.number()

  //  .required("Payment amount is required"),
  //   payment_tax: Yup.number()
  //   .min(0, "Tax cannot be negative")
  //////   .max(100, "Tax cannot exceed 100%")
  //   .required("Payment tax is required"),
  //    payment_total_amount: Yup.string()
  //   .required("Payment total amount is required")
  // .test(
  //   "is-correct-total",
  //   "Total amount must be amount + (amount * tax / 100)",
  //   function (value) {
  //     const { payment_amount, payment_tax } = this.parent;
  //     const expectedTotal = payment_amount + (payment_amount * payment_tax) / 100;
  //     return value === expectedTotal;
  //   }
  // ),
});

declare global {
  interface Window {
    Razorpay: any;
  }
}

interface herderProps {
  calculateTotalPrice: () => number;
  isYearly: boolean;
  plan: any;
}
const PricingForm: React.FC<herderProps> = ({
  calculateTotalPrice,
  isYearly,
  plan,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const navigation = useNavigate();
  const handlePayment = async (order: any) => {
    try {
      const options = {
        key: "rzp_test_JeUbtukUDiNo4O", // From Razorpay Dashboard
        amount: order.order.amount,
        currency: order.order.currency,
        name: "HDT Solutions",
        // description: "Test Transaction",
        order_id: order.order.id,
        handler: async function (response: any) {
          console.log("response ", { ...response, id: order.id });
          let url = "payment/update_payment";
          post(url, { ...response, id: order.id }).subscribe((res) => {
            //console.log("respsonse ", response.data.data);
            console.log("response", res);
            reset();
            showAlertAutoClose(
              "Thank you for subscribing! 🎉 Your monthly subscription has been successfully activated. Please check your email for further details, including your account setup and next steps. If you don’t see the email, kindly check your spam or promotions folder.We’re excited to have you on board! ",
              "success"
            );
            navigation("/");
            //handlePayment(response.data.data);
            //  reset();
          });
          // const verifyRes = await fetch("http://localhost:5000/verify-payment", {
          //   method: "POST",
          //   headers: { "Content-Type": "application/json" },
          //   body: JSON.stringify(response),
          // });

          // const verifyData = await verifyRes.json();
          // alert(verifyData.message);
        },
        prefill: {
          name: order.name,
          email: order.email_id,
          contact: order.mobile_no,
        },
        theme: { color: "#3399cc" },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Payment failed", error);
    }
  };

  // Auto-update total amount when amount or tax changes
  // useEffect(() => {
  //     const total = paymentAmount + (paymentAmount * paymentTax) / 100;
  //     setValue("payment_total_amount", total, { shouldValidate: true });
  //   }, [paymentAmount, paymentTax, setValue]);

  const onSubmit = (data: any) => {
    let url = "/payment/insert";
    data["payment_amount"] = calculateTotalPrice();
    data["plan"] = plan.name;
    data["plan_period"] = isYearly ? "year" : "month";
    // console.log("Submit");

    const subscription = post(url, data).subscribe((response) => {
      console.log("respsonse ", response.data.data);
      handlePayment(response.data.data);
      //  reset();
    });
    return () => subscription.unsubscribe();
  };
  const elements: SdsFormElementProps[] = [
    {
      type: "LABEL",
      width: 12,
      name: "label",
      labelComponent: <p className="title is-4">Contact Information</p>,
    },
    {
      type: "TEXT_BOX",
      width: 12,
      name: "name",
      class_name: "p-1",
      element: { placeHolder: "Your Name", isRequired: true, label: "Name" },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "email_id",
      class_name: "p-1",
      element: { placeHolder: "Your Email", isRequired: true, label: "Email" },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "mobile_no",
      class_name: "p-1",
      element: {
        placeHolder: "Your Mobile Number",
        isRequired: true,
        label: "Mobile Number",
      },
    },
    {
      type: "LABEL",
      width: 12,
      name: "label",
      labelComponent: <p className="title is-4">Billing Information</p>,
    },
    {
      type: "TEXTAREA",
      width: 12,
      name: "billing_address",
      class_name: "p-1",

      element: {
        placeHolder: "Billing Address",
        isRequired: true,
        rows: 3,
        label: "Address",
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "gst_no",
      class_name: "p-1",
      element: { placeHolder: "GST Number", isRequired: true, label: "GST No" },
    },
    // {
    //   type: "TEXT_BOX",
    //   width: 6,
    //   name: "payment_ref_no",
    //   class_name: "p-1",
    //   element: {
    //     placeHolder: "Payment Reference No",
    //     isRequired: true,
    //     label: "Payment Ref No",
    //   },
    // },
    // {
    //   type: "TEXT_BOX",
    //   width: 6,
    //   name: "payment_amount",
    //   class_name: "p-1",
    //   element: {
    //     placeHolder: "Payment Amount",
    //     isRequired: true,
    //     disabled: true,
    //     label: " Amount",
    //   },
    // },
    // {
    //   type: "TEXT_BOX",
    //   width: 6,
    //   name: "payment_tax",
    //   class_name: "p-1",
    //   element: { placeHolder: "Payment Tax", isRequired: true, label: "Tax" },
    // },
    // {
    //   type: "TEXT_BOX",
    //   width: 12,
    //   name: "payment_total_amount",
    //   class_name: "p-1",
    //   element: {
    //     placeHolder: "Total Payment Amount",
    //     isRequired: true,
    //     label: "Total",
    //   },
    // },
    {
      type: "BUTTON",
      width: 12,
      name: "submit_button",
      class_name: "has-text-centered p-0",
      element: {
        classList: ["button", "smart-action-button"],
        onClick: handleSubmit(onSubmit),
        label: `Pay $${calculateTotalPrice().toFixed(2)} ${
          isYearly ? "Yearly" : "Monthly"
        }`,
      },
    },
  ];

  return (
    <div className="pricing-form-container">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};

export default PricingForm;
