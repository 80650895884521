const ROLE_URLS = {
  INSERT: "/role/insert",
  GET_ALL: "/role/get_all",
  UPDATE: "/role/update",
  GET_ONE: "/role/get_one",
  DELETE: "/role/delete_one",
  GET_ALL_SELECT: "/role/get_all_select",
  GET_ALL_SELECT_DESC: "/role/get_all_select_desc",
};

const USER_URLS = {
  INSERT: "/user/insert",
  GET_ALL: "/user/get_all",
  UPDATE: "/user/update",
  GET_ONE: "/user/get_one",
  DELETE: "/user/delete_one",
  GET_ALL_SELECT: "/user/get_all_select",
  ADMIN_RESET: "/user/admin_reset",
  APPROVED: "user/approve_user",
  STATUS_UPDATE:""
};
const ADMIN_MENU_SETTINGS_URLS = {
  GET_ALL: "/admin_menu/get_all",
  INSERT: "/admin_menu/insert",
  GET_ALL_SELECT: "/admin_menu/get_all_select",
  GET_ONE: "/admin_menu/get_one",
  DELETE: "/admin_menu/delete_one",
  UPDATE: "/admin_menu/update",
  ROLE_GET_ALL_SELECT: "/role/get_all_select",
  ROLE_GET_ALL_SELECT_LABEL: "/role/get_all_select_label",
};


const SITE_CIRCULAR = {
  INSERT: "/circular/insert",
  GET_ALL: "/circular/get_all",
  UPDATE: "/circular/update",
  GET_ONE: "/circular/get_one",
  DELETE: "/circular/delete_one",
  DOWNLOAD: "/circular/download_one",
  GET_ALL_SELECT: "/circular/get_all_select_types",
};
const EMAIL_SETTINGS_URLS = {
  GET_ALL: "/admin_mail/get_all",
  INSERT: "/admin_mail/insert",
  GET_ONE: "/admin_mail/get_one",
  DELETE: "/admin_mail/delete_one",
  UPDATE: "/admin_mail/update",
};
const CSP_SETTINGS_URLS = {
  GET_ALL: "/csp_settings/get_all",
  INSERT: "/csp_settings/insert",
  GET_ONE: "/csp_settings/get_one",
  DELETE: "/csp_settings/delete_one",
  UPDATE: "/csp_settings/update",
};

const PROFILE_URLS = {
  PASSWORD:"/user/user_reset"
}
export { ADMIN_MENU_SETTINGS_URLS, CSP_SETTINGS_URLS, EMAIL_SETTINGS_URLS, PROFILE_URLS, ROLE_URLS, SITE_CIRCULAR, USER_URLS };

