import React from 'react'
import { motion } from "framer-motion";
import './component.scss';

interface HeaderProps{
    LandingPage?: boolean;
    title?: string;
    subTitle?: string;

}
const SmartHeader:React.FC< HeaderProps> = ({LandingPage,title,subTitle}) => {
  return (
    <>
  
    {!LandingPage&&<div className="">
            <motion.div
              className="smart-header-intro"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h2>{title} </h2>
              <p>
             {subTitle}
              </p>
            </motion.div>
          </div>
    }
      </>
  )
}

export default SmartHeader