import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import SmartHeader from "../../../components/SmartHeader";
import ScrollToTop from "../ScrollToTop";
const TermsAndConditions: React.FC = () => {
  const companyName = "HDT Solutions Pvt Limited";
  const companyEmail = "support@hdtsolution.com";
  const companyWebsite = "www.hdtsolution.com";
  const effectiveDate = "March 13, 2010";
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const termsData = [
    {
      title: "1. Use of Services",
      content: (
        <>
          <p>
                Our Services include but are not limited to: CSP
                implementations, Digital Signature Workflow, mobile application
                development, website development, HRMS, HIMS, and other software
                solutions.
              </p>
              <p>
                You agree to use our Services only for lawful purposes and in
                accordance with these Terms. You agree not to use the Services:
              </p>
              <ul>
                <li>
                  In any way that violates any applicable federal, state, local,
                  or international law or regulation.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or
                  promotional material, including any "junk mail", "chain
                  letter," "spam," or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate the Company, a
                  Company employee, another user, or any other person or entity.
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits
                  anyone's use or enjoyment of the Services, or which, as
                  determined by us, may harm the Company or users of the
                  Services or expose them to liability.
                </li>
              </ul>
        </>
      ),
    },
    {
      title: "2. Intellectual Property",
      content: (
        <>
          <p>
                The Services and their entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof) are owned by{" "}
                {companyName}, its licensors, or other providers of such
                material and are protected by copyright, trademark, patent,
                trade secret, and other intellectual property or proprietary
                rights laws.
              </p>
              <p>
                These Terms permit you to use the Services for your personal,
                non-commercial use only, or for legitimate business purposes if
                you are using our Services on behalf of a business entity that
                has entered into a separate agreement with us.
              </p>
        </>
      ),
    },
    {
      title: "3. User Accounts",
      content: (
        <>
            <p>
                When you create an account with us, you must provide information
                that is accurate, complete, and current at all times. Failure to
                do so constitutes a breach of the Terms, which may result in
                immediate termination of your account on our Services.
              </p>
              <p>
                You are responsible for safeguarding the password that you use
                to access the Services and for any activities or actions under
                your password, whether your password is with our Services or a
                third-party service.
              </p>
              <p>
                You agree not to disclose your password to any third party. You
                must notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>
        </>
      ),
    },
    {
      title: "4. Limitation of Liability",
      content: (
        <>
         <p>
                In no event shall {companyName}, nor its directors, employees,
                partners, agents, suppliers, or affiliates, be liable for any
                indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from:
              </p>
              <ol type="a">
                <li>
                  Your access to or use of or inability to access or use the
                  Services;
                </li>
                <li>
                  Any conduct or content of any third party on the Services;
                </li>
                <li>Any content obtained from the Services; and</li>
                <li>
                  Unauthorized access, use or alteration of your transmissions
                  or content, whether based on warranty, contract, tort
                  (including negligence) or any other legal theory, whether or
                  not we have been informed of the possibility of such damage.
                </li>
              </ol>

        </>
      ),
    },
    {
      title: "5.Disclaimer",
      content: (
        <>
        <p>
                Your use of the Services is at your sole risk. The Services are
                provided on an "AS IS" and "AS AVAILABLE" basis. The Services
                are provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>
              <p>
                {companyName}, its subsidiaries, affiliates, and its licensors
                do not warrant that:
              </p>
              <ol type="a">
                <li>
                  The Services will function uninterrupted, secure or available
                  at any particular time or location;
                </li>
                <li>Any errors or defects will be corrected;</li>
                <li>
                  The Services are free of viruses or other harmful components;
                  or
                </li>
                <li>
                  The results of using the Services will meet your requirements.
                </li>
              </ol>
        </>
      ),
    },
    {
      title: "6.Governing Law",
      content: (
        <>
           <p>
                These Terms shall be governed and construed in accordance with
                the laws applicable in the jurisdiction where {companyName} is
                headquartered, without regard to its conflict of law provisions.
              </p>
              <p>
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect.
              </p>
        </>
      ),
    },
    {
      title: " 7. Changes to Terms",
      content: (
        <>
        <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material, we
                will try to provide at least 30 days' notice prior to any new
                terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>
              <p>
                By continuing to access or use our Services after those
                revisions become effective, you agree to be bound by the revised
                terms. If you do not agree to the new terms, please stop using
                the Services.
              </p>
        </>
      ),
    },
    {
      title: "8. Contact Us",
      content: (
        <>
           <p>
                If you have any questions about these Terms, please contact us
                at:
              </p>
        </>
      ),
    },
  ];

  return (
    <>
      <SmartHeader title="Terms and Conditions"  subTitle={`Effective Date: ${effectiveDate}`}/>

      <motion.div
        className="terms-container container p-0"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <section className="section ">
          <div className="container">
            {/* <motion.h1
            className="title is-2 has-text-centered terms-title"
            variants={itemVariants}
          >
            Terms and Conditions
          </motion.h1> */}

            {/* <motion.div
              className="notification is-light terms-effective-date"
              variants={itemVariants}
            >
              <p className="has-text-centered">
                Effective Date: {effectiveDate}
              </p>
            </motion.div> */}
            {/* {termsData.map((section, index) => (
        <div key={index} className="terms-section">
          <motion.div
            className="terms-header"
            onClick={() => toggleSection(index)}
          >
            <motion.h2
              className="title is-4"
              initial={{ opacity: 0.8 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.05 }}
            >
              {section.title}
            </motion.h2>
            <motion.span
              className="toggle-icon"
              animate={{ rotate: openIndex === index ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              ▼
            </motion.span>
          </motion.div>

          <motion.div
            className="terms-content"
            initial={{ height: 0, opacity: 0 }}
            animate={
              openIndex === index ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }
            }
            transition={{ duration: 0.3 }}
          >
            {section.content}
          </motion.div>
        </div>
      ))} */}

            <motion.div
              className="content terms-content"
              variants={itemVariants}
            >
              <p>
                Please read these Terms and Conditions ("Terms", "Terms and
                Conditions") carefully before using the {companyWebsite} website
                and our software products and services (collectively, the
                "Services") operated by {companyName} ("us", "we", or "our").
              </p>
              <p>
                Your access to and use of the Services is conditioned on your
                acceptance of and compliance with these Terms. These Terms apply
                to all visitors, users, and others who access or use the
                Services.
              </p>
              <p>
                By accessing or using the Services, you agree to be bound by
                these Terms. If you disagree with any part of the terms, then
                you may not access the Services.
              </p>

              <motion.h2 className="title is-4" variants={itemVariants}>
                1. Use of Services
              </motion.h2>
              <p>
                Our Services include but are not limited to: CSP
                implementations, Digital Signature Workflow, mobile application
                development, website development, HRMS, HIMS, and other software
                solutions.
              </p>
              <p>
                You agree to use our Services only for lawful purposes and in
                accordance with these Terms. You agree not to use the Services:
              </p>
              <ul>
                <li>
                  In any way that violates any applicable federal, state, local,
                  or international law or regulation.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or
                  promotional material, including any "junk mail", "chain
                  letter," "spam," or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate the Company, a
                  Company employee, another user, or any other person or entity.
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits
                  anyone's use or enjoyment of the Services, or which, as
                  determined by us, may harm the Company or users of the
                  Services or expose them to liability.
                </li>
              </ul>

              <motion.h2 className="title is-4" variants={itemVariants}>
                2. Intellectual Property
              </motion.h2>
              <p>
                The Services and their entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof) are owned by{" "}
                {companyName}, its licensors, or other providers of such
                material and are protected by copyright, trademark, patent,
                trade secret, and other intellectual property or proprietary
                rights laws.
              </p>
              <p>
                These Terms permit you to use the Services for your personal,
                non-commercial use only, or for legitimate business purposes if
                you are using our Services on behalf of a business entity that
                has entered into a separate agreement with us.
              </p>

              <motion.h2 className="title is-4" variants={itemVariants}>
                3. User Accounts
              </motion.h2>
              <p>
                When you create an account with us, you must provide information
                that is accurate, complete, and current at all times. Failure to
                do so constitutes a breach of the Terms, which may result in
                immediate termination of your account on our Services.
              </p>
              <p>
                You are responsible for safeguarding the password that you use
                to access the Services and for any activities or actions under
                your password, whether your password is with our Services or a
                third-party service.
              </p>
              <p>
                You agree not to disclose your password to any third party. You
                must notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>

              <motion.h2 className="title is-4" variants={itemVariants}>
                4. Limitation of Liability
              </motion.h2>
              <p>
                In no event shall {companyName}, nor its directors, employees,
                partners, agents, suppliers, or affiliates, be liable for any
                indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from:
              </p>
              <ol type="a">
                <li>
                  Your access to or use of or inability to access or use the
                  Services;
                </li>
                <li>
                  Any conduct or content of any third party on the Services;
                </li>
                <li>Any content obtained from the Services; and</li>
                <li>
                  Unauthorized access, use or alteration of your transmissions
                  or content, whether based on warranty, contract, tort
                  (including negligence) or any other legal theory, whether or
                  not we have been informed of the possibility of such damage.
                </li>
              </ol>

              <motion.h2 className="title is-4" variants={itemVariants}>
                5. Disclaimer
              </motion.h2>
              <p>
                Your use of the Services is at your sole risk. The Services are
                provided on an "AS IS" and "AS AVAILABLE" basis. The Services
                are provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>
              <p>
                {companyName}, its subsidiaries, affiliates, and its licensors
                do not warrant that:
              </p>
              <ol type="a">
                <li>
                  The Services will function uninterrupted, secure or available
                  at any particular time or location;
                </li>
                <li>Any errors or defects will be corrected;</li>
                <li>
                  The Services are free of viruses or other harmful components;
                  or
                </li>
                <li>
                  The results of using the Services will meet your requirements.
                </li>
              </ol>

              <motion.h2 className="title is-4" variants={itemVariants}>
                6. Governing Law
              </motion.h2>
              <p>
                These Terms shall be governed and construed in accordance with
                the laws applicable in the jurisdiction where {companyName} is
                headquartered, without regard to its conflict of law provisions.
              </p>
              <p>
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect.
              </p>

              <motion.h2 className="title is-4" variants={itemVariants}>
                7. Changes to Terms
              </motion.h2>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material, we
                will try to provide at least 30 days' notice prior to any new
                terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>
              <p>
                By continuing to access or use our Services after those
                revisions become effective, you agree to be bound by the revised
                terms. If you do not agree to the new terms, please stop using
                the Services.
              </p>

              <motion.h2 className="title is-4" variants={itemVariants}>
                8. Contact Us
              </motion.h2>
              <p>
                If you have any questions about these Terms, please contact us
                at:
              </p>
              <p>
                {companyName}
                <br />
                Email: {companyEmail}
                <br />
                Website: {companyWebsite}
              </p>
            </motion.div>
          </div>
        </section>

        {/* <motion.div
        className="back-to-top"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        variants={itemVariants}
      >
        <button className="button is-primary is-rounded">
          <span className="icon">
            <i className="fas fa-arrow-up"></i>
          </span>
          <span>Back to Top</span>
        </button>
      </motion.div> */}
        <ScrollToTop />
      </motion.div>
    </>
  );
};

export default TermsAndConditions;
