import { motion } from "framer-motion";



interface headerProps{
    LandingPage?: boolean ;
    data : any[];
}
const ProductSectionItems:React.FC<headerProps> = ({data}) => {
const SectionItem = (data_in:any) => {
    return (
      <motion.div 
        className={`columns is-vcentered projects-list-item ${data_in.reverse ? "is-flex-direction-row-reverse" : ""}`}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
      >
        <div className="column is-half projects-list-image-container">
          <motion.img 
            src={data_in?.image} 
            alt={data_in?.title} 
            className="projects-list-image"
            initial={{ opacity: 0, x:data_in?.reverse ? 50 : -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="column is-half projects-list-content-container">
          <motion.div 
            className="projects-list-content"
            initial={{ opacity: 0, x: data_in?.reverse ? -50 : 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5 }}
          >
            <motion.h2  
                    className="title projects-list-title mb-5"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.5 }}>{data_in?.title}</motion.h2>
                     <motion.h2  
                    className="subtitle projects-list-subtitle"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.7 }}>{data_in?.description}</motion.h2>

        
            <ul className="projects-list-list">
              {data_in&& data_in.list.map((listItem:any, index:any) => (
                <motion.li
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.9 }}
                 key={index} className="projects-list-list-item">
                 
                 {listItem}
                  
                  </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </motion.div>
    );
  };
 
    return (
      <section className="section projects-list-container">
        <div className="container">
          {data&& data.map((item:any, index:any) => (
            <SectionItem key={index} {...item} />
          ))}
        </div>
      </section>
    );
  };
  
  export default ProductSectionItems;
  
