import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { post } from "../../services/smartApiService";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define types
interface ChartData {
  month: string;
  sd_url: string;
  top_score: number;
}

interface LineChartProps {
  sd_url?: string;
}

const ClientLineChart: React.FC<LineChartProps> = ({ sd_url }) => {
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [startYear, setStartYear] = useState(new Date().getFullYear());

  // Function to get all months from Jan to Dec
  const getAllMonths = (): string[] => {
    const year = startYear.toString();
    return Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, "0")}`);
  };
  
  const loadTableData = async () => {
    
      let URL = "/csp/get_year_csp_scores";
      let data = { year: startYear, sd_url: sd_url || "" };
  
      const subscription = post(URL, data).subscribe((response) => {
        const fetchedData = Array.isArray(response.data.data) ? response.data.data : [];
        const fullData = getAllMonths().map((month) => {
          const existingData = fetchedData.find((item: any) => item.month === month);
          return {
            month,
            sd_url: existingData ? existingData.sd_url : sd_url || "N/A",
            top_score: existingData ? existingData.top_score : 0, 
          };
        });
  
        setChartData(fullData);
      });
  
      return () => subscription.unsubscribe();
    
  };
  
  
  useEffect(() => {
    loadTableData();
  }, [startYear]);

  // Prepare chart labels (months) and data (scores)
  const labels = chartData.map((item) => item.month);
  const scores = chartData.map((item) => item.top_score);
  console.log("top_score",scores)

  // Change Year
  const handleChangeYear = (changeNo: number) => {
    setStartYear((prevYear) => prevYear + changeNo);
  };

  return (
    <div>
        <div className=" is-flex is-justify-content-space-between">
        <h2 className=" ">
        CSP Scores for {startYear}
      </h2>

      
      {/* <div className="date-navigation flex-between mb-4">
        <button onClick={() => handleChangeYear(-1)}>⬅️ Previous Year</button>
        <span className="mx-2 text-lg font-semibold">{startYear}</span>
        <button onClick={() => handleChangeYear(1)}>Next Year ➡️</button>
      </div> */}
        </div>
    

      {/* Line Chart */}
      <Line
        data={{
          labels,
          datasets: [
            {
              label: "Top CSP Score",
              data: scores,
              borderColor: "rgba(75,192,192,1)",
              backgroundColor: "rgba(75,192,192,0.2)",
              pointBorderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 2,
              tension: 0.3,
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: { display: true, position: "top" },
            tooltip: { enabled: true },
          },
          scales: {
            x: { title: { display: true, text: "Months" } },
            y: {
              title: { display: true, text: "CSP Score" },
              min: 0,
              max: 100,
            },
          },
        }}
      />
    </div>
  );
};

export default ClientLineChart;
