import React, { useEffect, useState } from "react";
import { SdsForm, SmartSoftButton } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSiteContext } from "../../contexts/SiteProvider";
import { post } from "../../services/smartApiService";
import { HOME_IMAGES_URLS } from "../../api/SiteSettingsUrl";



const validationSchema = Yup.object({
});

interface HeaderProps {
  loadTableData: () => void;
  data: any;
}

const HomeImageForm: React.FC<HeaderProps> = ({ loadTableData, data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: data,
  });

  const { closeModal } = useSiteContext();



  const onSubmit = (formData: any) => {
    let url = HOME_IMAGES_URLS.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = HOME_IMAGES_URLS.UPDATE;
    }
    const postData = { ...formData};
    const subscription = post(url, postData).subscribe(() => {
      closeModal();
      loadTableData();
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const elements: SdsFormElementProps[] = [
    
    {
        type: "TEXT_BOX",
        width: 6,
        name: "image_title",
        element: {
          label: "Title",
          placeHolder: "Enter Title",
          isRequired: true,
        },
      },
    
      {
        type: "FILE",
        width: 6,
        name: "image_file",
        element: {
          label: "Upload Image",
          placeHolder: "(Image Only)",
          accept: "*",
        },
      },
    {
      type: "BUTTON",
      width: 12,
      name: "submit",
      class_name: "has-text-right",
      element: {
        label: data.ID ? "Update" : "Add Image",
        onClick: handleSubmit(onSubmit),
        classList: ["smart-action-button"],
      },
    },
  ];

  return (
    <div className="sd-hrms-input">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};
export default HomeImageForm