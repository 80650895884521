import { motion } from 'framer-motion';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import SmartHeader from '../../../components/SmartHeader';

const AboutUs: React.FC = () => {
  const  navigate=useNavigate()
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <>
    <SmartHeader title='About Us' subTitle='Building Great Futures Together Since 2010'/>
    <div className="about-us-page">
      {/* Hero Section */}
      {/* <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <motion.h1 
              className="title is-1"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
             
            </motion.h1>
            <motion.h2 
              className="subtitle"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7, delay: 0.3 }}
            >
              Building Great Futures Together Since 2010
            </motion.h2>
          </div>
        </div>
      </section> */}

      {/* Main Content */}
      <section className="section">
        <div className="container">
          <motion.div 
            className="columns"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {/* Our Story */}
            <motion.div className="column is-6" variants={itemVariants}>
              <div className="content-box">
                <h3 className="title is-3">Our Story</h3>
                <p>
                  Founded in 2010, our software development company has grown from a small team of passionate developers to a full-service technology partner for businesses worldwide. We've built our reputation on three core values: Honesty, Unity, and Innovation.
                </p>
                <p>
                  Through years of dedication and expertise, we've developed pioneering solutions including CSP implementations, Digital Signature Workflow, mobile applications, web development, HRMS, and HIMS. Our award-winning team brings both technical expertise and creative problem-solving to every project.
                </p>
              </div>
            </motion.div>

            {/* Our Mission */}
            <motion.div className="column is-6" variants={itemVariants}>
              <div className="content-box">
                <h3 className="title is-3">Our Mission</h3>
                <p>
                  We're committed to empowering businesses through innovative technology solutions that drive growth, efficiency, and competitive advantage. We believe in creating software that not only meets current needs but is adaptable enough to evolve with your business.
                </p>
                <p>
                  Our mission is to transform complex business challenges into elegant, user-friendly digital solutions that make a real difference in how organizations operate and serve their customers.
                </p>
              </div>
            </motion.div>
          </motion.div>

          {/* Core Values */}
          <motion.div 
            className="columns mt-6"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            viewport={{ once: true }}
          >
            <div className="column is-12">
              <h3 className="title is-3 has-text-centered mb-6">Our Core Values</h3>
            </div>
          </motion.div>

          <motion.div 
            className="columns is-multiline"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            viewport={{ once: true }}
          >
            {/* Honesty */}
            <motion.div className="column is-4" variants={itemVariants}>
              <div className="value-card">
                <div className="value-number">01</div>
                <h4 className="title is-4">Honesty</h4>
                <p>
                  We build trust through transparent communication and ethical business practices. Our track record of award-winning solutions demonstrates our commitment to delivering exactly what we promise—customized software that solves your unique challenges.
                </p>
              </div>
            </motion.div>

            {/* Unity */}
            <motion.div className="column is-4" variants={itemVariants}>
              <div className="value-card">
                <div className="value-number">02</div>
                <h4 className="title is-4">Unity</h4>
                <p>
                  We believe in collaborative partnerships that drive success. By combining our technical expertise with your vision, we create integrated solutions that unite your teams, streamline workflows, and connect systems across your organization.
                </p>
              </div>
            </motion.div>

            {/* Innovation */}
            <motion.div className="column is-4" variants={itemVariants}>
              <div className="value-card">
                <div className="value-number">03</div>
                <h4 className="title is-4">Innovation</h4>
                <p>
                  We constantly push technological boundaries to develop pioneering solutions. From CSP implementations to digital signature workflows, our innovative approach ensures you stay ahead of industry trends with future-proof technology tailored to your evolving needs.
                </p>
              </div>
            </motion.div>
          </motion.div>

          {/* Compliance & Certifications Section */}
          <motion.div 
            className="columns mt-6"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <div className="column is-12">
              <h3 className="title is-3 has-text-centered mb-6">Compliance & Certifications</h3>
            </div>
          </motion.div>

          <motion.div 
            className="columns is-multiline"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {/* GDPR Compliance */}
            <motion.div className="column is-6" variants={itemVariants}>
              <div className="compliance-card">
                <div className="compliance-icon">
                  <i className="fas fa-shield-alt"></i>
                </div>
                <h4 className="title is-4">GDPR Compliance</h4>
                <p>
                  We are fully committed to GDPR compliance across all our operations and software solutions. Our development practices incorporate privacy by design principles, ensuring that your data and your customers' data are always protected according to the highest standards. We implement robust data protection measures including encryption, access controls, and regular security audits.
                </p>
                <p>
                  Our software solutions are designed with customizable privacy settings that help your business maintain GDPR compliance while delivering exceptional user experiences.
                </p>
              </div>
            </motion.div>

            {/* Certifications */}
            <motion.div className="column is-6" variants={itemVariants}>
              <div className="compliance-card">
                <div className="compliance-icon">
                  <i className="fas fa-certificate"></i>
                </div>
                <h4 className="title is-4">Industry Certifications</h4>
                <p>
                  Our commitment to excellence is validated through internationally recognized certifications:
                </p>
                <ul>
                  <li><strong>ISO 27001</strong> - Information Security Management</li>
                  {/* <li><strong>ISO 9001</strong> - Quality Management Systems</li>
                  <li><strong>CMMI Level 5</strong> - Capability Maturity Model Integration</li>
                  <li><strong>PCI DSS</strong> - Payment Card Industry Data Security Standard</li>
                  <li><strong>SOC 2 Type II</strong> - Service Organization Control</li> */}
                </ul>
                <p>
                  These certifications demonstrate our dedication to maintaining the highest standards of security, quality, and operational excellence in everything we deliver.
                </p>
              </div>
            </motion.div>
          </motion.div>

          {/* Team Section */}
          {/* <motion.div 
            className="columns mt-6"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <div className="column is-12">
              <h3 className="title is-3 has-text-centered mb-6">Our Leadership Team</h3>
              <div className="columns is-multiline">
               
                <motion.div className="column is-3" variants={itemVariants}>
                  <div className="card team-card">
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src="/api/placeholder/300/225" alt="Team member" />
                      </figure>
                    </div>
                    <div className="card-content">
                      <p className="title is-5">John Doe</p>
                      <p className="subtitle is-6">CEO & Founder</p>
                    </div>
                  </div>
                </motion.div>
                
                <motion.div className="column is-3" variants={itemVariants}>
                  <div className="card team-card">
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src="/api/placeholder/300/225" alt="Team member" />
                      </figure>
                    </div>
                    <div className="card-content">
                      <p className="title is-5">Jane Smith</p>
                      <p className="subtitle is-6">CTO</p>
                    </div>
                  </div>
                </motion.div>
                
                <motion.div className="column is-3" variants={itemVariants}>
                  <div className="card team-card">
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src="/api/placeholder/300/225" alt="Team member" />
                      </figure>
                    </div>
                    <div className="card-content">
                      <p className="title is-5">Mark Johnson</p>
                      <p className="subtitle is-6">Head of Development</p>
                    </div>
                  </div>
                </motion.div>
                
                <motion.div className="column is-3" variants={itemVariants}>
                  <div className="card team-card">
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src="/api/placeholder/300/225" alt="Team member" />
                      </figure>
                    </div>
                    <div className="card-content">
                      <p className="title is-5">Sarah Williams</p>
                      <p className="subtitle is-6">Head of Client Services</p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div> */}

          {/* Contact CTA */}
          <motion.div 
            className="columns mt-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <div className="column is-12">
              <div className="contact-cta has-text-centered">
                <h3 className="title is-3">Ready to Build Your Future With Us?</h3>
                <p className="subtitle">Let's discuss how our solutions can help your business grow.</p>
                <motion.button 
                  className="button is-primary is-large"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={()=> navigate("/contact-us")}

                >
                  Contact Us Today
                </motion.button>
                
                {/* Terms and Privacy Policy Links */}
                <div className="legal-links mt-6">
                  <p>
                    By working with us, you agree to our <Link to="/terms-and-conditions" className="has-text-primary">Terms and Conditions</Link> and <Link to="/privacy-policy" className="has-text-primary">Privacy Policy</Link>.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
    </>
  );
};

export default AboutUs;