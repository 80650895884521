import { ADMIN_MENU_SETTINGS_URLS, ROLE_URLS, SITE_CIRCULAR, USER_URLS } from "../../api/AdminUrls";
import { get, post } from "../smartApiService";

/**
 *
 * @param url s
 * @param call_back
 * @returns
 */
const admin_generic_select = (url: string, call_back: any) => {
  const handleError = (errorMessage: any) => {};
  const subscription = get(url).subscribe((response) => {
    call_back(response.data);
  });
  return () => {
    subscription.unsubscribe();
  };
};
const admin_generic_select_post = (url: string, _data: any, call_back: any) => {
  const handleError = (errorMessage: any) => {};
  const subscription = post(url, _data, { handleError: handleError }).subscribe(
    (response) => {
      call_back(response.data);
    }
  );
  return () => {
    subscription.unsubscribe();
  };
};
/**
 *
 * @param call_back
 * @returns
 */

const user_get_select = (call_back: any) => {
  let url = USER_URLS.GET_ALL_SELECT;
  admin_generic_select(url, call_back);
};
const role_get_select = (call_back: any) => {
  let url = ROLE_URLS.GET_ALL_SELECT;
  admin_generic_select(url, call_back);
};

const role_get_select_desc = (call_back: any) => {
  let url = ROLE_URLS.GET_ALL_SELECT_DESC;
  admin_generic_select(url, call_back);
};
const circular_get_select = (call_back: any) => {
  let url = SITE_CIRCULAR.GET_ALL_SELECT;
  admin_generic_select(url, call_back);
};
const menu_Parent_get_select = (call_back: any, payload: any = {}) => {
  let url = ADMIN_MENU_SETTINGS_URLS.GET_ALL_SELECT;
  admin_generic_select_post(url, payload, call_back);
};

export {
  circular_get_select, menu_Parent_get_select, role_get_select, role_get_select_desc, user_get_select
};

