import { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { showAlertAutoClose } from "../../services/notifyService";
import { get, post } from "../../services/smartApiService";

import { EMAIL_SETTINGS_URLS } from "../../api/AdminUrls";
import { useNavigate } from "react-router-dom";
import EmailDetails from "./EmailDetails";
import { useSiteContext } from "../../contexts/SiteProvider";

const EmailTemplate = () => {
  const [tabData, setTabData] = useState([]);
  const navigate = useNavigate();
const { openModal, closeModal ,setTitle} = useSiteContext();
  const loadTableData = () => {
    const URL = EMAIL_SETTINGS_URLS.GET_ALL;
    const subscription = get(URL).subscribe((response) => {
      setTabData(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    setTitle ("Email Templates");
  }, []);

  useEffect(() => {
    loadTableData();
  }, []);

  const deleteData = (id: any) => {
    const subscription = post(EMAIL_SETTINGS_URLS.DELETE, { ID: id }).subscribe(
      (response) => {
        showAlertAutoClose("Deleted Successfully...", "success");
        loadTableData();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openDeleteModal = (id: any) => {
    const alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Email Settings Deletion!
        </span>
      ),
      alertFunction: (option) => {
        if (option === "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to delete this Email Settings? This action cannot be
          reverted.
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };
  const openOfficesForm = (data: any) => {
    let options = {
      title: <>{data?.mail_name}</>,
      content: <EmailDetails  tableData={data} />,
      className: "sd-efl-modal",
      closeBody: false,
      width: 80,
    };
    openModal(options);
  };

  const openViewDetails = (id: any) => {
    const subscription = post(EMAIL_SETTINGS_URLS.GET_ONE, { ID: id }).subscribe(
      (response: any) => {
        openOfficesForm(response.data.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const buttons = [
      {
      label: "",
      type: "icon",
      leftIcon: "fa fa-eye",
      classList: ["smart-hims-table-view-icon"],
      onClick: (data: any) => {
        openViewDetails(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-pencil-square",
      classList: ["smart-hims-table-edit-icon"],
      onClick: (data: any) => {
        navigate(`/admin/email-from/${data["ID"]}`);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["smart-hims-table-delete-icon"],
      onClick: (data: any) => {
        openDeleteModal(data["ID"]);
      },
    },
  ];

  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width: "5" },
    { title: "Name", index: "mail_name" },
    { title: "Subject", index: "mail_subject" },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];

  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-6",
      custom: <p className="is-size-4 has-text-weight-bold"></p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-4",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-2",
      align: "CENTER",
      buttons: [
        {
          type: "REFRESH",
          action: loadTableData,
        },
        {
          label: "Add",
          icon: "fa-plus",
          type: "CUSTOM",
          className: "",
          action: () => navigate("/admin/email-from/new"),
        },
      ],
    },
  ];

  return (
    <>
      <div className="sd-efl-input">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          tableProps={{
            className: "is-hoverable is-bordered smart-kgr-table",
            isResponsive: true,
            searchPlaceHolder: "Search",
          }}
          paginationProps={{
            pageSize: 10,
          }}
        />
      </div>
    </>
  );
};

export default EmailTemplate;
