import { BrowserRouter as Router, Routes } from "react-router-dom";
import { SmartModal } from "soft_digi";
import { useSiteContext } from "../contexts/SiteProvider";
import ClientRoutes from "./ClientRoutes";
import DashRoutes from "./DashRoutes";
import HomeRoutes from "./HomeRoutes";
import LoginRoutes from "./LoginRoutes";

const SiteRoute = () => {
  const { isModalOpen, modalOptions, closeModal } = useSiteContext();

  return (
    <>
      <Router>
        <Routes>
          {HomeRoutes()}
          {LoginRoutes()}
          {DashRoutes()}
          {ClientRoutes()}
        </Routes>
        {isModalOpen && (
          <SmartModal
            active={isModalOpen}
            {...modalOptions}
            closeFunction={closeModal}
          />
        )}
      </Router>
    </>
  );
};

export default SiteRoute;
