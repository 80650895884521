import React, { useEffect, useState } from "react";
import { SdsForm } from "soft_digi";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { EMAIL_SETTINGS_URLS } from "../../api/AdminUrls";
import { useParams, useNavigate } from "react-router-dom";

const schema = Yup.object({
  mail_name: Yup.string()
    .required("Mail Name is required")
    .min(3, "Mail Name must be at least 3 characters long")
    .max(50, "Mail Name must not exceed 50 characters"),
  mail_subject: Yup.string()
    .required("Mail Subject is required")
    .min(3, "Mail Subject must be at least 3 characters long")
    .max(100, "Mail Subject must not exceed 100 characters"),
  mail_msg: Yup.string()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters long")
    .max(2000, "Message must not exceed 1000 characters"),
});

const EmailSettingsForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mail_name: "",
      mail_subject: "",
      mail_msg: "",
    },
  });

  const { closeModal } = useSiteContext();
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();

  useEffect(() => {
    if (id) {
      const subscription = post(EMAIL_SETTINGS_URLS.GET_ONE, { ID: id }).subscribe((response: any) => {
        const data = response.data.data;
        reset({
          mail_name: data.mail_name,
          mail_subject: data.mail_subject,
          mail_msg: data.mail_msg,
        });
      });

      return () => subscription.unsubscribe();
    }
  }, [id, reset]);

  const onSubmit = (formData: any) => {
    let url = EMAIL_SETTINGS_URLS.INSERT;

    if (id) {
      formData["ID"] = id;
      url = EMAIL_SETTINGS_URLS.UPDATE;
    }

    const subscription = post(url, formData).subscribe((response) => {
      showAlertAutoClose("Data Saved Successfully", "success");
      navigate("/admin/email");
    });

    return () => subscription.unsubscribe();
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "mail_name",
      element: { label: "Mail Name", isRequired: true },
    },
    {
      type: "TEXT_BOX",
      width: 12,
      name: "mail_subject",
      element: { label: "Mail Subject", isRequired: true },
    },
    {
      type: "TEXTAREA",
      width: 12,
      name: "mail_msg",
      class_name:"smart-text-area",
      element: { label: "Message", isRequired: true, rows: 30, richEditor: true },
    },
    {
      type: "BUTTON",
      width: 12,
      name: "submitbutton",
      class_name: "has-text-right",
      element: {
        label: "Submit",
        onClick: handleSubmit(onSubmit),
        classList: ["smart-action-button"],
      },
    },
  ];

  return (
    <div className="sd-hims-input p-6">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};

export default EmailSettingsForm;
