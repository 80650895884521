import React, { useEffect, useState } from 'react'
import { useSiteContext } from '../../contexts/SiteProvider';
import { post } from '../../services/smartApiService';
import CSPTable from '../Help/Projects/CSPTable';
import CspDirectiveDisplay from './CspDirectiveDisplay';

interface headerProps {
  id: string;
}
const CspinfoDetails:React.FC<headerProps> = ({id}) => {

    const [tabData, setTabData] = useState<any>([]);
    const [dangerDirectives, setDangerDirectives] = useState([]);

    const { openModal, closeModal, setTitle, user } = useSiteContext();
  
    const loadTableData = () => {
      let URL = "/csp/get_one";
  
      const subscription = post(URL, { id: id }).subscribe((response) => {
        setTabData(response.data.data);
        const filteredDanger = response.data.data.directives?.filter((item:any) => item.status === "danger") || [];
        setDangerDirectives(filteredDanger);
        
      });
      return () => {
        subscription.unsubscribe();
      };
    };
    console.log("loadTableData", tabData);
  
    useEffect(() => {
      loadTableData();
    }, [id]);
  return (
   <div >
      {tabData && tabData?.directives?.length > 0  && <CspDirectiveDisplay directives={dangerDirectives || []} />}
   </div>
  )
}

export default CspinfoDetails