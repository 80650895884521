import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEarthEurope } from "react-icons/fa6";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { setLocalStorage } from "../../../services/sessionService";
import { useSiteContext } from "../../../contexts/SiteProvider";
import CookieConsentModal from "../../../components/CookieConsentComponent";

const validationSchema = Yup.object({
  email_id: Yup.string().required("Email is required"),
});

interface cspEmailProps {
  submit: (data: any) => void;
  dataIn: any;
}

const CspEmailForm: React.FC<cspEmailProps> = ({ submit, dataIn }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: data,
  });
  const { openModal, closeModal, setIsVisible } = useSiteContext();

  const handleEmailUpdate = (data: any) => {
    // console.log("data ", data);
    setIsVisible(true);
    setLocalStorage("userEmail", data.email_id);
    submit(dataIn);
    closeModal();
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "email_id",
      class_name: "mt-2",
      element: {
        // size: "large",
        // label: "Title",
        placeHolder: "Enter Your Email Address ",
        isRequired: true,
        leftIcon: (
          <span className="icon is-small is-left ">
            <FaEarthEurope />
          </span>
        ),
        // leftIcon:"fa fa-pie-chart"
      },
    },

    {
      type: "BUTTON",
      width: 12,
      name: "submit_button",
      class_name: "is-justify-content-center is-flex",
      element: {
        classList: ["button", "smart-action-button has-text-right"],
        label: "Submit",
        onClick: handleSubmit(handleEmailUpdate),
      },
    },
  ];
  return (
    <div>
      <p>Enter your email to get your CSP analysis report instantly!</p>
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};

export default CspEmailForm;
