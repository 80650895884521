import "bulma/css/bulma.min.css"; // Import Bulma CSS
import "bulma-checkradio/dist/css/bulma-checkradio.min.css";
import "bulma-switch/dist/css/bulma-switch.min.css";
import "./assets/scss/App.scss";

import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import { SiteProvider } from "./contexts/SiteProvider";
import SiteRoute from "./routing/SiteRoute";
import { Provider } from "react-redux";
import { store } from "./services/reduxServices/store";

import ColorfulCursor from "./pages/FluidCursor";
import RainbowCursor from "./pages/FluidCursor";
import AnimatedCursor from "react-animated-cursor";


function App() {
  useEffect(() => {
    window.loaderType = "STAR";
  }, []); 

  return (
    <Provider store={store}>
    <SiteProvider>
    {/* <AnimatedCursor
        innerSize={8}
        outerSize={35}
        color="255, 0, 0"
        outerAlpha={0.3}
        innerScale={1}
        outerScale={2}
        clickables={["a", "button", ".custom-hover"]}
      /> */}
      {/* <ColorfulCursor /> */}
      {/* <RainbowCursor /> */}
      <div className="App">
        <SiteRoute />
        
      </div>
    </SiteProvider>
    </Provider>
  );
}

export default App;
