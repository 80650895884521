import { motion } from "framer-motion";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  FaChartPie,
  FaCog,
  FaExclamationTriangle,
  FaInfoCircle,
  FaPlus,
  FaSync,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SdsDtGetTimeAgo } from "soft_digi/dist/services/DateService";
import ContentSecurityPolicy from "../Help/Projects/ContentSecurityPolicy";
import { useSiteContext } from "../../contexts/SiteProvider";
import CspinfoDetails from "./CspinfoDetails";
import CspSettingsForm from "./CspSettingsForm";
import { post } from "../../services/smartApiService";
import CspUrlAdditionForm from "./CspUrlAdditionForm";

type CSPSite = {
  id: number;
  name: string;
  score: number; // Score percentage (0-100)
  lastChecked: string; // "10 min ago", "1 day ago", etc.
  recommendations: string[]; // List of improvement recommendations
};

type DashboardProps = {
  sites: any[];
  onAddSite: () => void;
};

const CspDashboardCards: React.FC<DashboardProps> = ({ sites, onAddSite }) => {
  const [selectedSite, setSelectedSite] = useState<CSPSite | null>(null);
  const { openModal } = useSiteContext();
  const navigate = useNavigate();
  const navigateAnalytics = (id: any) => {
    navigate(`/client/csp-analytics/${id}`);
  };

  const openForm = () => {
    // console.log("open",data)
    let options = {
      title: "Add New Site",
      content: <CspUrlAdditionForm onAddSite={onAddSite} />,
      width: 90,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };
  const openInfoForm = (data: any) => {
    // console.log("open",data)
    let options = {
      title: "Check Site",
      content: <CspinfoDetails id={data} />,
      width: 90,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };
  const openSettingsForm = (data: any) => {
    // console.log("open",data)
    let options = {
      title: <CspSettingsForm dataIn={data} />,
      content: <></>,
      width: 60,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };
  const post_insert_data = (dataIn: any, email_id: any) => {
    //console.log("storedEmail", storedEmail);
    let url = "/csp/insert";
    let data = {
      sd_url: dataIn,
      email_id: email_id,
    };
    const subscription = post(url, data, {
      loadingMsg: "Refreshing Please Wait",
    }).subscribe((response) => {
      onAddSite();
    });
    return () => subscription.unsubscribe();
  };

  return (
    <div className="container">
      {/* Dashboard Header */}
      <div className="is-flex is-justify-content-end">
        {/* <h2 className="title is-4">CSP Monitoring Dashboard</h2> */}
        {sites.length !== 0 && (
          <button className="button is-primary mr-6" onClick={openForm}>
            <FaPlus /> &nbsp; Add New Site
          </button>
        )}
      </div>

      {/* No Sites Message */}
      {sites.length === 0 ? (
        <div className="notification is-light has-text-centered">
          <p>No CSP sites added yet.</p>
          <button className="button is-primary mt-2" onClick={openForm}>
            Start CSP Monitoring
          </button>
        </div>
      ) : (
        <div className="columns is-multiline">
          {sites.map((site, index) => {
            const cleanUrl = site?.sd_url
              .replace(/(^\w+:|^)\/\//, "")
              .replace(/\/$/, "");
            return (
              <motion.div
                key={site.id}
                className="column is-one-third"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <div
                  className={`box has-text-centered ${
                    index % 2 === 0
                      ? "has-background-light"
                      : "has-background-white"
                  }`}
                  style={{
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {/* Card Title & Info Icon */}
                  <div className="is-flex is-justify-content-space-between">
                    <h3 className="title is-5">{cleanUrl}</h3>
                    <FaInfoCircle
                      className="icon has-text-info"
                      style={{ cursor: "pointer" }}
                      onClick={() => openInfoForm(site?.ID)}
                    />
                  </div>

                  <p className="is-size-7 has-text-grey">
                    Last checked: {SdsDtGetTimeAgo(site?.last_modified_time)}
                  </p>

                  {/* Score Progress Bar */}
                  <div style={{ width: 80, margin: "10px auto" }}>
                    <CircularProgressbar
                      value={site?.overall_score}
                      text={`${site?.overall_score}%`}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor:
                          site.overall_score > 60
                            ? "#4CAF50"
                            : site.score > 40
                            ? "#FFC107"
                            : "#F44336",
                        textColor: "#333",
                        trailColor: "#eee",
                      })}
                    />
                  </div>

                  {/* Warning Message if Score < 50% */}
                  {site.score < 50 && (
                    <div className="notification is-danger is-light mt-3">
                      <FaExclamationTriangle className="mr-1" /> Score is low!
                      Take action to improve security.
                    </div>
                  )}

                  {/* Footer Actions */}
                  <div className="is-flex is-justify-content-space-around mt-3">
                    <div
                      className="is-flex is-align-items-center is-clickable"
                      onClick={() =>
                        post_insert_data(site?.sd_url, site?.email_id)
                      }
                    >
                      <FaSync
                        className="icon has-text-info"
                        style={{ fontSize: "18px" }}
                      />
                      <span className="is-size-7 ml-1">Refresh</span>
                    </div>
                    <div
                      className="is-flex is-align-items-center is-clickable"
                      onClick={() => openSettingsForm(site.ID)}
                    >
                      <FaCog
                        className="icon has-text-primary"
                        style={{ fontSize: "18px" }}
                      />
                      <span className="is-size-7 ml-1">Settings</span>
                    </div>
                    <div
                      className="is-flex is-align-items-center is-clickable"
                      onClick={() => navigateAnalytics(site.ID)}
                    >
                      <FaChartPie
                        className="icon has-text-success"
                        style={{ fontSize: "18px" }}
                      />
                      <span className="is-size-7 ml-1">Analytics</span>
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      )}

      {/* Recommendations Modal */}
      {selectedSite && (
        <div className="modal is-active">
          <div
            className="modal-background"
            onClick={() => setSelectedSite(null)}
          ></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Recommendations for {selectedSite.name}
              </p>
              <button
                className="delete"
                onClick={() => setSelectedSite(null)}
              ></button>
            </header>
            <section className="modal-card-body">
              <ul>
                {selectedSite.recommendations.length > 0 ? (
                  selectedSite.recommendations.map((rec, idx) => (
                    <li key={idx} className="mb-2">
                      ✅ {rec}
                    </li>
                  ))
                ) : (
                  <p>No specific recommendations available.</p>
                )}
              </ul>
              <hr />
              <p className="has-text-weight-semibold">
                Need help? <a href="/contact">Contact us</a> for the best CSP
                solutions!
              </p>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-primary"
                onClick={() => setSelectedSite(null)}
              >
                Close
              </button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
};

export default CspDashboardCards;
