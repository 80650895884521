import React, { useState } from "react";
import { motion } from "framer-motion";
import "./help.scss";
import { FaChartLine, FaCloud, FaCube, FaDatabase, FaGlobe, FaLaptopCode, FaLock, FaMobileAlt, FaPalette, FaRobot, FaShoppingCart, FaWifi } from "react-icons/fa";
import SmartHeader from "../../components/SmartHeader";

const services = [
    {
      title: "Database Security",
      description:
        "Protecting critical business data with advanced encryption and security measures.",
      icon: <FaDatabase />,
    },
    {
      title: "IT Consultancy",
      description:
        "Expert advice to optimize your IT infrastructure and drive business growth.",
      icon: <FaLaptopCode />,
    },
    {
      title: "Cyber Security",
      description:
        "Ensuring digital safety with top-tier security solutions for businesses.",
      icon: <FaLock />,
    },
    {
      title: "App Development",
      description:
        "Building scalable and high-performance applications tailored to your needs.",
      icon: <FaMobileAlt />,
    },
    {
      title: "Cloud Computing",
      description:
        "Harness the power of the cloud with scalable and secure cloud solutions.",
      icon: <FaCloud />,
    },
    {
      title: "AI & Machine Learning",
      description:
        "Leverage AI to automate tasks and gain data-driven insights for business growth.",
      icon: <FaRobot />,
    },
    {
      title: "Web Development",
      description:
        "Creating responsive and modern websites optimized for performance and user experience.",
      icon: <FaGlobe />,
    },
    {
      title: "Blockchain Solutions",
      description:
        "Implementing secure and decentralized blockchain solutions for businesses.",
      icon: <FaCube />,
    },
    {
      title: "UI/UX Design",
      description:
        "Crafting intuitive and visually appealing user interfaces for applications.",
      icon: <FaPalette />,
    },
    {
      title: "Big Data Analytics",
      description:
        "Transforming raw data into valuable business insights with advanced analytics.",
      icon: <FaChartLine />,
    },
    {
      title: "E-Commerce Solutions",
      description:
        "Developing scalable and feature-rich e-commerce platforms to grow online sales.",
      icon: <FaShoppingCart />,
    },
    {
      title: "IoT Solutions",
      description:
        "Connecting smart devices to drive automation and efficiency in industries.",
      icon: <FaWifi />,
    },
  ];
  

const ServicesSection: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <>
      <SmartHeader title="Our Services"
    subTitle="We Provide The Best Quality"
    />
 
    <div className="team-service-page-section">

  
    <section className="sds-services-section ">
      {/* <h2 className="section-title">Our Services</h2>
      <p className="section-subtitle">We Provide The Best Quality</p> */}
      <div className="services-grid">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="service-card"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="icon-container">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <motion.p
              className="service-description"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: hoveredIndex === index ? 1 : 0, x: hoveredIndex === index ? 0 : -20 }}
              transition={{ duration: 0.3 }}
            >
              {service.description}
            </motion.p>
            <div className="gradient-overlay"></div>
          </motion.div>
        ))}
      </div>
    </section>
    </div>
    </>
  );
};

export default ServicesSection;
