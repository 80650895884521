import { configureStore } from "@reduxjs/toolkit";
import CircularsReducer from "./slices/CircularsSlice";
import modalReducer from "./slices/modalSlice";


export const store = configureStore({
  reducer: {
    modal: modalReducer, 
    Circulars:CircularsReducer , 
   
  },
  devTools: true,
  // Enable Redux DevTools if in development mode
});

// Infer the `RootState` and `AppDispatch` types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
