import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { SdsForm } from "soft_digi";
import { CSP_SETTINGS_URLS, ROLE_URLS } from "../../api/AdminUrls";
import { post } from "../../services/smartApiService";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";


const options = [

  { value: "Every 12 Hours", label: "Every 12 Hours" },
  { value: "Every 24 Hours", label: "Every 24 Hours" },
  { value: "Every 48 Hours", label: "Every 48 Hours" },
  { value: "Every 72 Hours", label: "Every 72 Hours" },
  { value: "Every 96 Hours", label: "Every 96 Hours" },
  { value: "Every 144 Hours", label: "Every 144 Hours" },
  { value: "Every 288 Hours", label: "Every 288 Hours" },
];

const domainOptions = [
  { value: "example.com", label: "example.com" },
  { value: "testsite.org", label: "testsite.org" },
  { value: "secure.net", label: "secure.net" },
];

const securityLevels = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
];

const schema = Yup.object().shape({
  request_type: Yup.object().required("Update frequency is required"),
  // primary_email: Yup.string().email("Invalid email format").required("Primary email is required"),
  alter_email_id: Yup.string().email("Invalid email format"),
  // notifications: Yup.boolean().required("Notification preference is required"),
  // allowed_domains: Yup.array().min(1, "Select at least one domain"),
  // security_level: Yup.string().required("Select a security level"),
  // auto_block: Yup.boolean(),
});
interface HeaderProps{
  dataIn:any
}

const CspSettingsForm: React.FC<HeaderProps> = ({dataIn}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
    const { closeModal } = useSiteContext();

  const onSubmit = (data: any) => {
    let url = CSP_SETTINGS_URLS.INSERT;
    // if (data.ID !== undefined) {
    //   data["id"] = data.ID;
    //   url =ROLE_URLS.UPDATE;
    // }
    let insertData ={...data, csp_id: dataIn}
    const subscription = post(url, insertData).subscribe((response) => {
      closeModal();
      showAlertAutoClose("Setting Added  Successfully");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "SELECT_BOX",
      width: 12,
      name: "request_type",
      element: {
        label: "Update Frequency",
        options: options,
        isRequired: true,
      },
    },
    // {
    //   type: "TEXT_BOX",
    //   width: 12,
    //   name: "primary_email",
    //   element: {
    //     label: "Primary Email Address",
    //     placeHolder: "Enter your primary email",
    //     isRequired: true,
    //   },
    // },
    {
      type: "TEXT_BOX",
      width: 12,
      name: "alter_email_id",
      element: {
        label: "Another Email Address (Optional)",
        placeHolder: "Enter an alternate email",
      },
    },
    // {
    //   type: "CHECK_RADIO",
    //   width: 6,
    //   name: "notifications",
    //   element: {
    //     // label: "Enable Email Notifications",
    //     isRequired: true,
    //     options:[
    //       {
    //         value: 1,
    //         label: "Enable Email Notifications",
    //       }
    //     ],
       
    //   },
    // },
    // {
    //   type: "CHECK_RADIO",
    //   width: 6,
    //   name: "auto_block",
    //   element: {
    //   //  label: "Auto Block Suspicious Requests",
    //     // isRequired: true,
    //     options:[{
    //       value: 1,
    //       label: "Auto Block Suspicious Requests",
    //     }
    //     ]
    //   },
    // },
    // {
    //   type: "SELECT_BOX",
    //   width: 12,
    //   name: "allowed_domains",
    //   element: {
    //     label: "Allowed Domains",
    //     options: domainOptions,
    //     isRequired: true,
    //     isMulti: true,
    //   },
    // },
    // {
    //   type: "CHECK_RADIO",
    //   width: 12,
    //   name: "security_level",
    //   element: {
    //     label: "Security Level",
    //     options: securityLevels,
    //     isRequired: true,
    //     isMulti: true,
    //     type: "radio",
    //   },
    // },
   
    {
      type: "BUTTON",
      width: 12,
      name: "submitbutton",
      class_name: "has-text-centered",
      element: {
        label: "Save Settings",
        onClick: handleSubmit(onSubmit),
      },
    },
  ];

  return (
    <div className="csp-settings-container">
      <h2 className="title">CSP Analytics Settings</h2>
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};





export default CspSettingsForm