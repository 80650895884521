import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import AboutUs from "../Help/AboutUs";
import ChooseUs from "../Help/ChooseUs";
import CookieConsent from "../Help/CookieConsent";
import ProcessSection from "../Help/ProcessSection";
import StaffSection from "../Help/StaffSection";
import StatsSection from "../Help/StatsSection";
import TechnologiesList from "../Help/TechnologiesList";
import HomeSlider from "./HomeSlider";
import "./LandingPage.scss"; // Import SCSS
import ScrollToTop from "./ScrollToTop";
import CookieConsentModal from "../../components/CookieConsentComponent";

const LandingPage = () => {
  const [LandingPage, setLandingPage] = useState<boolean>(true);

  useEffect(() => {}, []);

  return (
    <div className="landing-page">
      <HomeSlider />

      <motion.section
        className="about-us-section hero is-large"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.7 }}
      >
        <AboutUs LandingPage={LandingPage} />
      </motion.section>

      <ChooseUs />

      <div className=" service-page-section">
        <StaffSection />
      </div>
      <TechnologiesList />

      {/* <div className="team-page-section">
        <TeamMembersList count={4} />
      </div> */}
      <StatsSection />
      <ProcessSection />
      <CookieConsentModal />
      {/* <div className=" team-service-page-section">
      <ServicesSection/>
      </div> */}
      <ScrollToTop />
    </div>
  );
};

export default LandingPage;
