import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./LandingPage.scss";
import {
  HERO,
  HERO_FOUR,
  HERO_ONE,
  HERO_THREE,
  HERO_TWO,
} from "../../services/ImageService";

const slides = [
  {
    title: "Comprehensive IT Security, Tailored for You",
    // subtitle: "for Your Success",
    description:
      "From infrastructure to cloud, we safeguard your digital assets. Secure, scalable, and seamless IT solutions.",
    image: HERO,
  },
  {
    title: "Defining the Future of Secure Web",
    // subtitle: "Driving Digital Transformation",
    description:
      "Protect your web assets with advanced CSP solutions. Prevent XSS, data breaches, and unauthorized access.",
    image: HERO_ONE,
  },
  {
    title: "Digitize Signatures, Retain Full Control",
    subtitle: "IT Infrastructure",
    description:
      "Eliminate dependency on third parties. Secure, efficient, and compliant on-premise digital signing solutions.",
    image: HERO_TWO,
  },
  {
    title: "Smarter HR & Healthcare Management",
    subtitle: "Delivering Value",
    description:
      "Seamless automation for HR & healthcare operations. Secure, scalable, and future-ready solutions.",
    image: HERO_THREE,
  },
  // {
  //   title: "Customer-Centric Approach",
  //   subtitle: "Delivering Value",
  //   description:
  //     "Focusing on customer needs to provide tailored IT services that drive success, efficiency, and innovation.",
  //   image: HERO_FOUR,
  // },
];

const HomeSlider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // Auto-scroll every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.section
      className="hero-section hero is-large"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="columns is-mobile p-0 m-0 ">
        <div className="column is-6 p-0 m-0">
          <motion.div
            key={currentSlide}
            className="animated-box landing-page-left-container"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0, y: [0, -10, 0] }}
            transition={{ duration: 0.8, ease: "easeOut", type: "tween" }}
          >
            <div className="best-text">Best IT Solution Provider</div>
            <h1 className="home-slide-title">{slides[currentSlide].title}</h1>
            {/* <h1 className="home-slide-title">
              {slides[currentSlide].subtitle}
            </h1> */}
            <p className="landing-page-sub-text">
              {slides[currentSlide].description}
            </p>
          </motion.div>
        </div>
        <div className="column is-6 p-0 m-0">
          <motion.div
            key={currentSlide}
            className="animated-box pl-6"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, y: [0, -10, 0] }}
            transition={{ duration: 0.8, ease: "easeOut", type: "tween" }}
          >
            <div className="landing-first-right-container">
              <figure className="image smart-sds-landing-image">
                <img src={slides[currentSlide].image} alt="Hero Image" />
              </figure>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default HomeSlider;
