import React, { useState } from "react";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { useForm } from "react-hook-form";
import { LOGO } from "../../services/ImageService";

const ForgetPassword = () => {
  const [stage, setStage] = useState("FIRST");
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    if (stage === "FIRST") {
      setStage("SECOND");
    } else if (stage === "SECOND") {
    }
  };

 

  const stage1_elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "publisher_name",
      element: {
        label: "Email or Mobile No.",
        isRequired: true,
        placeHolder: "Enter ",
      },
    },
    {
      type: "BUTTON",
      width: 12,
      name: "sendOtp",
      element: { label: "Send Otp", onClick: handleSubmit(onSubmit) },
    },
  ];

  const stage2_elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "otp_value",
      element: { max: 6 },
    },
    {
      type: "BUTTON",
      width: 12,
      name: "verify",
      element: { label: "Verify", onClick: handleSubmit(onSubmit) },
    },
  ];

  const formDisplay = (elements: SdsFormElementProps[]) => (
    <div className="register-page-business-login-form">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );

  const first_second_form = () => (
    <div>
      {stage === "FIRST" && (
        <>
    
          {formDisplay(stage1_elements)}
      
        </>
      )}
      {stage === "SECOND" && (
        <>
          {formDisplay(stage2_elements)}
        </>
      )}
    </div>
  );

 

  return (
    <div>   <div className="columns is-vcentered">
    <div className="column is-half has-text-centered smart-login-page-image">
      <img
        src= "https://images.pexels.com/photos/842711/pexels-photo-842711.jpeg"
        alt="Scheduling and Routing"
      />
    </div>

    <div className="column is-half pl-6 pr-6 pt-2 pb-2">
    <div className="smart-logo">
        <img
          src={LOGO}
          alt=""
          className="logo"
        /> </div>
 
         {(stage === "FIRST" || stage === "SECOND") && first_second_form()}

    </div>
  </div>
     
    </div>
  );
};



export default ForgetPassword