import React from "react";
import { motion } from "framer-motion";
import "./help.scss";
import { _NET, ANGULAR, C_C, FACEBOOK, NODE_JS, PHP, REACT_JS, REACT_NATIVE, SQL } from "../../services/ImageService";

const technologies = [
  { name: "React", logo: REACT_JS },
  { name: "Angular", logo: ANGULAR },
  { name: "PHP", logo:  PHP },
  // { name: "Node.js", logo: SQL },
  { name: "Node.js", logo:  C_C },
  { name: "Node.js", logo: _NET },
  { name: "Node.js", logo: REACT_NATIVE },
  { name: "Node.js", logo: NODE_JS },
];

const Technologies: React.FC = () => {
  return (
    <section className="technologiesSection">
      <h2 className="title">Specialized</h2>
      <h1 className="heading">Technologies</h1>

      <div className="slider-container">
        <motion.div
          className="slider"
          initial={{ x: "100%" }}
          animate={{ x: "-100%" }}
          transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
        >
          {technologies.map((tech, index) => (
            <div key={index} className="techItem image is-96x96">
              <img src={tech.logo} alt={tech.name} />
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Technologies;
