import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO, LOGO_ONE, SITE_LOGO } from "../../services/ImageService";
import { useSiteContext } from "../../contexts/SiteProvider";
import { checkInterSection } from "../../services/core/FilterService";
import { motion } from "framer-motion";
import "./sidenav.scss";
import { get } from "../../services/smartApiService";
import { SmartAlert, SmartLoaderInterface } from "soft_digi";
interface childrenProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}
const AdminSideNav: React.FC<childrenProps> = ({ isOpen, setIsOpen }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useSiteContext();
  const [hide, setHide] = useState(false);

  const [tabData, setTabData] = useState([]);
  const loadTableData = () => {
    let URL = "/admin_menu/get_all";
    const subscription = get(URL).subscribe((response) => {
      // console.log("links data ", response.data);
      setTabData(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const openLogoutModal = () => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          {/* <i className="fa fa-check"></i> Do You Want Logout! */}
        </span>
      ),
      alertFunction: (option) => {
        if (option === "yes") {
          handleLogout();
          SmartAlert.hide();
        }
      },
      content: <p>Note: Are you sure you want to log out?</p>,
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };

  const handleLogout = () => {
    console.log("logout");

    logout();
    navigate("/");
  };
  useEffect(() => {
    loadTableData();
  }, []);
  // console.log("logged in user ", user);
  const navigateLink = (index: any) => {
    navigate("/auth/" + index);
    if (window.innerWidth <= 768) {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      //setActive(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const headerLinks = [
    { name: "Dashboard", link: "/admin/dashboard", icon: "fa-superpowers" },

    { name: "Contact Us", link: "/admin/contact-list", icon: "fa-phone" },
    { name: "CSP", link: "/admin/csp-list", icon: "fa-globe" },
    { name: "Users", link: "/admin/users", icon: "fa-users" },
    { name: "Roles", link: "/admin/role", icon: "fa-users" },
    { name: "Settings", link: "/admin/Settings", icon: "fa-superpowers" },
    // { name: "Blog", link: "/terms-condition", icon: "newspaper" },
  ];

  return (
    <motion.div
      key="mobileScreen"
      className="admin-side-nav-container"
      // initial={{ opacity: 0, x: -250 }} // Start off-screen
      // animate={{
      //   opacity: isOpen ? 1 : 0,
      //   x: isOpen ? 0 : -250,
      // }}
      // exit={{ opacity: 0, x: -250 }} // Animate out
      // transition={{ duration: 0.3, ease: "easeOut", type: "tween" }}
    >
      <div className={`smart-sidenav ${isOpen ? "expanded" : ""}`}>
        {/* Brand Logo */}
        <div className="header-brand-">
          <img
            className="is-64x64  with-out-pointer"
            src={SITE_LOGO}
            alt="Brand Logo"
          />
        </div>
        {tabData.map((item: any, index: any) => {
          let _roles = Array.isArray(item.roles)
            ? item.roles
            : item.roles.split(",");
          return (
            checkInterSection(user?.roles || [], _roles) && (
              <div className="sds-side-nav-container">
                <motion.a
                  key={index}
                  className={` sds-admin-side-bar-list  ${
                    item?.dropdown ? " sds-hover-dropdown " : ""
                  } ${
                    location.pathname === item.link ? "active-navbar-item" : ""
                  }
              ${
                isOpen
                  ? "is-flex-direction-row"
                  : "is-flex-direction-column p-1 is-justify-content-center"
              }
              `}
                  href={item?.link || "#"}
                  onClick={(e) => {
                    if (!item?.link) e.preventDefault();
                    if (item?.label === "Logout") {
                      e.preventDefault();
                      openLogoutModal();
                    }
                  }}
                  whileHover={{ scale: 1.05, color: "#3273dc" }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div
                    className={` smart-admin-icon ${
                      !isOpen ? "has-text-centered is-size-5" : "mr-2"
                    }`}
                  >
                    {/* {item?.icon} */}
                    <i className={`fa ${item.icon}`} aria-hidden="true"></i>
                  </div>
                  <div
                    className={`${
                      !isOpen ? "is-size-7 has-text-centered " : ""
                    }`}
                  >
                    {item.label}
                  </div>
                </motion.a>
              </div>
              // )
            )
          );
        })}
      </div>
    </motion.div>
  );
};

export default AdminSideNav;
