import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { SITE_CIRCULAR } from "../../../api/AdminUrls";
import { post } from "../../smartApiService";
import { showAlertAutoClose } from "../../notifyService";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { closeModal } from "./modalSlice";

// const { openModal, closeModal } = useSiteContext();

const CircularsSlice = createSlice({
  name: "circulars",
  initialState: {
    tabData: [] as any[],
    selectedItem: null,
  },
  reducers: {
    setTabData: (state, action) => {
      state.tabData = action.payload;
    },
    updateTabData: (state, action) => {
      const updatedData = action.payload;
      const index = state.tabData.findIndex(
        (item) => item.id === updatedData.id
      );
      if (index !== -1) {
        state.tabData[index] = updatedData;
      }
    },
    addTabData: (state, action) => {
      state.tabData.push(action.payload);
    },
    deleteTabData: (state, action) => {
      state.tabData = state.tabData.filter(
        (item) => item.id !== action.payload
      );
    },
    selectTabData: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
});

export const {
  setTabData,
  updateTabData,
  addTabData,
  deleteTabData,
  selectTabData,
} = CircularsSlice.actions;
export default CircularsSlice.reducer;

export const loadTableData = () => (dispatch: AppDispatch) => {
  let URL = SITE_CIRCULAR.GET_ALL;
  const subscription = post(URL, {}).subscribe((response) => {
    dispatch(setTabData(response.data));
  });
  return () => {
    subscription.unsubscribe();
  };
};
export const onSubmit = (data: any) => (dispatch: AppDispatch) => {
  const url = data.ID ? SITE_CIRCULAR.UPDATE : SITE_CIRCULAR.INSERT;
  const subscription = post(url, data).subscribe((response) => {
    if (data.ID) {
      dispatch(updateTabData(response.data));
    } else {
      dispatch(addTabData(response.data));
    }
    //   closeModal()
    dispatch(loadTableData());
    dispatch(closeModal());
  });

  return () => {
    subscription.unsubscribe();
  };
};

export const deleteData = (id: any) => (dispatch: AppDispatch) => {
  const subscription = post(SITE_CIRCULAR.DELETE, { id }).subscribe(() => {
    dispatch(deleteTabData(id)); // Remove from store
    showAlertAutoClose("Deleted Successfully...", "success");
    dispatch(loadTableData());
  });

  return () => {
    subscription.unsubscribe();
  };
};
export const viewEditForm = (id: any) => (dispatch: AppDispatch) => {
    const subscription = post(SITE_CIRCULAR.GET_ONE, { id }).subscribe(
      (response: any) => {
        dispatch(selectTabData(response.data)); // Dispatch the selected item to Redux
      }
    );
  
    return () => {
      subscription.unsubscribe();
    };
  };
