import React, { useState, useMemo } from "react";
import "./Login.scss";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { SdsForm } from "soft_digi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { post } from "../../services/smartApiService";
import { useNavigate } from "react-router-dom";
import { showAlertAutoClose } from "../../services/notifyService";

// Props for each sub-component
type RegisterPageProps = {
  setStage: (val: string) => void;
  onSubmit: (data: any) => void;
};

const ModalLoginPage: React.FC = () => {
  const [stage, setStage] = useState("FIRST");
  const [otpId, setOtpId] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const navigate = useNavigate();

  // Validation Schemas
  const validationSchema = useMemo(() => {
    if (stage === "FIRST") {
      return Yup.object({
        emailid: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        epassword: Yup.string()
          .required("Password is required")
          .min(6, "Password must be at least 6 characters"),
        confirm_password: Yup.string()
          .oneOf([Yup.ref("epassword")], "Passwords must match")
          .required("Confirm password is required"),
      });
    }

    if (stage === "SECOND") {
      return Yup.object({
        otp_value: Yup.string()
          .required("OTP is required")
          .length(6, "OTP must be 6 digits"),
      });
    }

    if (stage === "THIRD") {
      return Yup.object({
        mobile_no: Yup.string()
          .required("Mobile number is required")
          .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
        ename: Yup.string().required("Name is required"),
      });
    }

    return Yup.object({});
  }, [stage]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    // console.log("Form data:", data);
    setUserData({ ...userData, ...data });

    if (stage === "FIRST") {
      const url = "auth/send-otp";
      const emailid = data.emailid;

      const subscription = post(
        url,
        { emailid },
        { requiresAuthorization: false }
      ).subscribe((response) => {
        setOtpId(response.data.otp_id);
        showAlertAutoClose("OTP Sent Successfully");
        setStage("SECOND");
      });

      return () => {
        subscription.unsubscribe();
      };
    }

    if (stage === "SECOND") {
      const url = "/auth/verify_otp";
      const _data = {
        otp: data.otp_value,
        emailid: userData.emailid,
      };

      const subscription = post(url, _data, {
        requiresAuthorization: false,
      }).subscribe((response) => {
        showAlertAutoClose("OTP Verified Successfully");
        setStage("THIRD");
      });

      return () => {
        subscription.unsubscribe();
      };
    }

    if (stage === "THIRD") {
      const url = "auth/signup";
      const _data = {
        emailid: userData.emailid,
        epassword: userData.epassword,
        mobile_no: data.mobile_no,
        ename: data.ename,
      };

      const subscription = post(url, _data, {
        requiresAuthorization: false,
      }).subscribe((response) => {
        showAlertAutoClose("Registered Successfully");
        setStage("FIRST");
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  };

  // Stage TWO form (OTP verification)
  const RegisterPage: React.FC<RegisterPageProps> = ({ onSubmit }) => {
    const stage2_elements: SdsFormElementProps[] = [
      {
        type: "TEXT_BOX",
        width: 12,
        name: "otp_value",
        element: {
          label: "Enter OTP",
          placeHolder: "6-digit OTP",
          max: 6,
          isRequired: true,
          inputType: "BORDER_LABEL",
        },
      },
      {
        type: "BUTTON",
        width: 12,
        name: "verify_otp",
        class_name: "login-button",
        element: {
          label: "Verify OTP",
          onClick: handleSubmit(onSubmit),
          classList: ["cta-button"],
        },
      },
    ];
    return <SdsForm elements={stage2_elements} errors={errors} control={control} />;
  };

  // Stage ONE form (Email & Password Registration)
  const Registrations: React.FC<RegisterPageProps> = ({ onSubmit }) => {
    const registrationElements: SdsFormElementProps[] = [
      {
        type: "TEXT_BOX",
        width: 12,
        name: "emailid",
        element: {
          label: "Email ID",
          placeHolder: "Enter Email",
          isRequired: true,
          inputType: "BORDER_LABEL",
        },
      },
      {
        type: "PASSWORD",
        width: 12,
        name: "epassword",
        element: {
          label: "Password",
          placeHolder: "Enter Password",
          isRequired: true,
          passwordValidator: false,
          inputType: "BORDER_LABEL",
        },
      },
      {
        type: "PASSWORD",
        width: 12,
        name: "confirm_password",
        element: {
          label: "Confirm Password",
          placeHolder: "Re-enter Password",
          isRequired: true,
          passwordValidator: false,
          inputType: "BORDER_LABEL",
        },
      },
      {
        type: "BUTTON",
        width: 12,
        name: "register",
        class_name: "login-button",
        element: {
          label: "Send OTP",
          onClick: handleSubmit(onSubmit),
          classList: ["cta-button"],
        },
      },
    ];
    return <SdsForm elements={registrationElements} errors={errors} control={control} />;
  };

  // Stage THREE form (Final Registration Details)
  const Registration_Last: React.FC<RegisterPageProps> = ({ onSubmit }) => {
    const elements: SdsFormElementProps[] = [
      {
        type: "TEXT_BOX",
        width: 12,
        name: "emailid",
        element: {
          label: "Email ID",
          placeHolder: "Enter Email",
          isRequired: true,
          inputType: "BORDER_LABEL",
          disabled: !!userData,
        },
      },
      {
        type: "PASSWORD",
        width: 12,
        name: "epassword",
        element: {
          label: "Password",
          placeHolder: "Enter Password",
          isRequired: true,
          inputType: "BORDER_LABEL",
          disabled: !!userData,
        },
      },
      {
        type: "TEXT_BOX",
        width: 12,
        name: "mobile_no",
        element: {
          label: "Mobile No.",
          placeHolder: "Enter Mobile No.",
          isRequired: true,
          inputType: "BORDER_LABEL",
        },
      },
      {
        type: "TEXT_BOX",
        width: 12,
        name: "ename",
        element: {
          label: "Name",
          placeHolder: "Enter Name",
          isRequired: true,
          inputType: "BORDER_LABEL",
        },
      },
      {
        type: "BUTTON",
        width: 12,
        name: "complete_registration",
        class_name: "login-button",
        element: {
          label: "Register",
          onClick: handleSubmit(onSubmit),
          classList: ["cta-button"],
        },
      },
    ];
    return <SdsForm elements={elements} errors={errors} control={control} />;
  };

  return (
    <>
      {stage === "FIRST" && (
        <Registrations setStage={setStage} onSubmit={onSubmit} />
      )}
      {stage === "SECOND" && (
        <RegisterPage setStage={setStage} onSubmit={onSubmit} />
      )}
      {stage === "THIRD" && (
        <Registration_Last setStage={setStage} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default ModalLoginPage;
