import React, { useState } from "react";

import EFHeader from "./Header";
import "./MainTheme.scss";
import EFSideNav from "./SideNav";
import "./MainLayout.scss";
import { useSiteContext } from "../../contexts/SiteProvider";
import { Outlet } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import AdminSideNav from "./AdminSideNav";


const Layout = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDark, setIsDark] = useState<boolean>(true);
  const { user } = useSiteContext();

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  return (
    <div
      className={`container is-fluid smart-dark-efl-container ${
        !isDark ? "smart-efl-container" : ""
      }`}
    >
      
     
      <div className="efl-main-sidenav">
        <AdminSideNav isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className={`efl-main-header ${isOpen ? "" : ""}`}>
        <EFHeader
          setIsOpen={(value) => setIsOpen(value)}
          isOpen={isOpen}
          setIsDark={setIsDark}
          isDark={isDark}
        />
      </div>
      <div className={`efl-main-div ${isOpen ? "expanded" : ""}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
