import React, { useEffect, useState } from "react";
import CspDirectiveDispaly from "./CspDirectiveDisplay";
import { useSiteContext } from "../../contexts/SiteProvider";
import { post } from "../../services/smartApiService";
import { useParams } from "react-router-dom";
import CSPTable from "../Help/Projects/CSPTable";
import CSPCards from "../Help/Projects/CSPCards";
import ScorePieChart from "../Help/Projects/ScorePieChart";
import ClientLineChart from "./ClientLineChart";
type DirectiveValue = {
  label: string;
  status: "success" | "warning" | "danger";
};

type CSPDirective = {
  directive: string;
  values: DirectiveValue[];
  recommendation: string;
  status: "success" | "warning" | "danger";
};

const sampleDirectives: CSPDirective[] = [
  {
    directive: "default-src",
    values: [
      { label: "'self'", status: "success" },
      { label: "'unsafe-inline'", status: "danger" },
    ],
    recommendation: "Remove 'unsafe-inline' to prevent XSS attacks.",
    status: "danger",
  },
  {
    directive: "script-src",
    values: [
      { label: "'self'", status: "success" },
      { label: "cdn.example.com", status: "success" },
    ],
    recommendation: "Ensure all external scripts are from trusted sources.",
    status: "success",
  },
  {
    directive: "img-src",
    values: [{ label: "*", status: "warning" }],
    recommendation: "Avoid using wildcard '*' in img-src.",
    status: "warning",
  },
];

const CspAnalytics: React.FC = ({}) => {
  const { id } = useParams();
  const [tabData, setTabData] = useState<any>([]);
  const [directive, setDirective] = useState<any[]>([]);
  const [dangerDirectives, setDangerDirectives] = useState([]);
  const [score, setScore] = useState<any>(0);
  const [status, setStatus] = useState<any>();
  const [summary, setSummary] = useState<any>();

  const { openModal, closeModal, setTitle, user } = useSiteContext();
  useEffect(() => {
    setTitle("CSP Directives");
  }, []);
  const loadTableData = () => {
    let URL = "/csp/get_one";

    const subscription = post(URL, { id: id }).subscribe((response) => {
      setTabData(response.data.data);
      setDirective(response.data.data.directives);
      setScore(response.data.data.overall_score);
      setStatus(response.data.data.overall_status);
      setSummary(response.data.data.summary);
      const filteredDanger =
        response.data.data.directives?.filter(
          (item: any) => item.status === "danger"
        ) || [];
      setDangerDirectives(filteredDanger);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  console.log("loadTableData", tabData?.url?.sd_url);

  useEffect(() => {
    loadTableData();
  }, [id]);

  return (
    <div className="section">
      <div className="columns  is-multiline">
        <div className="column is-12-mobile is-3-tablet px-3 ">
          {tabData && tabData?.directives && (
            <>
              <div className="smart-csp-score-card">
                <ScorePieChart overall_score={score} status={status} />
                <p className="has-text-black">{summary}</p>
              </div>
            </>
          )}
        </div>
        <div className="column is-8">
          {tabData && tabData?.directives && (
            <>
              <CSPCards data={tabData} />
              <CSPTable tabData={tabData} />
            </>
          )}
        </div>
        <div className="column is-12">
          {tabData && tabData?.directives && (
            <>
              <ClientLineChart sd_url={tabData?.url?.sd_url} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CspAnalytics;
