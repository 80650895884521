import React from 'react';
import {
    FaBug,
    FaBuilding,
    FaCertificate,
    FaChartLine,
    FaCheckCircle,
    FaClock,
    FaCode,
    FaLock,
    FaMoneyBillWave,
    FaPuzzlePiece,
    FaRocket,
    FaShieldAlt,
    FaTools,
    FaTrophy,
    FaUserShield,
    FaUserTie
} from 'react-icons/fa';



// Define types for supporting content
interface SupportingPoint {
  icon: React.ElementType;
  text: string;
}

// Define type for each card
interface SecurityCardProps {
  icon: React.ElementType;
  title: string;
  description: string;
  supportingPoints: SupportingPoint[];
}

// Individual Card component
const SecurityCard: React.FC<SecurityCardProps> = ({ 
  icon: Icon, 
  title, 
  description, 
  supportingPoints 
}) => {
  return (
    <div className="column is-4">
      <div className="card csp-card">
        <div className="card-content">
          <div className="has-text-centered mb-4">
            <span className="icon is-large">
              <Icon className="fa-3x csp-icon-primary" />
            </span>
          </div>
          <p className="title is-4 ">
            {title}
          </p>
          <p className="subtitle is-6 " dangerouslySetInnerHTML={{ __html: description }} />
          
          <div className="content mt-4">
            {supportingPoints.map((point, index) => {
              const PointIcon = point.icon;
              return (
                <p key={index} className=" csp-supporting-point is-size-7">
                  <PointIcon className="mr-2 csp-supporting-icon mt-1" /> {point.text}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

// Main component
const CSPBusinessImportance: React.FC = () => {
  // Define card data
  const securityCards: SecurityCardProps[] = [
    {
      icon: FaShieldAlt,
      title: "Attack Prevention",
      description: "<strong>Prevents malicious attacks</strong> like XSS, clickjacking, and data theft.",
      supportingPoints: [
        { icon: FaLock, text: "Blocks cross-site scripting (XSS) attempts" },
        { icon: FaUserShield, text: "Prevents clickjacking and UI redressing" },
        { icon: FaBug, text: "Mitigates data theft via script injection" }
      ]
    },
    {
      icon: FaCode,
      title: "Third-Party Protection",
      description: "<strong>Protects against third-party package vulnerabilities</strong> – Many external libraries may contain hidden malicious code that can steal data. CSP helps prevent unauthorized script execution, ensuring security.",
      supportingPoints: [
        { icon: FaShieldAlt, text: "Restricts which scripts can execute" },
        { icon: FaUserShield, text: "Controls external resource loading" },
        { icon: FaLock, text: "Enforces trusted source policies" }
      ]
    },
    {
      icon: FaMoneyBillWave,
      title: "Business Protection",
      description: "<strong>Prevents revenue loss & legal risks</strong> due to potential data breaches.",
      supportingPoints: [
        { icon: FaMoneyBillWave, text: "Safeguards against financial damages" },
        { icon: FaUserShield, text: "Maintains customer trust and loyalty" },
        { icon: FaShieldAlt, text: "Helps with regulatory compliance (GDPR, CCPA)" }
      ]
    }
  ];

  const strategyCards: SecurityCardProps[] = [
    {
      icon: FaRocket,
      title: "Seamless Integration",
      description: "<strong>Specialized in seamless CSP implementation without business disruption</strong>",
      supportingPoints: [
        { icon: FaClock, text: "Zero downtime deployment methodology" },
        { icon: FaChartLine, text: "Phased rollout with continuous monitoring" },
        { icon: FaUserTie, text: "Dedicated transition support team" },
        { icon: FaShieldAlt, text: "Proactive risk mitigation planning" }
      ]
    },
    {
      icon: FaPuzzlePiece,
      title: "Tailored Solutions",
      description: "<strong>Custom-fit for your applications & works seamlessly with existing security measures</strong>",
      supportingPoints: [
        { icon: FaTools, text: "Customized CSP policies for your tech stack" },
        { icon: FaLock, text: "Integration with existing security frameworks" },
        { icon: FaShieldAlt, text: "Compatibility with WAF, SIEM and other tools" },
        { icon: FaCertificate, text: "Policy optimization for performance" }
      ]
    },
    {
      icon: FaTrophy,
      title: "Proven Track Record",
      description: "<strong>Successfully implemented CSP for 16+ major enterprise applications</strong>",
      supportingPoints: [
        { icon: FaBuilding, text: "Trusted by Fortune 16+ companies" },
        { icon: FaCheckCircle, text: "100% implementation success rate" },
        { icon: FaChartLine, text: "Average 87% reduction in XSS vulnerabilities" },
        { icon: FaShieldAlt, text: "Compliance-ready implementations" }
      ]
    }
  ];

  return (
    <>
    <section className="section">
      <div className="container">
        <h1 className="title is-3 has-text-centered has-text-info mb-6 csp-title">
          Why CSP is Critical for Your Business
        </h1>
        
        <div className="columns">
          {securityCards.map((card, index) => (
            <SecurityCard key={index} {...card} />
          ))}
        </div>
      </div>
    </section>
      <section className="section">
      <div className="container">
        <h1 className="title is-3 has-text-centered has-text-info mb-6 csp-title">
          Why Choose Us – Our Proven CSP Implementation Strategy
        </h1>
        
        <div className="columns">
          {strategyCards.map((card, index) => (
            <SecurityCard key={index} {...card} />
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

export default CSPBusinessImportance;