import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import "./Pricing.scss";
import SmartHeader from "../../../components/SmartHeader";
import PricingForm from "./PricingForm";

interface PricingTier {
  name: string;
  monthlyPrice: number;
  description: string;
  features: string[];
  buttonText: string;
  buttonLink: string;
  highlighted?: boolean;
}

const CspPricing: React.FC = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PricingTier | null>(null);
  const [showRegistration, setShowRegistration] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const yearlyDiscount = 0.15; // 15% discount

  const pricingTiers: PricingTier[] = [
    {
      name: "Basic",
      monthlyPrice: 99,
      description: "For small websites and personal projects",
      features: [
        "Monitor up to 5 websites",
        "Weekly CSP reports",
        "Basic violation alerts",
        "Email support",
        "Content Security Policy templates",
      ],
      buttonText: "Get Started",
      buttonLink: "/signup/basic",
    },
    {
      name: "Business",
      monthlyPrice: 449,
      description: "For growing businesses with multiple properties",
      features: [
        "Monitor up to 25 websites",
        "Daily CSP reports",
        "Advanced violation analytics",
        "Slack & email alerts",
        "Custom policy builder",
        "Priority support",
        "API access",
      ],
      buttonText: "Start Free Trial",
      buttonLink: "/signup/business",
      highlighted: true,
    },
    {
      name: "Enterprise",
      monthlyPrice: 0,
      description: "For large organizations with complex needs",
      features: [
        "Unlimited website monitoring",
        "Real-time CSP reports",
        "Advanced security insights",
        "Custom integrations",
        "Dedicated account manager",
        "SLA guarantee",
        "On-premises option available",
      ],
      buttonText: "Request Demo",
      buttonLink: "/request-demo",
    },
  ];

  const comparisonFeatures = [
    {
      name: "Websites Monitored",
      basic: "5",
      business: "25",
      enterprise: "Unlimited",
    },
    {
      name: "Reporting Frequency",
      basic: "Weekly",
      business: "Daily",
      enterprise: "Real-time",
    },
    {
      name: "CSP Violation Alerts",
      basic: "Basic",
      business: "Advanced",
      enterprise: "Advanced",
    },
    { name: "Email Support", basic: "✅", business: "✅", enterprise: "✅" },
    { name: "Phone Support", basic: "❌", business: "✅", enterprise: "✅" },
    {
      name: "Dedicated Account Manager",
      basic: "❌",
      business: "❌",
      enterprise: "✅",
    },
    { name: "API Access", basic: "❌", business: "✅", enterprise: "✅" },
    {
      name: "Custom Integrations",
      basic: "❌",
      business: "Limited",
      enterprise: "Full",
    },
    { name: "SLA Guarantee", basic: "❌", business: "❌", enterprise: "✅" },
  ];

  const handlePlanSelection = (plan: PricingTier) => {
    if (plan.name === "Enterprise") {
      // For Enterprise, just redirect to demo request
      window.location.href = plan.buttonLink;
      return;
    }

    setSelectedPlan(plan);
    setShowRegistration(true);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission - would connect to payment processor in real implementation
    alert("Payment processed successfully!");
    setShowRegistration(false);
    setSelectedPlan(null);
  };

  const calculatePrice = (basePrice: number, isYearly: boolean) => {
    if (basePrice === 0) return "Custom"; // For Enterprise plan

    const price = isYearly ? basePrice * 12 * (1 - yearlyDiscount) : basePrice;

    return `$${price.toFixed(0)}`;
  };

  const calculateTotalPrice = () => {
    if (!selectedPlan) return 0;

    return isYearly
      ? selectedPlan.monthlyPrice * 12 * (1 - yearlyDiscount)
      : selectedPlan.monthlyPrice;
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      <SmartHeader
        title="Choose Your Plan"
        subTitle="Secure your websites with our powerful CSP monitoring service"
      />

      <div className="section pricing-section">
        <div className="container">
          <div className="has-text-centered mb-6">
            {/* <h1 className="title is-2">Choose Your Plan</h1>
          <p className="subtitle is-5">Secure your websites with our powerful CSP monitoring service</p> */}

            {/* Billing toggle */}
            <div className="billing-toggle mb-5">
              <span className={!isYearly ? "is-active" : ""}>Monthly</span>
              <label className="switch mx-3">
                <input
                  type="checkbox"
                  checked={isYearly}
                  onChange={() => setIsYearly(!isYearly)}
                />
                <span className="slider round"></span>
              </label>
              <span className={isYearly ? "is-active" : ""}>
                Yearly
                <span className="tag is-primary ml-2">Save 15%</span>
              </span>
            </div>
          </div>

          {/* Pricing Cards */}
          <AnimatePresence>
            {!showRegistration && (
              <motion.div
                className="columns is-multiline"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit={{ opacity: 0 }}
              >
                {pricingTiers.map((tier, index) => (
                  <motion.div
                    className="column is-4"
                    key={index}
                    variants={itemVariants}
                  >
                    <div
                      className={`card pricing-card ${
                        tier.highlighted ? "is-highlighted" : ""
                      }`}
                    >
                      <div className="card-content">
                        <p className="title is-4">{tier.name}</p>
                        <p className="pricing-cost">
                          <span className="is-size-1 has-text-weight-bold">
                            {calculatePrice(tier.monthlyPrice, isYearly)}
                          </span>
                          {tier.monthlyPrice !== 0 && (
                            <span className="is-size-6">
                              /{isYearly ? "year" : "month"}
                            </span>
                          )}
                        </p>
                        <p className="is-size-6 mb-4">{tier.description}</p>

                        <div className="pricing-features">
                          {tier.features.map((feature, featureIndex) => (
                            <div
                              className="pricing-feature-item"
                              key={featureIndex}
                            >
                              <span className="icon has-text-success">
                                <i className="fas fa-check"></i>
                              </span>
                              <span>{feature}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="card-footer-item">
                          <button
                            onClick={() => handlePlanSelection(tier)}
                            className={`button is-fullwidth ${
                              tier.highlighted ? "is-primary" : "is-light"
                            }`}
                          >
                            {tier.buttonText}
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>

          {/* Registration Form */}
          <AnimatePresence>
            {showRegistration && selectedPlan && (
              <motion.div
                className="registration-section"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <div className="columns">
                  <div className="column is-12 ">
                    <div className="box">
                      <h2 className="title is-3 has-text-centered mb-5">
                        Complete Your Order
                      </h2>

                      <div className="columns">
                        <div className="column is-4">
                          <div className="order-summary">
                            <h3 className="title is-5">Order Summary</h3>
                            <div className="box plan-summary">
                              <p className="is-size-5 has-text-weight-bold">
                                {selectedPlan.name} Plan
                              </p>
                              <p className="is-size-6">
                                {isYearly ? "Annual" : "Monthly"} Subscription
                              </p>
                              <hr />

                              <div className="level">
                                <div className="level-left">
                                  <div className="level-item">
                                    <span>Base Price</span>
                                  </div>
                                </div>
                                <div className="level-right">
                                  <div className="level-item">
                                    $
                                    {isYearly
                                      ? (
                                          selectedPlan.monthlyPrice * 12
                                        ).toFixed(2)
                                      : selectedPlan.monthlyPrice.toFixed(2)}
                                  </div>
                                </div>
                              </div>

                              {isYearly && (
                                <div className="level">
                                  <div className="level-left">
                                    <div className="level-item">
                                      <span>Yearly Discount (15%)</span>
                                    </div>
                                  </div>
                                  <div className="level-right">
                                    <div className="level-item has-text-danger">
                                      -$
                                      {(
                                        selectedPlan.monthlyPrice *
                                        12 *
                                        yearlyDiscount
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <hr />
                              <div className="level">
                                <div className="level-left">
                                  <div className="level-item">
                                    <span className="has-text-weight-bold">
                                      Total
                                    </span>
                                  </div>
                                </div>
                                <div className="level-right">
                                  <div className="level-item has-text-weight-bold">
                                    ${calculateTotalPrice().toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mt-4">
                              <button
                                className="button is-light is-fullwidth"
                                onClick={() => setShowRegistration(false)}
                              >
                                Change Plan
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="column is-8">
                          
                          <PricingForm
                            plan={selectedPlan}
                            calculateTotalPrice={calculateTotalPrice}
                            isYearly={isYearly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Comparison Table (only show when not in registration mode) */}
          {!showRegistration && (
            <motion.div
              className="comparison-section mt-6 pt-6"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.6 }}
            >
              <h2 className="title is-3 has-text-centered mb-5">
                Feature Comparison
              </h2>

              <div className="table-container">
                <table className="table is-fullwidth is-hoverable comparison-table">
                  <thead>
                    <tr>
                      <th>Feature</th>
                      <th className="has-text-centered">Basic</th>
                      <th className="has-text-centered  has-text-primary">
                        Business
                      </th>
                      <th className="has-text-centered">Enterprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    {comparisonFeatures.map((feature, index) => (
                      <tr key={index}>
                        <td>{feature.name}</td>
                        <td className="has-text-centered">{feature.basic}</td>
                        <td className="has-text-centered has-text-primary">
                          {feature.business}
                        </td>
                        <td className="has-text-centered">
                          {feature.enterprise}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </motion.div>
          )}

          {/* CTA Section (only show when not in registration mode) */}
          {!showRegistration && (
            <motion.div
              className="cta-section has-text-centered mt-6 pt-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <div className="box p-6 colorful-box ">
                <h3 className="title is-4">
                  Not sure which plan is right for you?
                </h3>
                <p className="mb-4">
                  Contact our team for a personalized recommendation
                </p>
                <a href="/contact-us" className="button is-secondary is-medium">
                  Contact Sales
                </a>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default CspPricing;
