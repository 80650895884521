import React, { useEffect, useState } from "react";

import "../../assets/scss/kgr.scss";
import CspDashboardCards from "./CspDashboardCards";
import { useSiteContext } from "../../contexts/SiteProvider";
import { get, post } from "../../services/smartApiService";

const ClientDashBoard: React.FC = () => {
  const [tabData, setTabData] = useState<any>([]);
  const { openModal, closeModal, setTitle, user } = useSiteContext();
  useEffect(() => {
    setTitle("CSP Monitoring Dashboard");
  }, []);
  const loadTableData = () => {
    let URL = "/csp/get_all_email";
     let email_id=user.emailid
  // let email_id = "jana@gmail.com";
    const subscription = post(URL, { email_id }).subscribe((response) => {
      setTabData(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  console.log("Loading",tabData)

  useEffect(() => {
    loadTableData();
  }, []);
 ;

  const handleAddSite = () => {
    alert("Navigate to Add New Site Page");
  };
  const sampleSites = [
    {
      id: 1,
      name: "example.com",
      score: 85,
      lastChecked: "10 min ago",
      recommendations: [
        "Consider upgrading to TLS 1.3",
        "Enable Content Security Policy (CSP) headers",
        "Restrict inline scripts using 'nonce' or 'sha256'",
      ],
    },
    {
      id: 2,
      name: "insecure-site.com",
      score: 40, // Below 50, so it will show a danger message
      lastChecked: "1 day ago",
      recommendations: [
        "CSP policy missing: Implement default-src 'self'",
        "Remove 'unsafe-inline' from script-src directive",
        "Restrict frame ancestors to prevent clickjacking",
      ],
    },
    {
      id: 3,
      name: "secure-site.io",
      score: 95,
      lastChecked: "3 hours ago",
      recommendations: [],
    },
  ];

  return (
    <div className="team-service-page-section">
      <CspDashboardCards sites={tabData} onAddSite={ loadTableData} />
    </div>
  );
};

export default ClientDashBoard;
