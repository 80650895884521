import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";

interface HeaderProps {
  control: any;
  errors: any;
  watch: any;
  setValue: any;
}

const GallerySubForm: React.FC<HeaderProps> = ({
  control,
  errors,
  watch,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "sub_data",
  });

  //console.log("products " , products);

  const addRemoveButtons = (index: number): React.ReactNode => {
    return (
      <>
        <span className="icon">
          {index === fields.length - 1 && (
            <i
              className="fa fa-plus has-text-link smart-cursor"
              onClick={() => append({})}
            ></i>
          )}
        </span>
        <span className="icon">
          {" "}
          {index > 0 && (
            <i
              className="fa fa-trash has-text-danger smart-cursor"
              onClick={() => remove(index)}
            ></i>
          )}
        </span>
      </>
    );
  };

  const getElements = (index: number) => {
    const elements: SdsFormElementProps[] = [
      {
        type: "TEXT_BOX",
        width: 4,
        name: `sub_data.${index}.image_title`,
        element: {
        //   size: "small",
        },
      },

      {
        type: "FILE",
        width: 6,
        name: `sub_data.${index}.image_file`,
        element: {
          placeHolder: "(Image Only)",
          accept: "*",
          size: "small",
        },
      },

      {
        type: "LABEL",
        width: 1,
        class_name: "has-text-centered p-2",
        name: "addbuttons",
        labelComponent: addRemoveButtons(index),
      },
    ];
    return elements;
  };

  return (
    <>
      <p className="has-text-link">
        {" "}
        <u>Gallery Addition</u>
      </p>
      <div className="sd-hims-input sd-hims-batch-form">
        <div className="columns is-gapless mb-0">
          <div className="column is-4">
            <label className="is-size-7">Title</label>
          </div>
          <div className="column is-3">
            <label className="is-size-7">Image</label>
          </div>
         
        </div>
        {fields.map((field, index) => (
          <SdsForm
            elements={getElements(index)}
            errors={errors}
            control={control}
            className=" mb-1"
          />
        ))}
      </div>
    </>
  );
};

export default GallerySubForm;
