const config: siteConfig = {
  API_URL: "http://localhost:3005/api",
  CONTACT_DETAILS:{
    phone_1:"+91 9392336796",
    phone_2:"+91 8332049208",
    email:"sds@hdtsolution.com"
  }
};

export default config;
