import React, { useEffect, useState } from "react";
import { SdsForm } from "soft_digi";
import { ROLE_URLS } from "../../api/AdminUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import {
  
  user_get_select,
} from "../../services/site/SelectBoxServices";
import { post } from "../../services/smartApiService";
import {
  SmartValid,
  ValidateFormNew,
} from "soft_digi/dist/services/smartValidationService";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

interface FormErrors {
  [key: string]: string | null;
}
interface HeaderProps {
  loadTableData: () => void;
  dataIn: any;
}
const schema = Yup.object({
  role_name: Yup.string()
    .required("Role name is required")
    .min(2, "Role name must be at least 2 characters"),
  users: Yup.array().default([]).min(1, "Please select an option"),
});
const RoleForm: React.FC<HeaderProps> = ({ loadTableData, dataIn }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),  defaultValues: dataIn
  });
  const { closeModal } = useSiteContext();
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    user_get_select((data: any) => setAllUsers(data));
  }, []);


  const onSubmit = (data: any) => {
    let url = ROLE_URLS.INSERT;
    if (data.ID !== undefined) {
      data["id"] = data.ID;
      url =ROLE_URLS.UPDATE;
    }
    const subscription = post(url, data).subscribe((response) => {
      closeModal();
      loadTableData();
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "role_name",
      element: {
        label: "Role Name",
        isRequired: true,
      },
    },

    {
      type: "SELECT_BOX",
      width: 12,
      name: "users",
      element: {
        label: "Users",
        isMulti: true,
        options: allUsers,
      },
    },
    {
      type: "BUTTON",
      width: 12,
      name: "submitbutton",
      element: {
        label: "Submit",
        onClick: handleSubmit(onSubmit),
      },
    },
  ];
  return (
    <>
      <div className="sd-hims-input">
        <SdsForm elements={elements} errors={errors} control={control} />
      </div>
    </>
  );
};

export default RoleForm;
