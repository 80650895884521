import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaLock, FaChartLine, FaArrowRight } from "react-icons/fa";
import { useSiteContext } from "../contexts/SiteProvider";
import "./component.scss";

const UpgradeCspModal: React.FC = () => {
  const { closeModal } = useSiteContext();
  const navigate = useNavigate();

  const redirectPricing = () => {
    navigate("/content-security-policy-evaluation-tool-pricing");
    closeModal();
  };

  return (
    <div className="container has-text-centered">
      <motion.div
        className="box custom-upgrade-box"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <p className="title is-5 has-text-danger">
          <FaLock className="icon-lock" /> Upgrade to Premium
        </p>
        <p className="subtitle is-6">
          You have reached your free limit of 2 website analyses.
        </p>
        <motion.p
          className="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Unlock **advanced security insights**, **detailed reports**, and
          **priority scanning** by upgrading to our **Premium Plan**.
        </motion.p>
        <motion.ul
          className="features-list"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <li>
            <FaChartLine className="icon-feature" /> In-depth vulnerability
            analysis
          </li>
          <li>
            <FaChartLine className="icon-feature" /> Historical data & trend
            insights
          </li>
          <li>
            <FaChartLine className="icon-feature" /> Faster scanning & priority
            support
          </li>
        </motion.ul>
        <motion.button
          className="button is-primary is-medium"
          onClick={() => redirectPricing()}
          whileHover={{ scale: 1.1 }}
        >
          Upgrade Now <FaArrowRight />
        </motion.button>
      </motion.div>
    </div>
  );
};

export default UpgradeCspModal;
