import { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { USER_URLS } from "../../api/AdminUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { get, post } from "../../services/smartApiService";




const MembersWatingsList = () => {
  const [tabData, setTabData] = useState([]);
  const { openModal, closeModal, } = useSiteContext();

  const loadTableData = () => {
    let URL = USER_URLS.GET_ALL;
    const payload = { status: 1 }; 
    const subscription = post(URL, payload).subscribe((response) => {
      setTabData(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

 
  const deleteData = (id: any) => {
    const subscription = post(USER_URLS.APPROVED, { id: id }).subscribe(
      (response) => {
        showAlertAutoClose("Approved Successfully...", "success");
        closeModal();
        loadTableData();
    
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openApprovedModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Members Selection!
        </span>
      ),
      alertFunction: (option) => {
        if (option == "yes") {
          deleteData(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to Approved this Members?
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };


  

  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-times",
      classList: ["smart-kgr-table-view-icon"],
      onClick: (data: any) => {
        // openViewDetails(data);
      },
    },
    // {
    //   label: "",
    //   type: "icon",
    //   leftIcon: " fa-pencil-square-o",
    //   classList: ["smart-hims-table-edit-icon"],
    //   onClick: (data: any) => {
    //     viewEditForm(data["ID"]);
    //   },
    // },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-check",
      classList: ["smart-kgr-table-delete-icon"],
      onClick: (data: any) => {
        openApprovedModal(data["ID"]);
      },
    },
    
  ];
  const statusTags = [
    { value: 5, label: "Active", class: "is-primary" },
    { value: 10, label: "Inactive", class: "is-danger" },
  ];


  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width: "5" },
    {
      title: "Ic No.",
      index: "icno",
      width: "10",
    },
    {
      title: "Name",
      index: "ename",
      width: "10",
    },
    {
      title: "Email",
      index: "email_id",
      width: "15",
    },
    {
      title: "Office",
      index: "unit_office",
      // width: "10",
    },
 

    {
      title: "	Status",
      index: "active_status",
      width: "10",
      type: "tags",
      tags: statusTags,
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];
  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-6",
      custom: <p className="is-size-4 has-text-weight-bold">Members</p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-4",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-2",
      align: "CENTER",
      buttons: [
        {
          type: "REFRESH",
          action: loadTableData,
        },
    
      ],
    },
  ];

  return (
    <>
      <div className="sd-efl-input">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          tableProps={{
            className: "is-hoverable is-bordered smart-kgr-table",
            isResponsive: true,
            searchPlaceHolder: "Search",
          }}
          paginationProps={{
            pageSize: 10,
          }}
        />
      </div>
    </>
  );
};

export default MembersWatingsList