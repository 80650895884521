import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SdsForm } from 'soft_digi';
import { SdsFormElementProps } from 'soft_digi/dist/sdforms/SdsFromInterFace';
import * as Yup from "yup";
import { useSiteContext } from '../contexts/SiteProvider';
import { post } from '../services/smartApiService';
import './component.scss';
import { showAlertAutoClose } from '../services/notifyService';
interface ContactUsProps {
  companyName?: string;
  companyEmail?: string;
  companyPhone?: string;
}


const validationSchema = Yup.object({

  email_id: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
    name: Yup.string()
    .required("Name is required"),
  msg: Yup.string()
    .min(5, "Message must be at least 5 characters")
    .max(500, "Message cannot exceed 500 characters")
    .required("Message is required"),
  
  mobile_no: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile No must be exactly 10 digits")
    .required("Mobile No is required"),
  
});

const ContactUsComponent: React.FC<ContactUsProps> = ({
  companyName = "Your Software Development Company",
  companyEmail = "contact@yourcompany.com",
  companyPhone = "+1 (123) 456-7890",
}) => {

  const {  closeModal } = useSiteContext();
      const {
        control,
        handleSubmit,
        reset,
        formState: { errors }, watch, setValue
      } = useForm({
        resolver: yupResolver(validationSchema),
        // defaultValues: data,
      });



        const onSubmit = (data: any) => {
          let url = "/contact/insert";
        
          // let data_in = { ...data };
          // data_in["sd_location_id"] = 1;
          const subscription = post(url, data).subscribe((response) => {
            closeModal();
               showAlertAutoClose("Thank you for reaching out! Our team will get back to you shortly");
            reset()
            
          });
          return () => subscription.unsubscribe();
        };
        const elements: SdsFormElementProps[] = [
          {
            type: "TEXT_BOX",
            width: 12,
            name: "name",
            class_name:"p-0",
            element: {
              // label: "Title",
              placeHolder: "Your Name ",
              isRequired: true,
            },
          },
          {
            type: "TEXT_BOX",
            width: 6,
            name: "email_id",
            class_name:"p-0",
            element: {
              // label: "Title",
              placeHolder: "Your Email ",
              isRequired: true,
            },
          },
          {
            type: "TEXT_BOX",
            width: 6,
            name: "mobile_no",
            class_name:"p-0",
            element: {
              // label: "Title",
              placeHolder: "Your Mobile Number ",
              isRequired: true,
            },
          },
        
      
          {
            type: "TEXTAREA",
            width: 12,
            name: "msg",
            class_name:"p-0",
            element: {
              // label: "Description",
              placeHolder: "Write  Message",
              isRequired: true,
              rows: 4,
              // richEditor: true
            },
          },
          
          {
            type: "BUTTON",
            width: 12,
  
            name: "submit_button",
            class_name: "has-text-centered p-0",
            element: {
              classList: ["button", "smart-action-button"],
              label:   "Send Message →",
              onClick: handleSubmit(onSubmit),
            },
          }, 
        ];

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.section 
      id="contact-us" 
      className="contact-section-page"
      initial="hidden"
      whileInView="visible"
      variants={containerVariants}
      viewport={{ once: true, amount: 0.2 }}
    >
   
        <motion.div className="has-text-centered mb-4" variants={itemVariants}>
          <h2 className="title is-3 contact-title">Get In Touch</h2>
          <p className="subtitle is-5">Have a question or need a quote? Contact us today!</p>
        </motion.div>

        <div className="columns">
          {/* Contact Information */}
          <motion.div className="column is-4 is-size-7" variants={itemVariants}>
            <div className="contact-info-wrapper">             
              <motion.div className="contact-info-container" variants={itemVariants}>
                <h3 className="title is-5 mb-4">Contact Information</h3>                
                <div className="contact-info-item">
                  <span className="icon has-text-primary">
                    <i className="fas fa-building"></i>
                  </span>
                  <div>
                    <p className="is-size-6 has-text-weight-semibold mt-1">{companyName}</p>
                  </div>
                </div>
                
                
                
                <div className="contact-info-item">
                  <span className="icon has-text-primary">
                    <i className="fas fa-phone"></i>
                  </span>
                  <div>
                    <p className='mt-1'>{companyPhone}</p>
                  </div>
                </div>
                
                <div className="contact-info-item">
                  <span className="icon has-text-primary">
                    <i className="fas fa-envelope"></i>
                  </span>
                  <div>
                    <p className='mt-1'>{companyEmail}</p>
                  </div>
                </div>
             
                
                <div className="social-icons mt-5">
                  <motion.a 
                    href="#" 
                    className="social-icon" 
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <span className="icon is-medium has-text-link">
                      <i className="fab fa-linkedin fa-lg"></i>
                    </span>
                  </motion.a>
                  <motion.a 
                    href="#" 
                    className="social-icon" 
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <span className="icon is-medium has-text-info">
                      <i className="fab fa-twitter fa-lg"></i>
                    </span>
                  </motion.a>
                  <motion.a 
                    href="#" 
                    className="social-icon" 
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <span className="icon is-medium has-text-dark">
                      <i className="fab fa-github fa-lg"></i>
                    </span>
                  </motion.a>
                  <motion.a 
                    href="#" 
                    className="social-icon" 
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <span className="icon is-medium has-text-danger">
                      <i className="fab fa-youtube fa-lg"></i>
                    </span>
                  </motion.a>
                </div>
              </motion.div>
            </div>
          </motion.div>
          
          {/* Contact Form */}
          <motion.div className="column is-7" variants={itemVariants}>
            <div className="box contact-form-box">
              <h3 className="title is-4 mb-4">Send Us A Message</h3>
              
              {submitSuccess && (
                <motion.div 
                  className="notification is-success"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                >
                  <button className="delete" onClick={() => setSubmitSuccess(false)}></button>
                  Thank you for your message! We'll get back to you soon.
                </motion.div>
              )}
              
              <div className="">
            <SdsForm elements={elements} errors={errors} control={control} />
          </div>
            </div>
          </motion.div>
        </div>
    
    </motion.section>
  );
};

export default ContactUsComponent;