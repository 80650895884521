import { Route } from "react-router-dom";

import {
  ForgetPassword,
  Login
} from "../pages";
import ModalLoginPage from "../pages/Login/ModalLoginPage";
import LoginLayout from "../themes/Login/LoginLayout";

const LoginRoutes = () => {
  return (
    <Route path="/login" element={<LoginLayout />}>
      <Route index element={<Login />} />
      <Route path="forget" element={<ForgetPassword />} />
      <Route path="register" element={<ModalLoginPage />} />    
    </Route>
  );
};

export default LoginRoutes;
