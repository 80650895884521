import React from 'react'
import ProductSectionItems from './ProductSectionItems'
import { HIMS_1, HIMS_2, HIMS_3, HIMS_4, HIMS_5, HIMS_6, HIMS_7 } from '../../../services/ImageService';
import SmartHeader from '../../../components/SmartHeader';

const HimsSections = () => {

const data = [
  {
    title: "Hospital Management",
    description: "Efficiently manage hospital operations with streamlined workflows.",
    image: HIMS_1,
    reverse: false,
    list: ["Patient records", "Doctor scheduling", "Billing automation"]
  },
  {
    title: "Electronic Health Records",
    description: "Ensure accurate and secure medical record-keeping.",
    image: HIMS_5,
    reverse: true,
    list: ["Paperless documentation", "Secure data storage", "Easy access for doctors"]
  },
  {
    title: "Pharmacy Management",
    description: "Manage prescriptions and inventory efficiently.",
    image: HIMS_3,
    reverse: false,
    list: ["Track medicine stock", "Automate prescriptions", "Billing and reporting"]
  },
  {
    title: "Appointment Scheduling",
    description: "Streamline patient appointments and doctor schedules.",
    image: HIMS_7,
    reverse: true,
    list: ["Online booking", "Automated reminders", "Doctor availability tracking"]
  },
  {
    title: "Medical Billing",
    description: "Simplify billing and insurance claims processing.",
    image: HIMS_2,
    reverse: false,
    list: ["Automated invoicing", "Insurance claim tracking", "Revenue management"]
  },
  {
    title: "Telemedicine",
    description: "Provide remote healthcare services effectively.",
    image: HIMS_4,
    reverse: true,
    list: ["Video consultations", "Secure communication", "E-prescriptions"]
  },
  {
    title: "Inventory Management",
    description: "Monitor and control medical supplies efficiently.",
    image: HIMS_1,
    reverse: false,
    list: ["Stock tracking", "Usage analytics", "Automated reordering"]
  },
  {
    title: "Clinical Decision Support",
    description: "Assist healthcare professionals with data-driven insights.",
    image: HIMS_2,
    reverse: true,
    list: ["AI-based recommendations", "Automated alerts", "Medical research integration"]
  },
  {
    title: "Patient Portals",
    description: "Enhance patient engagement with secure access to records.",
    image: HIMS_3,
    reverse: false,
    list: ["Access medical records", "Schedule appointments", "Secure messaging"]
  },
  {
    title: "Healthcare Analytics",
    description: "Leverage data for informed decision-making in healthcare.",
    image: HIMS_4,
    reverse: true,
    list: ["Real-time reporting", "Predictive analytics", "Performance tracking"]
  }
];

  return (
    <><SmartHeader title='HIMS' subTitle=''/>
    <ProductSectionItems data={data}/>
    </>
  )
}

export default HimsSections