import { motion } from "framer-motion";
import "./help.scss";

const processItems = [
  { id: 1, title: "Honesty", description: "We build trust through transparent communication and ethical business practices. Our track record of award-winning solutions demonstrates our commitment to delivering exactly what we promise—customized software that solves your unique challenges." },
  { id: 2, title: "Unity", description: "We believe in collaborative partnerships that drive success. By combining our technical expertise with your vision, we create integrated solutions that unite your teams, streamline workflows, and connect systems across your organization." },
  { id: 3, title: "Innovation", description: "We constantly push technological boundaries to develop pioneering solutions. From CSP implementations to digital signature workflows, our innovative approach ensures you stay ahead of industry trends with future-proof technology tailored to your evolving needs." },
];

const ProcessSection = () => {
  return (
    <section className="section process-section">
      <div className="container">
        <div className="has-text-centered mb-5">
          <span className="tag is-primary is-light is-medium has-text-black">OUR PROCESS</span>
          <h2 className="title is-2 has-text-white mt-3">
            Building great future <br /> Together, Be with us
          </h2>
        </div>

        <div className="columns is-multiline is-centered">
          {processItems.map((item, index) => (
            <motion.div
              key={item.id}
              className="column is-4-desktop is-6-tablet"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="box process-card">
                <motion.div
                  className="process-number"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  {String(item.id).padStart(2, "0")}
                </motion.div>
                <h3 className="title is-5 has-text-white">{item.title}</h3>
                <p className="has-text-grey-light">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
