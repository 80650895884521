import React, { useEffect, useState } from "react";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSiteContext } from "../../contexts/SiteProvider";
import { post } from "../../services/smartApiService";
import { GALLERY_URLS } from "../../api/GalaryUrls";
import { SITE_CIRCULAR } from "../../api/AdminUrls";
import { circular_get_select } from "../../services/site/SelectBoxServices";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../services/reduxServices/store";
import { useSelector } from "react-redux";
import { onSubmit } from "../../services/reduxServices/slices/CircularsSlice";
import useSelectBox from "../../services/HookServices/useSelectBox";

const validationSchema = Yup.object({
  circular_type: Yup.object().required("Type is required"),
  circular_title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),
  // circular_file: Yup.mixed()
  //   .required("File is required")
  //   .test(
  //     "fileType",
  //     "Only PDF files are allowed",
  //     (value) => value instanceof File && value.type === "application/pdf"
  //   )
  //   .test(
  //     "fileSize",
  //     "File size must be less than 500 KB",
  //     (value) => value instanceof File && value.size <= 500 * 1024
  //   ),
});
interface HeaderProps {
  loadTableData: () => void;
  data: any;
}
const SiteCurculerForm: React.FC<HeaderProps> = ({loadTableData, data}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema), defaultValues: data
  });
  const dispatch = useDispatch<AppDispatch>();
  const tabData = useSelector((state: RootState) => state.Circulars.tabData);

  const { openModal, closeModal } = useSiteContext();
  const [types, setTypes] = useState([]);
  useSelectBox(SITE_CIRCULAR.GET_ALL_SELECT, setTypes);

  // useEffect(() => {
  //   circular_get_select((data: any) => setTypes(data));
  // }, []);

  const newObject = { value: "others", label: "Others" };
  const updatedArray = [...types, newObject];

  // const onSubmit = (data: any) => {
  //   const url = data.ID ? SITE_CIRCULAR.UPDATE : SITE_CIRCULAR.INSERT;
  //   const subscription = post(url, data).subscribe(() => {
  //     closeModal(); loadTableData();
  //   });
  //   return () => subscription.unsubscribe();
  // };
  const handleFormSubmit = (data: any) => {
    dispatch(onSubmit(data));  
    reset();
  };

  const having_other = watch("circular_type");
 console.log("hide", having_other)
  const elements: SdsFormElementProps[] = [
    {
      type: "SELECT_BOX",
      width: 6,
      name: "circular_type",
      element: {
        label: "Type",
        isRequired: true,
        options: updatedArray,
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "circular_type_others",
      element: {
        label: "Other Type",
        isRequired: true,
      },
      hideFunction: () => {
        return having_other && having_other.value && having_other.value === "others" ? false : true;
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "circular_title",
      element: {
        label: "Title",
        isRequired: true,
        placeHolder: "Enter Title",
      },
    },
    {
      type: "FILE",
      width: 6,
      name: "circular_file",
      element: {
        label: "Upload",
        placeHolder: "Upload PDF",
        accept: "application/pdf",
      },
    },  {
      type: "DATE",
      width: 6,
      name: "circular_date",
      element: {
        label: "Date",
        isRequired: true,
        placeHolder: "Select Date",
      },
    },
    {
      type: "BUTTON",
      width: 12,
      name: "smart",
      class_name: "has-text-right",
      element: {
        classList: ["mt-4 smart-action-button"],
        label: "Add",
        onClick: handleSubmit(handleFormSubmit),
      },
    },
  ];

  return (
    <div className="sd-hrms-input">
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};


export default SiteCurculerForm;
