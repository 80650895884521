import React from 'react'

import { motion } from "framer-motion";
import { CAN } from '../../services/ImageService';
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SdsFormElementProps } from 'soft_digi/dist/sdforms/SdsFromInterFace';
import { SdsForm } from 'soft_digi';
import { post } from '../../services/smartApiService';
import { useSiteContext } from '../../contexts/SiteProvider';
import SmartHeader from '../../components/SmartHeader';
import { showAlertAutoClose } from '../../services/notifyService';
import { useNavigate } from 'react-router-dom';
import config from '../../config/config';

const validationSchema = Yup.object({

  email_id: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
    name: Yup.string()
    .required("Email is required"),
  msg: Yup.string()
    .min(5, "Message must be at least 5 characters")
    .max(500, "Message cannot exceed 500 characters")
    .required("Message is required"),
  
  mobile_no: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile No must be exactly 10 digits")
    .required("Mobile No is required"),
  
});
const ContactUs: React.FC = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }, watch, setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: data,
  });
  const { openModal, closeModal } = useSiteContext();
  const navigate=useNavigate()

  const onSubmit = (data: any) => {
    let url = "/contact/insert";
  
    // let data_in = { ...data };
    // data_in["sd_location_id"] = 1;
    const subscription = post(url, data).subscribe((response) => {
      closeModal();
      showAlertAutoClose("Thank you for reaching out! Our team will get back to you shortly");
      reset()
      navigate("/")
      
      
    });
    return () => subscription.unsubscribe();
  };
  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "name",
      element: {
        // label: "Title",
        placeHolder: "Your Name ",
        isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "email_id",
      element: {
        // label: "Title",
        placeHolder: "Your Email ",
        isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: 6,
      name: "mobile_no",
      element: {
        // label: "Title",
        placeHolder: "Your Mobile Number ",
        isRequired: true,
      },
    },
  

    {
      type: "TEXTAREA",
      width: 12,
      name: "msg",
      element: {
        // label: "Description",
        placeHolder: "Write  Message",
        isRequired: true,
        rows: 5,
        // richEditor: true
      },
    },
    
    {
      type: "BUTTON",
      width: 12,
      name: "submit_button",
      class_name: "has-text-right",
      element: {
        classList: ["button", "smart-action-button"],
        label:   "Send Message →",
        onClick: handleSubmit(onSubmit),
      },
    }, 
  ];

  return (
    <><SmartHeader title='Contact Us' subTitle=''/>
   
    <div className="contact-container">
      {/* Left Section */}
      <div className='columns is-vcentered'>
        <div className='column is-half'>
        <div className="contact-info">
        <div className="info-box">
          <i className="fas fa-phone-alt"></i>
          <div>
            <h4>Call Us 24/7</h4>
            <p className=''>{config.CONTACT_DETAILS.phone_1},</p>
            <p>{config.CONTACT_DETAILS.phone_2}</p>
          </div>
        </div>
        <div className="info-box">
          <i className="fas fa-envelope"></i>
          <div>
            <h4>Make a Quote</h4>
            <p>{config.CONTACT_DETAILS.email}</p>
          </div>
        </div>
        <div className="info-box">
          <i className="fas fa-map-marker-alt"></i>
          <div>
            <h4>Location</h4>
            <p>Hyderabad,Telangana-502 032.</p>
          </div>
        </div>
        <div className="contact-image">
          <img src={CAN} alt="Support" />
          <div className="play-button">
            <i className="fas fa-play"></i>
          </div>
        </div>
      </div>
        </div>
        <div className='column is-half'>
        <motion.div 
        className="contact-form"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Ready To Get Started?</h2>
        <p>
        Plan upon yet way get cold spot its week. Almost do am or limits hearts. Resolve parties but why she shewing.
        </p>

        
          <div className="">
            <SdsForm elements={elements} errors={errors} control={control} />
          </div>
         
          {/* <button type="submit">Send Message →</button> */}
       
      </motion.div>
        </div>
      </div>
    

      {/* Right Section */}
   
    </div>
    </>
  );
};



export default ContactUs