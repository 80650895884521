import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FaEarthEurope } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { useSiteContext } from "../../contexts/SiteProvider";
import { post } from "../../services/smartApiService";
import SmartHeader from "../../components/SmartHeader";

const validationSchema = Yup.object({
  sd_url: Yup.string().required("Url is required"),
});

type DashboardProps = {
  onAddSite: () => void;
};

const CspUrlAdditionForm: React.FC<DashboardProps> = ({ onAddSite }) => {
  const navigation = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: data,
  });

  const { openModal, closeModal, user } = useSiteContext();
  // console.log("setSubData", storedEmailId);

  const onSubmit = (data: any) => {
    let url = "/csp/insert";
    data["email_id"] = user?.emailid;
    const subscription = post(url, data, {
      loadingMsg: "Analyzing Please Wait",
    }).subscribe((response) => {
      console.log("Res ", response);
      closeModal();
      onAddSite();
    });
    return () => subscription.unsubscribe();
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "sd_url",
      class_name: "csp-input",
      element: {
        size: "large",
        // label: "Title",
        placeHolder: "Enter Your Url ",
        isRequired: true,
        leftIcon: (
          <span className="icon is-small is-left ">
            <FaEarthEurope />
          </span>
        ),
        // leftIcon:"fa fa-pie-chart"
      },
    },

    {
      type: "BUTTON",
      width: 2,
      name: "submit_button",
      class_name: "csp-input-button ",
      element: {
        classList: ["button", "smart-action-button"],
        label: "Check Your CSP →",
        onClick: handleSubmit(onSubmit),
      },
    },
  ];

  return (
    <>
      <SmartHeader
        title="Content Security Policy (CSP)"
        subTitle="A Content Security Policy (CSP) is a security feature that helps
                prevent cross-site scripting (XSS) attacks by restricting the
                sources from which content can be loaded."
      />
      <div className=" columns is-multiline is-mobile csp-main-container ">
        <div className="column is-12 is-half-desktop">
          <div className=" csp-input">
            <SdsForm elements={elements} errors={errors} control={control} />
            <div className="csp-input-button ">
              {/* <SdsButton  label="Check Your CSP →" onClick={()=>handleSubmit(onSubmit)}
          
           classList={["button", "smart-action-button"]}
              /> */}
            </div>
          </div>
          {/* <EmailInput /> */}
        </div>
      </div>
    </>
  );
};

export default CspUrlAdditionForm;
