import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./userLayout.scss";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <motion.div 
      className="not-found-container has-text-centered"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="title is-1">404</h1>
      <p className="subtitle is-4">Oops! The page you are looking for does not exist.</p>
      <button className="button is-primary" onClick={() => navigate("/")}>Go Home</button>
    </motion.div>
  );
};

export default NotFound;
