import React, { useEffect, useState } from "react";
import {
  SmartAlert,
  SmartFormInterFace,
  SmartLoaderInterface,
  SmartTable,
  SmartTableNewInterface,
} from "soft_digi";
import { useSiteContext } from "../../contexts/SiteProvider";
import { get, post } from "../../services/smartApiService";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../services/notifyService";

import SiteCurculerForm from "./SiteCurculerForm";
import { downloadFile } from "../../services/core/FileService";
import { SITE_CIRCULAR } from "../../api/AdminUrls";
import { SdsFsDownloadFile } from "soft_digi/dist/services/FileService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../services/reduxServices/store";
import { deleteData, loadTableData, viewEditForm } from "../../services/reduxServices/slices/CircularsSlice";

const Sitecurculer = () => {
  const { openModal, closeModal } = useSiteContext();
  const dispatch = useDispatch<AppDispatch>();
  const tabData = useSelector((state: RootState) => state.Circulars.tabData);
  const selectedItem = useSelector((state: RootState) => state.Circulars.selectedItem);

  useEffect(() => {
    const unsubscribe = dispatch(loadTableData());
    return unsubscribe;
  }, [dispatch]);
  useEffect(() => {
    if (selectedItem) {
      openNewsForm(selectedItem); // Open the form when selectedItem is updated
    }
  }, [selectedItem]); 
  const handleDelete = (id: any) => {
    dispatch(deleteData(id));
  };
  const openDeleteModal = (id: any) => {
    let alertProps: SmartLoaderInterface.SmartAlertInterface = {
      title: (
        <span className="has-text-danger">
          <i className="fa fa-check"></i> Circular Deletion!
        </span>
      ),
      alertFunction: (option) => {
        if (option === "yes") {
          handleDelete(id);
          SmartAlert.hide();
        }
      },
      content: (
        <p>
          Note: Do you wish to delete this Circular? This action cannot be
          reverted
        </p>
      ),
      className: "custom-alert",
    };

    SmartAlert.show(alertProps);
  };

  
  const handleEdit = (id: any) => {
    dispatch(viewEditForm(id)); // Dispatch action to fetch data
  };

  const openNewsForm = (data: any) => {
    let options = {
      title: " Circular Addition Form",
      content: <SiteCurculerForm  data={data} loadTableData={loadTableData} />,
      width: 60,
      className: "sd-efl-modal",
      closeBody: false,
    };
    openModal(options);
  };
  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-eye",
      classList: ["smart-kgr-table-view-icon"],
      onClick: (dataIn: any) => {
        // openViewdetails(dataIn);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: " fa-pencil-square-o",
      classList: ["smart-kgr-table-edit-icon"],
      onClick: (data: any) => {
        handleEdit(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["smart-kgr-table-delete-icon"],
      onClick: (data: any) => {
        openDeleteModal(data["ID"]);
      },
    },
  ];
  const onSubmit = (data_in: any) => {
    let url = SITE_CIRCULAR.DOWNLOAD;
    let item_id = data_in.ID;

    const subscription = post(url, { item_id }).subscribe((response) => {
      SdsFsDownloadFile(response.data.content, "documents.pdf");
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  
  const download_data = (data_in: any) => {
    return (
      <div
        className="is-size-5 has-text-danger sd-pointer"
        onClick={() => onSubmit(data_in)}
        title="Download"
      >
        <i className="fa fa-download"></i>
      </div>
    );
  };
  const columns: SmartTableNewInterface.SmartTableNewColumnConfig[] = [
    { title: "S.NO", index: "s_no", type: "sno", width: "5" },
    {
      title: "Circular Type",
      index: "circular_type",
    },
    {
      title: "Title",
      index: "circular_title",
    },
    { title: "Download", index: "download", valueFunction: download_data, width:"5" },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];
  const tableTop: SmartTableNewInterface.SmartTableNewTopProps[] = [
    {
      type: "CUSTOM",
      widthClass: "is-6",
      custom: <p className="is-size-5">Circular</p>,
    },
    {
      type: "SEARCH",
      widthClass: "is-3",
      align: "JUSTIFY",
    },
    {
      type: "BUTTONS",
      widthClass: "is-3",
      align: "CENTER",
      buttons: [
        { type: "FILTER" },
        {
          type: "REFRESH",
          action: loadTableData,
        },
        {
          label: "ADD",
          icon: "fa-plus",
          type: "CUSTOM",
          action: () => openNewsForm({}),
        },
      ],
    },
  ];
  const filterFields: SmartFormInterFace.SmartFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "category",
      element: {
        label: "Category",
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "taluka_name",
      element: {
        label: "Taluk",
      },
    },
  ];
  return (
    <>
      <div className="">
        <SmartTable
          columns={columns}
          data={tabData}
          tableTop={tableTop}
          filterFields={filterFields}
          tableProps={{
            className: " is-hoverable is-bordered is-striped smart-kgr-table",
            isResponsive: true,
            searchPlaceHolder: "Search Here...",
          }}
          // paginationProps={{
          //   pageSize: 5,
          // }}
        />
      </div>
    </>
  );
};

export default Sitecurculer;
