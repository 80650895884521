import React from "react";
import { motion } from "framer-motion";
import { ABOUT_US } from "../../services/ImageService";
import TeamMembersList from "../HomeFirst/TeamMembersList";

interface headerProps {
  LandingPage?: boolean;
}
const AboutUs: React.FC<headerProps> = ({ LandingPage }) => {
  return (
    <div className="about-us-container">
      <div className="columns">
        <div className="column is-6">
          <motion.div
            className="animated-box aboutus-left-container"
            initial={{ opacity: 0, x: -100 }} // Start from left
            whileInView={{ opacity: 1, x: 0, y: [0, -10, 0] }} // Slide in, then bounce
            transition={{
              duration: 1, // Slide duration
            }}
          >
            <figure className="image ">
              <img src={ABOUT_US} />
            </figure>
          </motion.div>
        </div>
        <div className="column is-6">
          <motion.div
            className="container aboutus-right-container"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.h2
              className="title"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5 }}
            >
              ABOUT HDT Solutions{" "}
            </motion.h2>
            <motion.p
              className="about-us-sub-title"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.2 }}
            >
              We Are Increasing Business Success With <span>Technology</span>
            </motion.p>
            <motion.p
              className="mb-5"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.3 }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at layout the point.
            </motion.p>
            <motion.div
              className="is-flex mb-4 "
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.4 }}
            >
              <div className="icon px-4">
                <i className="fa fa-american-sign-language-interpreting"></i>
              </div>
              <div className="content mt-3 ml-4">
                <h3 className=" has-text-black is-size-4 m-0">
                  Problem Solving
                </h3>
                <p className="is-size-6">
                  We offer customized IT problem-solving solutions for
                  businesses of all sizes. Let us troubleshoot, optimize, and
                  secure your IT infrastructure today! 
                </p>
              </div>
            </motion.div>
            <motion.div
              className="is-flex"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.4 }}
            >
              <div className="icon px-4">
                <i className="fa fa-street-view "></i>
              </div>
              <div className="content mt-3 ml-4 ">
                <h3 className=" has-text-black is-size-4 m-0">
                  Mission & Vision
                </h3>
                <p>
                  Deliver innovative, secure, and scalable IT solutions for
                  businesses.
                </p>
                <p>
                  Empower global digital transformation with cutting-edge
                  technology.
                </p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* {!LandingPage && <TeamMembersList />} */}
    </div>
  );
};

export default AboutUs;
