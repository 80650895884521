import { useState } from "react";
import "./Settings.scss";
import SiteSettings from "./SiteSettings";
import { post } from "../../services/smartApiService";

import { showAlertAutoClose } from "../../services/notifyService";
import { SITE_URLS } from "../../api/SiteSettingsUrl";
import HomeImage from "./HomeImage";
import AdminMenuTable from "../admin/MenuSettings/AdminMenuTable";

const Settings: React.FC = () => {
  const [tab, setTab] = useState("menu");
  const [data, setData] = useState<any>({});

  const tabs = [
    // { label: "Site Settings", icon: "gears", index: "site" },
    // { label: "Home Image Settings", icon: "share", index: "home" },
    { label: "Menu Settings", icon: "share", index: "menu" },
   
  ];

  const displayTabs = () => (
    <div className="tabs-container">
      {tabs.map((item) => (
        <div
          key={item.index}
          className={`tab-item ${tab === item.index ? "active" : ""}`}
          onClick={() => setTab(item.index)}
        >
          {item.icon && <i className={`fa fa-${item.icon} tab-icon`}></i>}
          <span className="tab-label">{item.label}</span>
        </div>
      ))}
    </div>
  );

  const onSubmit = (formData: any) => {
    let url = SITE_URLS.INSERT;
    let data_in = { ...formData };
    const subscription = post(url, data_in).subscribe((response) => {
      showAlertAutoClose("Data saved successfully", "success");
    });

    return () => {
      subscription.unsubscribe();
    };
  };

  const displayContent = () => {
    switch (tab) {
      case "site":
        return (
          <SiteSettings
            onSubmit={(formData: any) => onSubmit(formData)}
            data={data}
          />
        );
      case "home":
        return (
          <HomeImage
          />
        );
        case "menu":
          return (
            <AdminMenuTable />
           
          );
      default:
        return null;
    }
  };

  return (
    <div className="settings-wrapper">
      {displayTabs()}
      <div className="settings-content">{displayContent()}</div>
    </div>
  );
};

export default Settings;
