import { Route } from "react-router-dom";
import MainLayout from "../themes/Main/Layout";
import PrivateRoute from "./PrivateRoute";
import {
  AdminMenuTable,
  ContactUsList,
  CSPList,
  DashBoard,
  GalarySection,
  GalleryDisplay,
  Login,
  MembersWatingsList,
  Profile,
  RoleTable,
  Settings,
  Sitecurculer,
  UsersTable,
} from "../pages";
import Registration from "../pages/Login/Rigistation";
import ModalLoginPage from "../pages/Login/ModalLoginPage";
import EmailTemplate from "../pages/admin/EmailTemplate";
import EmailSettingsForm from "../pages/admin/EmailTemplateForm";

const DashRoutes = () => {
  return (
    <>
   
    <Route path="admin/login" element={<Login />} />
    <Route path="/registration" element={<ModalLoginPage />} />
    <Route
      path="/admin/*"
      element={
        // <PrivateRoute>
          <MainLayout />
        // </PrivateRoute>
      }
    >
      <Route path="dashboard" element={<DashBoard />} />
      <Route path="users" element={<UsersTable />} />
      <Route path="role" element={<RoleTable />} />
      <Route path="profile" element={<Profile />} />
      <Route path="Site" element={<Sitecurculer />} />
      <Route path="gellary-section" element={<GalarySection />} />
      <Route path="Settings" element={<Settings />} /> 
      <Route path="waiting-list" element={<MembersWatingsList />} />
      <Route path="contact-list" element={<ContactUsList />} />
      <Route path="csp-list" element={<CSPList />} />
      <Route path="email" element={<EmailTemplate />} />
      <Route path="email-from/:id" element={<EmailSettingsForm />} />
      <Route path="menu-settings" element={<AdminMenuTable />} />
    </Route>

    </>
  );
};

export default DashRoutes;
