import { motion } from "framer-motion";
import "./projects.scss";
interface Directive {
  data: any;
}

const CSPCards: React.FC<Directive> = ({ data }) => {
  // Count occurrences of each status

  // Motion animation settings
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="csp-card-container"
      initial="hidden"
      animate="visible"
    >
      <motion.div className="csp-card high" variants={cardVariants}>
        <h4>High</h4>
        <p>{data.highRisk}</p>
      </motion.div>

      <motion.div className="csp-card medium" variants={cardVariants}>
        <h4>Medium</h4>
        <p>{data.mediumRisk}</p>
      </motion.div>

      <motion.div className="csp-card low" variants={cardVariants}>
        <h4>Low</h4>
        <p>{data.lowRisk}</p>
      </motion.div>

      <motion.div className="csp-card info" variants={cardVariants}>
        <h4>Info</h4>
        <p>{data.informational}</p>
      </motion.div>
    </motion.div>
  );
};

export default CSPCards;
