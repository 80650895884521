const config: siteConfig = {
  //  REACT_APP_API_URL: "http://localhost/sds_efl_background/api",
  API_URL: "http://192.168.1.35:3005/api/",
  CONTACT_DETAILS:{
    phone:"+91 9392336796",
    email:"sds@hdtsolution.com"
  }
 
};

export default config;
