import React, { useEffect, useState } from "react";
import { SdsForm } from "soft_digi";
import { ROLE_URLS, USER_URLS } from "../../api/AdminUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { role_get_select, user_get_select } from "../../services/site/SelectBoxServices";
import { post } from "../../services/smartApiService";
import {
  SmartValid,
  ValidateFormNew,
} from "soft_digi/dist/services/smartValidationService";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

interface FormErrors {
  [key: string]: string | null;
}
interface HeaderProps {
  loadTableData: () => void;
  dataIn: any;
}
const schema = Yup.object({
  emailid: Yup.string()
    .required("Role name is required")
    .min(2, "Role name must be at least 2 characters"),
  users: Yup.array().default([]).min(1, "Please select an option"),
});
const UsersForm: React.FC<HeaderProps> = ({}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema), // Attach the Yup schema to react-hook-form
  });
  const { closeModal } = useSiteContext();

  const [allRole, setAllRole] = useState([]);



  useEffect(() => {   
   
    role_get_select((data:any) => setAllRole(data));
  }, []);

  //   const onSubmit = (data: any) => {
  //     console.log("Form submitted:", data);
  //   };
  const onSubmit = (data: any) => {
    let url = USER_URLS.INSERT;
    // if (formData.ID !== undefined) {
    //   formData["id"] = formData.ID;
    //   url =ROLE_URLS.UPDATE;
    // }
    const subscription = post(url, data).subscribe((response) => {
      //console.log("response form ", response.data);
      closeModal();
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const options = [
    { value: "1", label: "Test" },
    { value: "2", label: "Test" },
    { value: "3", label: "test" },
  ];
  const elements: SdsFormElementProps[] = [
    {
        type: "TEXT_BOX",
        width: 4,
        name: "emailid",
        element: {
          label: "Email ID",
          isRequired: true,
          // inputProps: { isFocussed: true },
        
        },
      },
      {
        type: "TEXT_BOX",
        width: 4,
        name: "mobile_no",
        element: {
          label: "Mobile No.",
          isRequired: true,
          inputType: "BORDER_LABEL",
         
         
         
        },
      },
      {
        type: "TEXT_BOX",
        width: 4,
        name: "ename",
        element: {
          label: "Name",
        
          isRequired: true,
          // inputProps: { isFocussed: true },
          
        },
      },
      {
        type: 'PASSWORD',
        width: 4,
        name: 'epassword',
        element: {
         label: 'Password',
          isRequired: true,
          placeHolder: 'Password',
          inputType: "BORDER_LABEL",
          // inputType: "BORDER_LABEL",
          // leftIcon: "fa fa-envelope-square",
         
        },
      },
      
     
    
      {
        type: "SELECT_BOX",
        width: 4,
        name: "role",
        element: {
          label: "Role",
          inputType: "BORDER_LABEL",
          options: allRole,
          isMulti: true,
        
         
        },
      },
    {
      type: "BUTTON",
      width: 12,
      name: "submitbutton",
      element: {
        label: "Submit",
        onClick: handleSubmit(onSubmit),
      },
    },
  ];
  return (
    <>
      <div className="sd-hims-input">
        <SdsForm elements={elements} errors={errors} control={control} />
      </div>
    </>
  );
};



export default UsersForm
