import React from "react";
import { motion } from "framer-motion";

const staffList = [
  { title: "Frontend Engineers", icon: "🖥️" },
  { title: "Angular Developers", icon: "🅰️" },
  { title: "Fullstack Engineers", icon: "💻" },
  { title: "UI/UX Engineers", icon: "🎨" },


  { title: "PHP Developers", icon: "🐘" },
  { title: "Project Managers", icon: "📅" },
  { title: "Solution Architects", icon: "🔗" },
  { title: "QA Engineers", icon: "✅" },
  { title: "Product Designer", icon: "🛠️" },
  { title: "Database Admin", icon: "💾" },
  { title: "Business Analyst", icon: "📈" },
  { title: "Golang Developers", icon: "🚀" },
  { title: "Application Security Engineers", icon: "🔐" },
  { title: "DevOps + DevSecOps Engineers", icon: "⚙️" },
  { title: "Data Scientist / Data Engineers", icon: "📊" },
  { title: "iOS and Android Developers", icon: "📱" },
  { title: "WordPress CMS Developers", icon: "🌐" },
  
  { title: "Tech Leads / Team Leads", icon: "👥" },

  { title: "Mobile App Developers", icon: "📲" },
  { title: "Information Security Engineers", icon: "🔒" },

];

const StaffSection = () => {
  return (
    <section className="section staff-section">
      <div className="container p-0 m-0">
        <div className="has-text-left mb-5">
          {/* <p className="tag is-primary is-light is-medium has-text-black">Specialized</p> */}
          <h2 className="sds-sub-title  mt-2">
            Specialized Staff We Provide
          </h2>
        </div>

        <div className="columns  is-multiline">
          {staffList.map((item, index) => (
            <motion.div
              key={index}
              className="column is-3-desktop is-6-tablet"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="box staff-card">
                <span className="staff-icon">{item.icon}</span>
                <p className="staff-title">{item.title}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StaffSection;
