import React, { useEffect, useState } from "react";
import "./GallerySelection.scss";
import {
  OUR_SERVICE_CARD_FIVE,
  OUR_SERVICE_CARD_FOUR,
  OUR_SERVICE_CARD_ONE,
  OUR_SERVICE_CARD_SIX,
  OUR_SERVICE_CARD_THREE,
  OUR_SERVICE_CARD_TWO,
} from "../../services/ImageService";
import { useSiteContext } from "../../contexts/SiteProvider";
import { GALLERY_URLS } from "../../api/GalaryUrls";
import { get, post } from "../../services/smartApiService";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Parallax,
  Zoom,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface Image {
  ID: number;
  src: string;
  title: string;
}

interface ImageGalleryProps {
  images: Image[]; // An array of `Image` objects
}

const GalleryDisplay: React.FC = () => {
  const { openModal, closeModal } = useSiteContext();
  const [tabData, setTabData] = useState<any>([]);
  // const [_data, setData] = useState<any>();
  const loadTableData = () => {
    let URL = GALLERY_URLS.GET_ALL;
    const subscription = get(URL).subscribe((response) => {
      setTabData(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const openImageForm = (data: any) => {
    let options = {
      title: "  ",
      content: <ImageSwiper images={data} />,
      width: 80,
      className: "sd-efl-modal",
      closeBody: false,
    };
    openModal(options);
  };
  const viewEditForm = (id: any) => {
    const subscription = post(GALLERY_URLS.GET_ONE, { id: id }).subscribe(
      (response: any) => {
        openImageForm(response.data.sub_data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  // slider Display
  const ImageSwiper: React.FC<ImageGalleryProps> = ({ images }) => {
    return (
      <Swiper
        modules={[Navigation, Pagination, Zoom]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop={true}
        style={{ width: "100%", height: "90vh" }}
      >
        {images.map((image: any) => {
          const base64Content = image.image_file?.content;
          const imageSrc = `data:image/jpeg;base64,${base64Content}`;
          return (
            <SwiperSlide key={image.ID}>
              <div>
                {/* <p>{image.title}</p> */}

                <img src={imageSrc} alt="" className="slider-images" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  //Gallery View
  const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
    return (
      <div className="gallerySelection">
        <div className="container">
          <div className="columns is-multiline">
            {images.map((image: any) => {
              const base64Content = image.image_file?.content;
              const imageSrc = `data:image/jpeg;base64,${base64Content}`;
              return (
                <div key={image.ID} className="column is-one-quarter">
                  <div className="card gallery-item">
                    <div className="card-image">
                      <figure
                        className="image is-4by3"
                        onClick={() => viewEditForm(image.ID)}
                      >
                        <img src={imageSrc} alt="" />
                      </figure>
                      <div className="card-content">
                        <div className="media has-text-center">
                          {image.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const images = [
    { id: 1, src: OUR_SERVICE_CARD_ONE, alt: "Image 1" },
    { id: 2, src: OUR_SERVICE_CARD_TWO, alt: "Image 2" },
    { id: 3, src: OUR_SERVICE_CARD_THREE, alt: "Image 3" },
    { id: 4, src: OUR_SERVICE_CARD_FOUR, alt: "Image 4" },
    { id: 5, src: OUR_SERVICE_CARD_FIVE, alt: "Image 5" },
    { id: 6, src: OUR_SERVICE_CARD_SIX, alt: "Image 6" },
  ];

  return (
    <>
      <div className="container mt-3">
        <ImageGallery images={tabData} />
      </div>
    </>
  );
};

export default GalleryDisplay;
