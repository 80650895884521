const SITE_URLS = {
    INSERT: "/site/insert",
    GET_ONE: "/site/get_one",
    CHANGE_PASSWORD: "/user/user_reset",
  };
  const HOME_IMAGES_URLS = {
    INSERT: "/admin_home_images/insert",
    GET_ONE: "/admin_home_images/get_one",
    GET_ALL:"/admin_home_images/get_all",
    UPDATE:"/admin_home_images/update",
    DELETE:"/admin_home_images/delete_one",
  }
export { SITE_URLS, HOME_IMAGES_URLS };
