import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEarthEurope } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { SdsButton, SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { getLocalStorage } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import CspDashboardCards from "../../client/CspDashboardCards";
import CSPBusinessImportance from "./CspBussinessImportance";
import CspEmailForm from "./CspEmailForm";
import CSPTable from "./CSPTable";
import "./projects.scss";
import ScorePieChart from "./ScorePieChart";
import UpgradeCspModal from "../../../components/UpgradeCspModal";
import { FaGlobe } from "react-icons/fa";
import SmartHeader from "../../../components/SmartHeader";
import CookieConsentModal from "../../../components/CookieConsentComponent";
import CSPCards from "./CSPCards";

const validationSchema = Yup.object({
  sd_url: Yup.string().required("Url is required"),
});

const ContentSecurityPolicy = () => {
  const navigation = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: data,
  });

  const [tabData, setTabData] = useState<any>();
  const [score, setScore] = useState<any>(0);
  const [status, setStatus] = useState<any>();
  const [summary, setSummary] = useState<any>();
  const { openModal, closeModal } = useSiteContext();
  const [subData, setSubData] = useState<any>([]);
  const storedEmailId = getLocalStorage("userEmail");
  // console.log("setSubData", storedEmailId);

  const loadTableData = () => {
    let URL = "/csp/get_all_email";
    let email_id = storedEmailId;
    //let email_id = "jana@gmail.com";
    const subscription = post(
      URL,
      { email_id },
      { showLoading: false }
    ).subscribe((response) => {
      setSubData(response.data.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    if (storedEmailId && storedEmailId.length > 4) {
      loadTableData();
    }
  }, [storedEmailId]);

  const openForm = (data: any, submit: any) => {
    let options = {
      title: " ",
      content: <CspEmailForm dataIn={data} submit={submit} />,
      width: 40,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };

  const openUpgradeModalForm = () => {
    let options = {
      title: " ",
      content: <UpgradeCspModal />,
      width: 40,
      className: "sd-efl-modal",
      closeBody: true,
    };
    openModal(options);
  };

  const onSubmit = (data: any) => {
    const storedEmail = getLocalStorage("userEmail");
    console.log("Setting");

    if (storedEmail && storedEmail.length > 2) {
      if (subData.length > 2) {
        // open the upgrade modal
        openUpgradeModalForm();
        return true;
      } else {
        // post and check the query here
        data["email_id"] = storedEmail;
        // check one more time to check certificates
        post_insert_data(data);
      }
    } else {
      openForm(data, onSubmit);
    }
  };

  const post_insert_data = (data: any) => {
    //console.log("storedEmail", storedEmail);
    let url = "/csp/insert";
    const subscription = post(url, data, {
      loadingMsg: "Analyzing Please Wait",
    }).subscribe((response) => {
      if (response.data.data.count !== undefined) {
        // open the upgrade modal
        openUpgradeModalForm();
      } else {
        setTabData(response.data.data);
        setScore(response.data.data.overall_score);
        setStatus(response.data.data.overall_status);
        setSummary(response.data.data.summary);
        reset();
      }
    });
    return () => subscription.unsubscribe();
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "sd_url",
      class_name: "csp-input",
      element: {
        size: "large",
        // label: "Title",
        placeHolder: "Enter Your Url ",
        isRequired: true,
        leftIcon: (
          <span className="icon is-small is-left ">
            <FaEarthEurope />
          </span>
        ),
        // leftIcon:"fa fa-pie-chart"
      },
    },

    {
      type: "BUTTON",
      width: 2,
      name: "submit_button",
      class_name: "csp-input-button ",
      element: {
        classList: ["button", "smart-action-button"],
        label: "Check Your CSP →",
        onClick: handleSubmit(onSubmit),
      },
    },
  ];

  const updateQueryUrl = (url: string) => {
    setValue("sd_url", url);
    handleSubmit(onSubmit)();
  };

  const displayChips = () => {
    return (
      <>
        <div className="columns is-multiline is-centered">
          {subData.map((site: any, index: number) => {
            const cleanUrl = site?.sd_url
              .replace(/(^\w+:|^)\/\//, "")
              .replace(/\/$/, "");
            return (
              <div className="column is-3 ">
                <span
                  className="is-flex box is-clickable"
                  onClick={() => updateQueryUrl(site.sd_url)}
                >
                  <span className="icon">
                    <FaGlobe className="web-icon" />
                  </span>
                  <span className="label"> {cleanUrl}</span>
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <SmartHeader
        title="Content Security Policy (CSP)"
        subTitle="A Content Security Policy (CSP) is a security feature that helps
                prevent cross-site scripting (XSS) attacks by restricting the
                sources from which content can be loaded."
      />
      <div className=" columns is-multiline is-mobile csp-main-container ">
        <div className="column is-12">
          {/* <div className="csp-container">
            <motion.div
              className="csp-intro"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h2>Content Security Policy (CSP) </h2>
              <p>
                A Content Security Policy (CSP) is a security feature that helps
                prevent cross-site scripting (XSS) attacks by restricting the
                sources from which content can be loaded.
              </p>
            </motion.div>
          </div> */}
        </div>
        <div className="column is-12 is-half-desktop">
          <div className=" csp-input">
            <SdsForm elements={elements} errors={errors} control={control} />
            <div className="csp-input-button ">
              {/* <SdsButton  label="Check Your CSP →" onClick={()=>handleSubmit(onSubmit)}
          
           classList={["button", "smart-action-button"]}
              /> */}
            </div>
          </div>
          {/* <EmailInput /> */}
        </div>
        <div className="column is-12">
          {subData && subData?.length > 0 && displayChips()}
        </div>
        {tabData && tabData?.directives.length == 0 && (
          <div className="column is-12">
            <div className="has-text-centered has-text-danger-dark">
              <p className="is-size-4">
                No Content Security Policy (CSP) detected! Your site may be
                prone to attacks. Contact us to implement an effective CSP
                policy
              </p>
            </div>
          </div>
        )}
        {/* <div className="column is-12">
        <RateCard />
        </div> */}

        <div className="column is-12-mobile is-3-tablet px-3 ">
          {tabData && tabData?.directives.length > 0 && (
            <>
              <div className="smart-csp-score-card">
                <ScorePieChart overall_score={Math.round(score)} status={status} />
                <p className="has-text-black">
                {score < 40 ? (
  <p>
     Your Content Security Policy needs significant improvement to protect against common attacks. Our team can strengthen your defenses - reach out to <a href="mailto:security@example.com">security@example.com</a> for assistance.
  </p>
) : score >= 40 && score <= 60 ? (
  <p>
     Your CSP provides decent protection but we've identified specific improvements. Contact <a href="mailto:security@example.com">security@example.com</a> to enhance your security without breaking functionality.
  </p>
) : null}
                </p>
              </div>
            </>
          )}
        </div>

        <div className="column is-12-mobile is-9-tablet is-vcentered">
          <div className="container">
            {tabData && tabData?.directives.length > 0 && (
              <>
                <CSPCards data={tabData} />
                <CSPTable tabData={tabData} />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="team-service-page-section">
        <CSPBusinessImportance />
      </div>
    </>
  );
};

export default ContentSecurityPolicy;
