import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SdsForm } from "soft_digi";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import * as Yup from "yup";
import { post } from "../../services/smartApiService";
import { useSiteContext } from "../../contexts/SiteProvider";
import GallerySubForm from "./GallerySubForm";
import { GALLERY_URLS } from "../../api/GalaryUrls";

interface HeaderProps {
  loadTableData: () => void;
  data: any;
}
const schema = Yup.object({
});

const GalarySectionForm: React.FC<HeaderProps> = ({ loadTableData, data }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { sub_data: [{}], ...data },
  });
  const { closeModal } = useSiteContext();
  const onSubmit = (dataIn: any) => {
    let url = GALLERY_URLS.INSERT;
    if (dataIn.ID !== undefined) {
      dataIn["id"] = dataIn.ID;
      url = GALLERY_URLS.UPDATE;
    }
    const subscription = post(url, dataIn).subscribe(() => {
      loadTableData();
      closeModal();
    });
    return () => subscription.unsubscribe();
  };

  const elements: SdsFormElementProps[] = [
    {
      type: "TEXT_BOX",
      width: 12,
      name: "title",
      element: { label: "Gallery Title", isRequired: true },
    },
    {
      type: "LABEL",
      name: "label_date",
      width: 12,
      labelComponent: (
        <>
          <GallerySubForm
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </>
      ),
    },

    {
      type: "BUTTON",
      width: 12,
      name: "submitbutton",
      class_name: "has-text-right",
      element: {
        label: data.ID ? "Update" : "Add",
        onClick: handleSubmit(onSubmit),
        classList: ["smart-action-button"],
      },
    },
  ];

  return (
    <div className="sd-hims-input">
      <div className="smart-hims-main-header"></div>
      <SdsForm elements={elements} errors={errors} control={control} />
    </div>
  );
};

export default GalarySectionForm