import { useForm, Controller } from "react-hook-form";
import { SdsForm } from "soft_digi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SdsFormElementProps } from "soft_digi/dist/sdforms/SdsFromInterFace";
import { post } from "../../services/smartApiService";
import { showAlertAutoClose } from "../../services/notifyService";
import { ADMIN_USER_LOGO } from "../../services/ImageService";
import { PROFILE_URLS } from "../../api/AdminUrls";

const validationSchema = Yup.object({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .required("New Password is required"),
    
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

const Profile = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data: any) => {
   
    let url = PROFILE_URLS.PASSWORD;
    const subscription = post(url, data).subscribe(
      (response) => {
     
         showAlertAutoClose("Password Changed Successfully","success" );
         setTimeout(() => {
          window.scrollTo(0, 0);
        }, 2500);
        
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const LoginSettings = () => {
    const elements: SdsFormElementProps[] = [
      {
        type: "PASSWORD",
        width: 12,
        name: "currentPassword",
        element: {
          label: "Current Password",
          inputType: "BORDER_LABEL",
          isRequired: true,
        },
      },
      {
        type: "PASSWORD",
        width: 12,
        name: "newPassword",
        element: {
          label: "New Password",
          inputType: "BORDER_LABEL",
          isRequired: true,
        },
      },
      {
        type: "PASSWORD",
        width: 12,
        name: "confirmPassword",
        element: {
          label: "Confirm Password",
          inputType: "BORDER_LABEL",
          isRequired: true,
        },
      },

      {
        type: "BUTTON",
        width: 12,
        name: "submit",
        class_name: "has-text-right",
        element: { label: "Submit", onClick: handleSubmit(onSubmit) },
      },
    ];
    return (
      <>
     
            <div className="mt-3 sd-hims-input">
              <SdsForm elements={elements} errors={errors} control={control} />
            </div>
         
      </>
    );
  };

  return (
    <>
      <div className="is-size-4 smart-efl-table_main_container">
        <p>Profile</p>
      </div>
      <div className="sd-efl-input mt-6 p-3">
        <div className="columns is-multiline">
          {/* Profile Image Column */}
          <div className="column is-4 is-flex ">
            <div className="profile-header">
              <div className=" is-flex ">
                <img
                  src={ADMIN_USER_LOGO}
                  alt="Profile"
                  className="image is-128x128"
                />
              </div>
            </div>
          </div>

          <div className="user-info column is-4 ">
            <table className="table ">
              <tr>
                <td>Name:</td>
                <td>ADMIN</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>Admin@gmail.com</td>
              </tr>
            </table>
          </div>
          <div className="column is-4">{LoginSettings()}</div>
        </div>
      </div>
    </>
  );
};

export default Profile;
